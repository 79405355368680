import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Heading, Illustration } from 'styleguide';
import RouteUtils from '../utils/RouteUtils';

export default function Maintenance({alert}) {
  useEffect(() => {
    RouteUtils.setDocumentTitle('maintenance');
  }, []);

  if(!alert?.isMaintenance){
    return <Navigate to="/" />;
  }

  const margin = '25px';
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  };

  return <div style={style}>
    <Illustration type='error404' margin={margin} size="200px" />
    <Heading level='4'>{alert?.text}</Heading>
  </div>;
}