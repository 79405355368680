import { Button, Grid, InputAdornment, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Heading, Paragraph, Colors, Icon } from 'styleguide';
import { addRegisterObject, fetchDetails, fetchRelationClassifiers, resetFormSaved, saveRegisterObject, setInvalidRegisterObject, setValidRegisterObject } from '../../stores/register/register';
import DialogForm from '../form/DialogForm';
import VTextField from '../form/VTextField';
import VDatePicker from '../form/VDatePicker';
import ClassifierSelect from '../form/ClassifierSelect';
import VCheckbox from '../form/VCheckbox';
import { ObjectStatus } from '../../constants/classifierConstants';
import ConfirmButton from '../table/ConfirmButton';
import MapDialog from '../map/MapDialog';
import { showWarning } from '../../stores/notification';
import { setDrawingFeatures } from '../../stores/map/feature';
import VNumberField from '../form/VNumberField';
import MapUtils from '../../utils/MapUtils';
import { setActiveGeometries, toggleLayerDrawer } from '../../stores/map/map';
import RegisterUtils from '../../utils/RegisterUtils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

const handleClose = (navigate, returnPath) => {
  if (returnPath) {
    navigate(returnPath, { replace: true });
  } else {
    navigate(-1);
  }
  return true;
}

export default function RegisterForm() {
  const { t } = useTranslation();
  let { objectType, id } = useParams();

  const dispatch = useDispatch();
  const { objects, isLoading, formSaved, relationClassifiers } = useSelector(state => state.register);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [updatedGeometries, setUpdatedGeometrues] = useState({});
  const [geometryColumn, setGeometryColumn] = useState();

  const objectDef = RegisterUtils.getObjectDef(objectType);
  const columns = objectDef.columns;
  const item = objects[objectType];
  const fromPath = location.state?.from;
  const returnPath = fromPath || ((item?.id) ? `/register/view/${objectType}/${item.id}` : null);

  const { register, control, errors, handleSubmit, reset, setValue } = useForm({
    defaultValues: item
  });
  useEffect(() => id && (!item || Number(id) !== item.id) && dispatch(fetchDetails(objectType, id)), [dispatch, objectType, id, item]);
  useEffect(() => reset(item), [item, reset]);
  useEffect(() => {
    if (formSaved) {
      handleClose(navigate, returnPath);
      dispatch(resetFormSaved());
    }
  }, [formSaved, returnPath, navigate, dispatch]);

  useEffect(() => {
    objectDef.columns.filter(column => column.type === 'relation' && column.useAutocomplete).forEach(column => {
      if (!relationClassifiers[column.relation] || column.relation === 'cooperationPartnerCompany') {
        dispatch(fetchRelationClassifiers(column.relation, objectDef));
      }
    });
  }, [dispatch, relationClassifiers, objectDef]);

  const idEqual = !id ? !item?.id : item?.id === Number(id);
  if (!objectType || !item || !idEqual) {
    return <CenteredLoadingIndicator />;
  }

  const geometryColumns = RegisterUtils.getRegisterGeometryColumns(objectType);

  const renderFormField = (column, index) => {
    if (column.hideOnForm || (column.showInsertOnly && !!id) || (column.showUpdateOnly && !id)) {
      return;
    }
    const commonParams = {
      name: column.field,
      label: RegisterUtils.getColumnName(objectType, column),
      disabled: isLoading || column.readonly || (!!item.id && column.notUpdateable),
      required: column.required,
      errors: errors,
      fullWidth: true
    };
    let formField = null;
    switch (column.type) {
      case 'relation':
        if (column.useAutocomplete) {
          formField = <Controller
            render={({ value, onBlur, onChange }) => (
              <Autocomplete
                value={value}
                onChange={(event, value) => {
                  return onChange(value);
                }}
                onBlur={onBlur}
                options={relationClassifiers[column.relation] || []}
                getOptionLabel={column.autocompleteLabel}
                groupBy={column.autocompleteGroup}
                closeIcon={<Icon icon="arrowUp" />}
                popupIcon={<Icon icon="arrowDown" />}
                disableClearable
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                renderInput={(params) =>
                  <VTextField {...params} label={commonParams.label}
                    errors={commonParams.errors} fullWidth
                  />}
                freeSolo={true}
                disabled={commonParams.disabled}
              />
            )}
            name={column.relation}
            defaultValue={item[column.relation] || null}
            control={control}
          />;
        } else if (item[column.relation]) {
          formField = <VTextField
            disabled={true}
            label={commonParams.label}
            fullWidth={true}
            value={item[column.relation][column.relationField]}
          />;
        }
        break;
      case 'boolean':
        formField = <VCheckbox
          item={item}
          control={control}
          disabled={isLoading}
          {...commonParams}
        />;
        break;
      case 'classifier':
        formField = <ClassifierSelect
          item={item}
          control={control}
          classifierType={column.classifierType}
          {...commonParams}
        />;
        break;
      case 'date':
        formField = <VDatePicker
          item={item}
          control={control}
          {...commonParams}
        />;
        break;
      case 'integer':
        formField = <VNumberField
          control={control}
          InputProps={{
            endAdornment: !!column.unit && <InputAdornment position='end'>{column.unit}</InputAdornment>,
          }}
          {...commonParams}
        />
        break;
      case 'number':
        formField = <VNumberField
          decimals={column.decimals || 2}
          control={control}
          InputProps={{
            endAdornment: !!column.unit && <InputAdornment position='end'>{column.unit}</InputAdornment>,
          }}
          {...commonParams}
        />
        break;
      case 'textarea':
        formField = <VTextField
          register={register}
          multiline
          {...commonParams}
        />;
        break;
      case 'geometry':
        if (!item.drawGeometries) {
          formField = <Button onClick={() => handleEditGeometry(column)} disabled={isLoading}>
            <Icon icon="earth" color={Colors.sinineVaal} margin="6px" />
            {!!item[column.field] ? t('form.editGeometry') : t('form.setGeometry')}
            {geometryColumns?.length > 1 && ` - ${commonParams.label}`}
          </Button>;
        }
        break;
      default:
        formField = <VTextField
          register={register}
          {...commonParams}
        />;
    }
    if (formField) {
      return <Grid item sm={12} key={index}>
        {formField}
      </Grid>;
    }
  }

  const handleShowMap = () => {
    dispatch(MapUtils.showRegisterObjectOnMap(navigate, objectType, item));
  };

  const handleEditGeometry = (column) => {
    const relationGeometries = RegisterUtils.getRegisterRelationGeometries(objectType, item);
    if (relationGeometries.length) {
      dispatch(setActiveGeometries(relationGeometries));
    }
    setGeometryColumn(column);
    dispatch(toggleLayerDrawer(false));
    setShowMapDialog(true);
  };

  const onSetValid = () => {
    dispatch(setValidRegisterObject(objectType, item));
  };

  const onSetInvalid = () => {
    dispatch(setInvalidRegisterObject(objectType, item));
  }


  const title = item.id ? item.title : t('register.form.new');
  let actions;
  if (!objectDef.noValidButton) {
    if (item.id && item.status !== ObjectStatus.valid) {
      actions = <ConfirmButton aria-label="set register object valid" color="tertiary"
        message={t('register.form.confirmValid')}
        btnText={t('register.form.setValid')}
        disabled={isLoading}
        onConfirm={onSetValid} />
    } else if (item.id && item.status === ObjectStatus.valid) {
      actions = <ConfirmButton aria-label="set register object valid" color="tertiary"
        message={t('register.form.confirmInvalid')}
        btnText={t('register.form.setInvalid')}
        disabled={isLoading}
        onConfirm={onSetInvalid} />
    }
  }

  const handleConfrimMap = (newGeometry) => {
    if (!newGeometry) {
      dispatch(showWarning("validation.geometryRequired"));
      return;
    }
    setValue(geometryColumn.field, newGeometry);
    updatedGeometries[geometryColumn.field] = newGeometry;
    setUpdatedGeometrues(updatedGeometries);
    setShowMapDialog(false);
    setGeometryColumn(null);
  };


  const onSubmit = (data) => {
    if (item.postApiPathPrefix) {
      data.postApiPathPrefix = item.postApiPathPrefix;
    }
    columns.forEach(column => {
      if (column.type === 'relation' && !data[column.relation] && item[column.relation]) {
        data[column.relation] = item[column.relation];
      }
    });
    if (geometryColumns.length) {
      let invalid = false;
      geometryColumns.forEach(geometryColumn => {
        const geometryField = geometryColumn.field;
        if (!item.id && item[geometryField]) {
          data[geometryField] = item[geometryField];
        }
        if (updatedGeometries && updatedGeometries[geometryField]) {
          data[geometryField] = updatedGeometries[geometryField];
        }
        if (!data.id && !!geometryColumn.required && !data[geometryField] && !item.drawGeometries) {
          dispatch(showWarning("validation.geometryRequired"));
          invalid = true;
          return;
        }
      });
      if (invalid) {
        return;
      }
    }

    switch (objectType) {
      case 'careAreaTE':
      case 'careAreaSV':
      case 'careAreaTV':
        data.domain = objectDef.domain;
        break;
      default:
    }

    if (data.id) {
      dispatch(saveRegisterObject(objectType, data));
    } else {
      data.id = undefined;
      if (item.drawGeometries && geometryColumns.length) {
        const geomCol = geometryColumns[0];
        item.drawGeometries.filter(geometry => geomCol.geometryTypes.includes(geometry.type)).forEach(geometry => {
          data[geomCol.field] = geometry;
          dispatch(addRegisterObject(objectType, data));
        });
      } else {
        dispatch(addRegisterObject(objectType, data));
      }
    }
    dispatch(setDrawingFeatures([]));
  };

  return <>
    <DialogForm
      title={<span>
        <Heading level='3'>{title}</Heading>
        <Paragraph fontSize='14B'>{t(`register.${RegisterUtils.getSuperObjectType(objectType)}.label`)}
          {!!item.id && !!geometryColumns.length &&
            <Button color="primary" size="small" onClick={handleShowMap}>
              <Icon icon="earth" color={Colors.sinineVaal} margin="6px" />
              <span>{t('button.showMap')}</span>
            </Button>
          }
        </Paragraph>
      </span>}
      actions={actions}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => handleClose(navigate, returnPath)}
      disabled={isLoading}
    >
      <input type="hidden" name="id" ref={register} />
      <Paper elevation={0} className={classes.formBlock}>
        <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
          <Grid container item lg={7} direction="column" spacing={4}>
            {columns.map((column, index) => renderFormField(column, index))}
          </Grid>
        </Grid>
      </Paper>
    </DialogForm>
    {showMapDialog && <MapDialog
      geometry={item[geometryColumn.field]}
      geometryTypes={geometryColumn.geometryTypes}
      onConfirm={handleConfrimMap}
      onClose={() => setShowMapDialog(false)}
    />}
  </>;
}