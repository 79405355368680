import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Colors, Icon, Paragraph } from "styleguide";
import { resetSubmitted } from "../../stores/planning/planning";
import DialogForm from "../form/DialogForm";
import VTextField from "../form/VTextField";
import clsx from 'clsx';
import { saveOpinion } from "../../stores/planning/planningOperation";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
  formBlockContainer: {
    padding: 12,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
}));

export default function PlanningOpinionForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, operationId } = useParams();
  const { isLoading, submitted } = useSelector(state => state.planningOperation);

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {}
  });

  const [file, setFile] = useState(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      setFile(files[0]);
    }
  });

  useEffect(() => submitted && dispatch(resetSubmitted()) && navigate(-1), [submitted, dispatch, navigate]);

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    data.file = file;
    dispatch(saveOpinion(id, operationId, data));
  };

  return <DialogForm
    title={t('planning.view.addOpinion')}
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <VTextField name="content" label={t('planning.operation.opinion')}
          disabled={isLoading} maxLength={2000} multiline minRows={3}
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        {!!file ?
          <Grid container direction="row" justifyContent="space-between" className={classes.formBlockContainer}>
            <Paragraph fontSize='12'>{file.name}</Paragraph>
            <IconButton size="small" onClick={() => setFile(null)} aria-label="delete file">
              <Icon icon="delete" />
            </IconButton>
          </Grid>
          :
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
            <Grid container direction="row" className={classes.uploadRow} onClick={open}>
              <Icon icon='add' color={Colors.sinineVaal} />
              <Box marginLeft={2}>
                <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
              </Box>
              <input type="file" {...getInputProps()} />
            </Grid>
          </div>
        }
      </Grid>
    </Grid>
  </DialogForm>;
}
