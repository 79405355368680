import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { Button, Heading, Paragraph, Shadows, Tooltip } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ApplicationTypeIllustration from './ApplicationTypeIllustration';
import { setDetails } from '../../stores/application/application';
import { setActiveGeometries } from '../../stores/map/map';
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({
  addRow: {
    height: 82,
    marginBottom: 14,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 8,
    boxShadow: Shadows.shadow1,
    [theme.breakpoints.up('md')]: {
      padding: '10px 26px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '3px 5px'
    },
  },
  applicationTypeTitle: {
    flex: 1,
    paddingLeft: 16
  },
  addButton: {
    alignSelf: 'center'
  }
}));

function AddRow({ applicationType, title, onClick, showIcons, tooltipText }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Paper className={classes.addRow}>
    {showIcons && <ApplicationTypeIllustration applicationType={applicationType} />}
    <Paragraph className={classes.applicationTypeTitle} fontSize='16B'>{title}</Paragraph>
    <Button className={classes.addButton} size='extra-small' onClick={() => onClick(applicationType)}>
      {tooltipText ? <Tooltip title={tooltipText} placement='left' label={t('button.start')} /> : t('button.start')}
    </Button>
  </Paper>;
}

export default function AddApplicationBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const { classifiers } = useSelector(state => state.classifier);
  const mobile = useSelector(state => state.global.mobileView);

  const applicationTypes = classifiers.filter(c => c.typeCode === ClassifierType.applicationType && c.valid);

  const handleAddNew = (applicationType) => {
    dispatch(setActiveGeometries());
    dispatch(setDetails(null));
    navigate(`/application/form/${applicationType}`);
  };

  const handleOpis = () => {
    window.open(config.OPIS_URL, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={classes.container}>
      <Heading level='3' margin='26px'>{t('myApplications.addNew')}</Heading>
      {applicationTypes.map(at => (
        <AddRow key={at.itemCode} applicationType={at.itemCode} title={at.title} onClick={handleAddNew} showIcons={!mobile} />
      ))}
      <AddRow applicationType='opis' title={t('myApplications.opis')} onClick={handleOpis} showIcons={!mobile} tooltipText={t('myApplications.opisTooltip')} />
    </div>
  );
}