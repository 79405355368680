import {
  format,
  formatISO,
  isAfter,
  isBefore,
  isDate,
  parse,
  parseISO,
  subDays,
} from 'date-fns';

const DateUtils = {
  isValid(validFrom, validTo) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return !isBefore(now, this.parse(validFrom)) && (!validTo || !isAfter(now, this.parse(validTo)));
  },
  isPast(date) {
    return isBefore(this.parse(date), new Date());
  },
  isFuture(date) {
    return isAfter(this.parse(date), new Date());
  },
  isFutureDate(date) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return !isAfter(now, this.parse(date));
  },
  isAfter(after, before) {
    return isAfter(this.parse(after), this.parse(before));
  },
  formatDate(value) {
    try {
      return format(this.parse(value), 'dd.MM.yyyy');
    } catch (e) {
      return '';
    }
  },
  formatDateTime(value) {
    try {
      return format(this.parse(value), 'dd.MM.yyyy HH:mm');
    } catch (e) {
      return '';
    }
  },
  formatTime(value) {
    try {
      return format(this.parse(value), 'HH:mm');
    } catch (e) {
      return '';
    }
  },
  formatHour(value) {
    try {
      return format(this.parse(value), 'dd.MM HH');
    } catch (e) {
      return '';
    }
  },
  formatDay(value) {
    try {
      return format(this.parse(value), 'dd.MM');
    } catch (e) {
      return '';
    }
  },
  formatMonth(value) {
    try {
      return format(this.parse(value), 'MM.yy');
    } catch (e) {
      return '';
    }
  },
  dateColumn() {
    return {
      type: "date",
      width: 130,
      valueFormatter: ({ value }) => value && this.formatDate(value)
    };
  },
  dateTimeColumn() {
    return {
      type: "dateTime",
      width: 150,
      valueFormatter: ({ value }) => value && this.formatDateTime(value)
    };
  },
  parse(value) {
    if (!value) {
      return null;
    }
    return isDate(value) ? value : parseISO(value);
  },
  parseDateTime(value) {
    if (!value) {
      return null;
    }
    return parse(value, 'dd.MM.yyyy HH:mm', new Date());
  },
  formatISODate(value) {
    try {
      return formatISO(this.parse(value), { representation: 'date' });
    } catch (e) {
      return '';
    }
  },
  formatISODateTime(value) {
    try {
      return formatISO(this.parse(value), { representation: 'complete' });
    } catch (e) {
      return '';
    }
  },
  formatISODateTimeWOZone(value) {
    try {
      return format(this.parse(value), "yyyy-MM-dd'T'HH:mm:ss");
    } catch (e) {
      return '';
    }
  },
  nowISODate() {
    return formatISO(new Date(), { representation: 'date' });
  },
  getDeadlineColor(deadlineString) {
    const now = new Date();
    const deadline = this.parse(deadlineString);
    if (isBefore(subDays(deadline, -1), now)) {
      return 'red';
    }
    if (isAfter(subDays(deadline, 1), now)) {
      return 'blue';
    }
    return 'orange';
  }
};
export default DateUtils;