import { Grid, InputAdornment, MenuItem, Toolbar, makeStyles } from "@material-ui/core";
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Heading } from "styleguide";
import { Authority } from "../../constants/authConstants";
import { addDrawingGeometryToQueue } from "../../stores/map/feature";
import { setActiveFeatures } from "../../stores/map/map";
import { deletePlanningTempObject, fetchPlanningTempObjects, savePlanningTempObject } from "../../stores/planning/planningTempObject";
import AuthUtils from "../../utils/AuthUtils";
import MapUtils from "../../utils/MapUtils";
import ControlledSelect from "../form/ControlledSelect";
import VTextField from "../form/VTextField";
import ToolbarButton from "../table/ToolbarButton";
import ConfirmButton from "../table/ConfirmButton";

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
}));

export default function PlanTempObjectEdit() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const title = location.state?.data?.mapLayerTitle;
  const data = location.state?.data;

  const { authUser } = useSelector(state => state.auth);
  const { isLoading } = useSelector(state => state.planningTempObject);
  const { vectorLayerTypes } = useSelector(state => state.map);
  const [ color, setColor ] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { register, control, errors, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    setColor(MapUtils.convertHexToRgb(data?.color));
    setShowColorPicker(false);
    reset(data);
  }, [data, reset, setColor]);

  if (!data) {
    navigate(-1);
  }

  const onSubmit = (input) => {
    input.id = data.id;
    input.geometry = data.geometry;
    dispatch(savePlanningTempObject(input)).then(() => {
      dispatch(fetchPlanningTempObjects(input.type));
      if(data.type !== input.type) {
        dispatch(fetchPlanningTempObjects(data.type));
      }
      dispatch(setActiveFeatures());
      navigate('/map', { replace: true });
    });
  };

  const handleColorChange = (rgb) => {
    setColor(rgb);
    setValue('color', MapUtils.convertRgbToHex(rgb));
    setShowColorPicker(false);
  };

  const handleAddToDrawingLayer = (event) => {
    event.stopPropagation();
    dispatch(addDrawingGeometryToQueue(data.geometry));
  };

  const handleDelete = () => {
    dispatch(deletePlanningTempObject(data)).then(() => {
      dispatch(setActiveFeatures());
      navigate('/map', { replace: true })
    });
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Heading level='4'>{title}</Heading>
      </span>
      <span className={classes.flex}></span>
      {data.geometry && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
        <ToolbarButton compact={true} onClick={handleAddToDrawingLayer} icon="pencil" label={t('button.addToDrawingLayer')} />
      }
    </Toolbar>

    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <ControlledSelect name="type" label={t('planning.temp.type')}
            disabled={isLoading} 
            control={control} errors={errors} fullWidth required
          >
            {vectorLayerTypes.map(item => <MenuItem key={item.type} value={item.type}>{item.name}</MenuItem>)}
          </ControlledSelect>
        </Grid>
        <Grid item sm={12}>
          <VTextField name="name" label="planning.temp.name"
            disabled={isLoading} 
            register={register} errors={errors} fullWidth required
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={12}>
          <VTextField name="description" label="planning.temp.description"
            disabled={isLoading} multiline
            register={register} errors={errors} fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={12}>
          <VTextField name="color" label="planning.temp.color"
            disabled={isLoading} 
            register={register} errors={errors} fullWidth
            onFocus={() => setShowColorPicker(true)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: 
                <InputAdornment position="end" disableTypography>
                  <StopRoundedIcon 
                    style={{ height: 60, width: 60, marginRight: -25, color: MapUtils.convertRgbToHex(color) }}
                    onClick={() => setShowColorPicker(true)}
                  />
                </InputAdornment>
            }}
          />
          {showColorPicker && 
            <TwitterPicker triangle="hide" color={color || {}}
              onChangeComplete={(event) => {
                setShowColorPicker(false);
                return handleColorChange(event.rgb);
              }}
            />
          }
        </Grid>
        <Grid item sm={12} container justifyContent="flex-end">
          <Grid item sm={4}>
            <ConfirmButton message={t('form.confirmDelete')} onConfirm={handleDelete} btnText={t('button.delete')} color="tertiary"
              aria-label="delete object" size="small" disabled={isLoading} />
          </Grid>
          <Grid item sm={3}>
            <Button type="submit" size="small" disabled={isLoading}>{t('button.save')}</Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </div>;
}