import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { TableHeader } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import { fetchRowsGov, resetRows, resetSubmitted, updateFilter, updatePageable, updateUserFilter } from '../../stores/application/application';
import TableUtils from '../../utils/TableUtils';
import DateUtils from '../../utils/DateUtils';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import ApplicationStatusLabel from './ApplicationStatusLabel';
import CroppedText from '../table/CroppedText';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import RouteUtils from '../../utils/RouteUtils';
import UserFilter from '../table/UserFilter';
import NavigationTabs from '../table/NavigationTabs';
import LinkButton from '../LinkButton';
import ApplicationUtils from '../../utils/ApplicationUtils';
import { ApplicationType } from '../../constants/classifierConstants';
import { setDetails } from '../../stores/application/application';
import { setActiveGeometries } from '../../stores/map/map';

function ApplicationTabs() {
  const { t } = useTranslation();

  const renderNavLink = (domain) => <NavLink to={`/application/${domain}`}>{t(`application.tabs.${domain}`)}</NavLink>;
  return <NavigationTabs>
    {renderNavLink(Domain.plan)}
    {renderNavLink(Domain.rainwater)}
    {renderNavLink(Domain.streetlight)}
    {renderNavLink(Domain.road)}
    {renderNavLink("archive")}
  </NavigationTabs>;
}

export default function ApplicationTable({ domain, hideTabs }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.authUser);
  const { rows, filter, pageable, isLoading, totalElements, userFilter, submitted, selected } = useSelector(state => state.application);
  const { classifiers } = useSelector(state => state.classifier);

  const columns = useMemo(() => [
    { field: 'applicationNumber', headerName: t('application.applicationNumber'), filter: true },
    {
      field: 'location', headerName: t('application.location'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.location} length={50} />
      )
    },
    {
      field: 'address', headerName: t('application.address'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.address} length={50} />
      )
    },
    {
      field: 'status', headerName: t('application.status'), width: 150,
      type: 'classifier', classifierType: ClassifierType.applicationStatus, filter: true, renderCell: (column, cell) =>
        <ApplicationStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.applicationStatus, cell.status)} />
    },
    {
      field: 'deadline', headerName: t('application.deadline'), renderCell: (column, row) => {
        if (row.deadline && row.deadlineType) {
          const type = TableUtils.getClassifierTitle(classifiers, ClassifierType.deadlineType, row.deadlineType);
          const date = DateUtils.formatDate(row.deadline);
          return `${type} ${date}`;
        }
        return '';
      }
    },
    {
      field: 'handlerId', headerName: t('application.handler'), type: 'govUser', filter: true, notSortable: true,
      renderCell: (column, cell) => cell.handlerName,
    },
    { field: 'proceedingsDeadline', type: "date", headerName: t('application.proceedingsDeadline') },
  ], [t, classifiers]);

  useEffect(() => dispatch(resetRows()), [dispatch, domain]);
  useEffect(() => !!classifiers.length && !pageable.loaded && dispatch(fetchRowsGov(domain, pageable, filter, columns, userFilter)),
    [dispatch, pageable, filter, columns, classifiers, domain, userFilter]);

  const detailsPath = !!selected && `/application/view/${selected.id}`;
  useEffect(() => {
    if (submitted && !!detailsPath) {
      dispatch(resetSubmitted());
      navigate(detailsPath);
    }
  }, [dispatch, navigate, submitted, detailsPath]);

  const handleRowSelection = (selected) => {
    RouteUtils.navigateToObject(navigate, 'application', selected.id);
  };

  const handleAddNew = () => {
    dispatch(setActiveGeometries());
    dispatch(setDetails(null));
    navigate(`/application/form/${ApplicationType.DP}`);
  };

  const handleAddNewYP = () => {
    dispatch(setActiveGeometries());
    dispatch(setDetails(null));
    navigate(`/application/form/${ApplicationType.YP}`);
  }

  const handleAddNewKT = () => {
    dispatch(setActiveGeometries());
    dispatch(setDetails(null));
    navigate(`/application/form/${ApplicationType.KT}`);
  };

  const handleUserFilterChange = (event, filter) => {
    dispatch(updateUserFilter(filter));
  };

  const addDomainFilter = (domain) => {
    filterItems.push({
      value: domain,
      label: t(`application.filter.${domain}`)
    });
  };

  const filterItems = [{
    value: 'assignedToMe',
    label: t('application.filter.mine')
  }];
  if (domain !== 'archive') {
    filterItems.unshift({
      value: 'notProcessed',
      label: t('application.filter.notProcessed')
    });
    filterItems.push({
      value: 'forMyReview',
      label: t('application.filter.forMyReview')
    });
  } else {
    addDomainFilter(Domain.plan);
    addDomainFilter(Domain.rainwater);
    addDomainFilter(Domain.streetlight);
    addDomainFilter(Domain.road);
  }

  const isApplicationActive = (applicationType) => {
    return classifiers?.some(c => 
      c.typeCode === ClassifierType.applicationType && 
      c.itemCode === applicationType && c.valid);
  };

  const header = () => (
    <TableHeader buttons={<>
      {authUser &&
        ApplicationUtils.isGovCanAddApplication(authUser) &&
        domain === Domain.plan && <>
          <LinkButton size='extra-small' onClick={handleAddNew}>
            {t('application.form.add')}
          </LinkButton>
          {isApplicationActive(ApplicationType.KT) && 
            <LinkButton size='extra-small' onClick={handleAddNewKT}>
              {t('application.form.addKT')}
            </LinkButton>
          }
        </>
      }

      {authUser &&
        ApplicationUtils.isGovCanAddYPApplication(authUser) &&
        domain === Domain.plan &&
        <LinkButton size='extra-small' onClick={handleAddNewYP}>
          {t('application.form.addYP')}
        </LinkButton>
      }
    </>}>
      <Grid container direction="column">
        <Box paddingBottom={1}>
          {t(`application.tableTitle.${domain}`)}
          <TotalElementsLabel>{totalElements}</TotalElementsLabel>
        </Box>
        <UserFilter value={userFilter} items={filterItems} onChange={handleUserFilterChange} disabled={isLoading} />
      </Grid>
    </TableHeader>
  );

  return <div>
    {!hideTabs && <ApplicationTabs />}

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}