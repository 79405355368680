import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, NavLink } from 'react-router-dom';
import { TabContainer } from 'styleguide';
import { CenteredLoadingIndicator } from '../components/form/LoadingIndicator';
import UserNotificationConfig from '../components/user/UserNotificationConfig';
import UserNotificationRequestForm from '../components/user/UserNotificationRequestForm';
import UserNotificationTable from '../components/user/UserNotificationTable';
import PageContainer from '../parts/PageContainer';
import RouteUtils from '../utils/RouteUtils';

export default function UserNotification() {
  const { t } = useTranslation();

  const compact = useSelector(state => state.global.mobileView);
  const authenticated = useSelector(state => state.auth.authenticated);

  useEffect(() => {
    RouteUtils.setDocumentTitle('userNotification');
  }, []);

  if (!authenticated) {
    return <CenteredLoadingIndicator />;
  }

  return <PageContainer>
    <Grid container>
      <TabContainer size={compact ? 'small' : 'big'}>
        <NavLink to={`/notification`} end>{t('userNotification.notificationsTab')}</NavLink>
        <NavLink to={`/notification/config`}>{t('userNotification.configTab')}</NavLink>
      </TabContainer>

      <Grid item xs={12}>
        <Box my={5}>
          <Routes>
            <Route path="" element={<UserNotificationTable/>} />
            <Route path="/config" element={<UserNotificationConfig/>} />
            <Route path="/config/request/*" element={<UserNotificationRequestForm/>} />
          </Routes>
        </Box>
      </Grid>
    </Grid>
  </PageContainer>;
}