import React, { useEffect } from 'react';
import { BasicLabel } from 'styleguide';
import PageContainer from '../parts/PageContainer';
import { useTranslation } from 'react-i18next';
import UserTable from '../components/admin/UserTable';
import { NavLink, useLocation, Navigate, Routes, Route } from 'react-router-dom';
import UserForm from '../components/admin/UserForm';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import RoleTable from '../components/admin/RoleTable';
import RoleForm from '../components/admin/RoleForm';
import ClassifierTable from '../components/admin/ClassifierTable';
import ClassifierForm from '../components/admin/ClassifierForm';
import AuthUtils from '../utils/AuthUtils';
import { Authority } from '../constants/authConstants';
import BasicObjectTypeTable from '../components/admin/BasicObjectTypeTable';
import BasicObjectTypeForm from '../components/admin/BasicObjectTypeForm';
import ApplicationTypeTable from '../components/admin/ApplicationTypeTable';
import ApplicationTypeForm from '../components/admin/ApplicationTypeForm';
import PartnerTable from '../components/admin/PartnerTable';
import PartnerForm from '../components/admin/PartnerForm';
import PartnerUserForm from '../components/admin/PartnerUserForm';
import ActivityLogView from '../components/admin/ActivityLogView';
import NavigationTabs from '../components/table/NavigationTabs';
import ClauseConfForm from '../components/admin/ClauseConfForm';
import RouteUtils from '../utils/RouteUtils';
import MapLayersTable from '../components/admin/MapLayerTable';
import MapLayerForm from '../components/admin/MapLayerForm';
import SecureWrapper from '../components/auth/SecureWrapper';

export default function Admin() {
  const { t } = useTranslation();

  const { totalElements: userTotalElements } = useSelector(state => state.user);
  const { rows: roles } = useSelector(state => state.role);
  const { authUser } = useSelector(state => state.auth);
  const { classifiers } = useSelector(state => state.classifier);
  const { rows: basicObjectTypeRows } = useSelector(state => state.basicObjectType);
  const { rows: applicationTypeRows } = useSelector(state => state.applicationType);
  const { totalElements: partnerTotalElements } = useSelector(state => state.partner);
  const { rows: mapLayerRows } = useSelector(state => state.mapLayer);

  useEffect(() => {
    RouteUtils.setDocumentTitle('admin');
  }, []);

  let navTabs = [];
  if (AuthUtils.hasAuthority(authUser, Authority.UC22_admin_users)) {
    navTabs.push({
      to: "/admin/users",
      content: <span>
        {t('admin.usersTitle')}
        {!!userTotalElements && <BasicLabel isNumeric>{userTotalElements}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC20_admin_roles)) {
    navTabs.push({
      to: "/admin/roles",
      content: <span>
        {t('admin.rolesTitle')}
        {!!roles?.length && <BasicLabel isNumeric>{roles.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC21_admin_partners)) {
    navTabs.push({
      to: "/admin/partners",
      content: <span>
        {t('admin.partnersTitle')}
        {!!partnerTotalElements && <BasicLabel isNumeric>{partnerTotalElements}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC24_admin_classifiers)) {
    navTabs.push({
      to: "/admin/classifiers",
      content: <span>
        {t('admin.classifiersTitle')}
        {classifiers && classifiers.length > 0 && <BasicLabel isNumeric>{classifiers.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC5_admin_application_types)) {
    navTabs.push({
      to: "/admin/applicationTypes",
      content: <span>
        {t('admin.applicationsTitle')}
        {!!applicationTypeRows?.length && <BasicLabel isNumeric>{applicationTypeRows.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC30_admin_basic_object_types)) {
    navTabs.push({
      to: "/admin/basicObjectTypes",
      content: <span>
        {t('admin.basicObjectType.label')}
        {!!basicObjectTypeRows?.length && <BasicLabel isNumeric>{basicObjectTypeRows.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC33_admin_logs)) {
    navTabs.push({
      to: "/admin/logs",
      content: <span>
        {t('admin.logsTitle')}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC36_admin_map_layers)) {
    navTabs.push({
      to: "/admin/mapLayers",
      content: <span>
        {t('admin.mapLayersTitle')}
        {!!mapLayerRows?.length && <BasicLabel isNumeric>{mapLayerRows.length}</BasicLabel>}
      </span>
    });
  }

  const location = useLocation();

  if (navTabs.length === 0) {
    return <Navigate to="/" />;
  }

  if (location.pathname === '/admin') {
    //redirect to first allowed page
    return <Navigate to={navTabs[0].to} />
  }

  return <PageContainer>
    <NavigationTabs>
      {navTabs.map((tab, index) => <NavLink key={index} to={tab.to}>{tab.content}</NavLink>)}
    </NavigationTabs>

    <Box my={5}>
      <Routes>
        <Route path="/users" element={
          <SecureWrapper authority={Authority.UC22_admin_users}>
            <UserTable/>
          </SecureWrapper>
        }/>
        <Route path="/user/:userId?" element={
          <SecureWrapper authority={Authority.UC22_admin_users}>
            <UserForm/>
          </SecureWrapper>
        }/>

        <Route path="/roles" element={
          <SecureWrapper authority={Authority.UC20_admin_roles}>
            <RoleTable/>
          </SecureWrapper>
        }/>
        <Route path="/role/:roleId?" element={
          <SecureWrapper authority={Authority.UC20_admin_roles}>
            <RoleForm/>
          </SecureWrapper>
        }/>

        <Route path="/partners" element={
          <SecureWrapper authority={Authority.UC21_admin_partners}>
            <PartnerTable/>
          </SecureWrapper>
        }/>
        <Route path="/partner/:partnerId?" element={
          <SecureWrapper authority={Authority.UC21_admin_partners}>
            <PartnerForm/>
          </SecureWrapper>
        }/>
        <Route path="/partner/:partnerId?/user/:userId?" element={
          <SecureWrapper authority={Authority.UC21_admin_partners}>
            <PartnerUserForm/>
          </SecureWrapper>
        }/>

        <Route path="/classifiers/:typeCode?" element={
          <SecureWrapper authority={Authority.UC24_admin_classifiers}>
            <ClassifierTable/>
          </SecureWrapper>
        }/>
        <Route path="/classifier/:classifierId?/:typeCode?" element={
          <SecureWrapper authority={Authority.UC24_admin_classifiers}>
            <ClassifierForm/>
          </SecureWrapper>
        }/>

        <Route path="/basicObjectTypes" element={
          <SecureWrapper authority={Authority.UC30_admin_basic_object_types}>
            <BasicObjectTypeTable/>
          </SecureWrapper>
        }/>
        <Route path="/basicObjectType/:id?" element={
          <SecureWrapper authority={Authority.UC30_admin_basic_object_types}>
            <BasicObjectTypeForm/>
          </SecureWrapper>
        }/>

        <Route path="/applicationTypes" element={
          <SecureWrapper authority={Authority.UC5_admin_application_types}>
            <ApplicationTypeTable/>
          </SecureWrapper>
        }/>
        <Route path="/applicationType/:id?" element={
          <SecureWrapper authority={Authority.UC5_admin_application_types}>
            <ApplicationTypeForm/>
          </SecureWrapper>
        }/>
        <Route path="/clauseConf/:applicationType/*" element={
          <SecureWrapper authority={Authority.UC5_admin_application_types}>
            <ClauseConfForm/>
          </SecureWrapper>
        }/>
        
        <Route path="/logs" element={
          <SecureWrapper authority={Authority.UC33_admin_logs}>
            <ActivityLogView/>
          </SecureWrapper>
        }/>
        
        <Route path="/mapLayers" element={
          <SecureWrapper authority={Authority.UC36_admin_map_layers}>
            <MapLayersTable/>
          </SecureWrapper>
        }/>

        <Route path="/mapLayer/:id?" element={
          <SecureWrapper authority={Authority.UC36_admin_map_layers}>
            <MapLayerForm/>
          </SecureWrapper>
        }/>
      </Routes>
    </Box>
  </PageContainer>;
}