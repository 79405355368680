import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { BasicLabel, Search, TableHeader } from 'styleguide';
import { useNavigate, useParams } from "react-router-dom";
import LinkButton from '../LinkButton';
import { fetchClassifierTypes, filterClassifiers, updatePageable } from '../../stores/admin/classifier';
import TableSelect from '../table/TableSelect';
import { PageableTableContainer } from '../table/PageableTable';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  invalidRow: {
    opacity: 0.5
  },
}));

export default function ClassifierTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { typeCode } = useParams();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { types, selectedType, searchValue, filteredClassifiers, pageable, isLoading } = useSelector(state => state.classifier);

  useEffect(() => !types.length && dispatch(fetchClassifierTypes()), [dispatch, types]);
  useEffect(() => typeCode && selectedType && typeCode !== selectedType.code && dispatch(filterClassifiers(typeCode, searchValue)), [dispatch, typeCode, selectedType, searchValue]);

  if (!selectedType || !selectedType.code) {
    return <></>;
  }

  const header = () => (
    <TableHeader buttons={<>
      <Search id='vsearch2' placeholder={t('form.search')} onChange={handleSearch} defaultValue={searchValue} />
      {!selectedType.readonly &&
        <LinkButton size="extra-small" to={`/admin/classifier/new/${selectedType.code}`}>{t('button.add')}</LinkButton>
      }
    </>}>
      <TableSelect selected={selectedType.code} values={types} handleValueSelected={(type) => navigate(`/admin/classifiers/${type.code}`, { replace:true })} />
      {!!filteredClassifiers?.length && <BasicLabel isNumeric>{filteredClassifiers.length}</BasicLabel>}
    </TableHeader>
  );

  const columns = [
    { field: 'itemCode', headerName: t('classifier.code'), flex: 0.7 },
    { field: 'title', headerName: t('classifier.name'), flex: 0.7 },
    { field: 'description', headerName: t('classifier.description'), flex: 1 }
  ];

  const handleSearch = (searchValue) => {
    dispatch(filterClassifiers(selectedType, searchValue));
  };

  const handleRowSelection = (selected) => navigate(`/admin/classifier/${selected.id}`);

  return <PageableTableContainer
    rows={filteredClassifiers}
    columns={columns}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    rowClassNameFunction={(row) => !row?.valid && classes.invalidRow}
  />;
}