import { Grid, IconButton, makeStyles, Paper, Toolbar } from '@material-ui/core';
import { clone } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Colors, Heading, Icon, Shadows } from 'styleguide';
import { Authority } from '../../constants/authConstants';
import { addDrawingGeometryToQueue } from '../../stores/map/feature';
import AuthUtils from '../../utils/AuthUtils';
import MapUtils from '../../utils/MapUtils';
import ClauseFeatureView from '../application/clause/ClauseFeatureView';
import PlanningFeatureView from '../planning/PlanningFeatureView';
import PlanTempObjectEdit from '../planning/PlanningTempObjectEdit';
import PlanTempObjectView from '../planning/PlanningTempObjectView';
import RegisterView from '../register/RegisterView';
import ToolbarButton from '../table/ToolbarButton';

const vaalLinkVariable = '{{VAAL_LINK}}';

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: 'calc(100vh - 150px)',
    [theme.breakpoints.up('sm')]: {
      width: 410,
      right: 100,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      right: 0,
    },
    overflow: 'auto',
    position: 'absolute',
    bottom: 24,
    boxShadow: Shadows.shadow2,
    padding: 12
  },
  close: {
    float: 'right',
    paddingTop: 18
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal
  }
}));

const validateUrlPattern = (url) => {
  const pattern = new RegExp('(https://www.|http://www.|https://|http://)[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})?');
  if (pattern.test(url)) {
    return true;
  }
  return false;
};

function GenericJsonView() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authUser } = useSelector(state => state.auth);

  const title = location.state.data.mapLayerTitle;
  const mapLayerLayerName = location.state.data.mapLayerLayerName;

  const data = clone(location.state.data);
  delete data.mapLayerTitle;
  delete data.mapLayerLayerName;

  if (!data) {
    navigate(-1);
  }

  const prettyfyKey = (key) => {
    if(MapUtils.plankLayers.includes(mapLayerLayerName)){
      return key;
    }
    return MapUtils.prettyfyKey(key);
  }

  const handleAddToDrawingLayer = (event) => {
    event.stopPropagation();
    dispatch(addDrawingGeometryToQueue(data.geometry));
  };
    
  const getLabelValue = (key) => {
    let value = data[key];
    if(value) {
      if(typeof value === 'string' && value.startsWith(vaalLinkVariable)) {
        const url = value.replace(vaalLinkVariable, '');
        return <Link to={url}>{key}</Link>;
      }
      if(validateUrlPattern(value)) {
        value = <a href={value} className={classes.fileLink} title={value} target="_blank" rel="noreferrer">{value}</a>;
      }
    }
    return <>
      <span className={classes.label}>{prettyfyKey(key)}</span>
      {value}
    </>;
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Heading level='4'>{title}</Heading>
      </span>
      <span className={classes.flex}></span>
      {data.geometry && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
        <ToolbarButton compact={true} onClick={handleAddToDrawingLayer} icon="pencil" label={t('button.addToDrawingLayer')} />
      }
    </Toolbar>

    <Grid container spacing={0}>
      {Object.keys(data).filter(key => key !== 'id' && key !== 'geometry').map((key, index) => (
        <Grid key={index} item xs={12} lg={12}>
          <div className={classes.detailRowDense}>
            {getLabelValue(key)}
          </div>
        </Grid>
      ))}
    </Grid>
  </div>;
}

export default function FeatureDetailsDialog() {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClose = () => {
    navigate('/map', { replace: true });
  };

  return (
    <Paper className={classes.dialog}>
      <span className={classes.close}>
        <IconButton onClick={handleClose} size="small"><Icon icon="times" /></IconButton>
      </span>
      <Routes>
        <Route path="/r/viewPlanning/:objectType/:id" element={<PlanningFeatureView mapView />}/>
        <Route path="/r/viewClause/:id" element={<ClauseFeatureView mapView />}/>
        <Route path="/r/view/:objectType/:id/*" element={<RegisterView mapView />} />
        <Route path="/r/viewCenter/:objectType/:id/*" element={<RegisterView mapView mapCenter />} />
        <Route path="/g/view" element={<GenericJsonView/>} />
        <Route path="/p/view" element={<PlanTempObjectView/>}/>
        <Route path="/p/edit" element={<PlanTempObjectEdit/>}/>
      </Routes>
    </Paper>
  );
}