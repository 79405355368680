import React from 'react';
import { useSelector } from 'react-redux';
import AuthUtils from '../../utils/AuthUtils';
import { Navigate } from 'react-router-dom';

export default function SecureWrapper({ authority, role, children }) {
  const { authUser } = useSelector(state => state.auth);
  
  if (AuthUtils.isAuthorised(authUser, authority, role)) {
    return children;
  }

  return <Navigate to="/"/>;
}