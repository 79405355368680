import OLGeoJSON from 'ol/format/GeoJSON';
import { createEmpty, extend, getCenter } from 'ol/extent';
import { fetchCadastreWithCallback, fetchWFSGetFeatureDirect, toggleFeaturesDialog } from '../stores/map/feature';
import { setActiveGeometries, showLayersByRegisterObjectType } from '../stores/map/map';
import RegisterUtils from './RegisterUtils';
import TMSLayer from '../components/map/layers/TMSLayer';
import ImageWMSLayer from '../components/map/layers/ImageWMSLayer';
import { cloneDeep } from 'lodash';
import { WFS, WKT } from 'ol/format';
import Intersects from 'ol/format/filter/Intersects';
import TableUtils from './TableUtils';
import { toggleLoadingOverlay } from '../stores/notification';
import * as olProj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { Geolocation } from 'ol';
import { point } from "@turf/helpers";

proj4.defs("EPSG:3301", "+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu");
register(proj4);

const geoJsonFormat = new OLGeoJSON();
const MapUtils = {
  projection: olProj.get('EPSG:3301'),
  mapDpi: 200,
  dpi: 25.4 / 0.28,
  inchPerMeter: 100 / 2.54,
  scales: [
    2000000,
    500000,
    250000,
    100000,
    50000,
    20000,
    10000,
    5000,
    2000,
    1000,
    500,
    200,
    100
  ],
  cadastreLayerName: 'ky_kehtiv',
  cadastreWithAddressLayerName: ',ky_aadress',
  secondaryStyle: 'activeFeatureSecondary',
  tertiaryStyle: 'activeFeatureTertiary',
  quaternaryStyle: 'activeFeatureQuaternary',
  plankLayers: ['dp_kehtetu', 'dp_kehtiv', 'dp_osakehtiv'],
  geometriesToFeatures(geometries, style) {
    if (!geometries?.length) {
      return [];
    }
    return geometries.map(geometry => this.geometryToFeature(geometry, style));
  },
  geometryToFeature(geometry, style) {
    let feature = { type: "Feature", geometry };
    if (style) {
      feature.style = style;
    }
    return feature;
  },
  geometryToOLFeature(geometry) {
    if (!geometry) {
      return null;
    }
    return geoJsonFormat.readFeature(this.geometryToFeature(geometry));
  },
  toOLGeometry(geometry) {
    return geoJsonFormat.readGeometry(geometry);
  },
  toOLFeatures(features) {
    const featureCollection = features.length === 1 ? features[0] : {
      type: "FeatureCollection",
      features: features
    };

    return geoJsonFormat.readFeatures(featureCollection);
  },
  toOLFeature(feature) {
    return geoJsonFormat.readFeature(feature);
  },
  getFeaturesExtent(features) {
    let extent = createEmpty();
    features.filter(feature => feature.getGeometry()).forEach(feature => extend(extent, feature.getGeometry().getExtent()));
    return extent;
  },
  getExtentCenter(extent) {
    return getCenter(extent);
  },
  toGeoJSONFeatures(olFeatures) {
    if (!olFeatures) {
      return [];
    }
    return geoJsonFormat.writeFeaturesObject(olFeatures).features;
  },
  toGeoJSONGeometry(olFeature) {
    if (!olFeature || !olFeature.getGeometry()) {
      return null;
    }
    return geoJsonFormat.writeGeometryObject(olFeature.getGeometry());
  },
  toGeoJSONFeature(olFeature) {
    if (!olFeature || !olFeature.getGeometry()) {
      return null;
    }
    return geoJsonFormat.writeFeatureObject(olFeature);
  },
  toTurfFeature(olFeature) {
    if (!olFeature || !olFeature.getGeometry()) {
      return null;
    }
    const feature = cloneDeep(olFeature);
    feature.getGeometry().transform('EPSG:3301', 'EPSG:4326')
    return geoJsonFormat.writeFeatureObject(feature);
  },
  fromTurfFeature(turfFeature) {
    const olFeature = geoJsonFormat.readFeature(turfFeature);
    olFeature.getGeometry().transform('EPSG:4326', 'EPSG:3301');
    return olFeature;
  },
  fromTurfFeatures(turfFeatures) {
    if (!turfFeatures) {
      return null;
    }
    if (Array.isArray(turfFeatures)) {
      return turfFeatures.map(f => this.fromTurfFeature(f));
    } else {
      return [this.fromTurfFeature(turfFeatures)];
    }
  },
  handleLayerDrawerOpenClassName(container, open) {
    if (container) {
      if (open) {
        container.className += ' layer-drawer-open';
      } else {
        container.className = container.className.replace('layer-drawer-open', '');
      }
    }
  },
  showRegisterObjectOnMap: (navigate, objectType, object) => async dispatch => {
    const geometries = RegisterUtils.getRegisterGeometries(objectType, object);
    dispatch(MapUtils.showOnMap(navigate, geometries, objectType));
  },
  showOnMap: (navigate, geom, registerObjectType) => async dispatch => {
    dispatch(toggleFeaturesDialog(false));
    const geomArray = Array.isArray(geom) ? geom : [geom];
    dispatch(setActiveGeometries(geomArray));
    if (registerObjectType) {
      dispatch(showLayersByRegisterObjectType(registerObjectType));
    }
    navigate(`/map`);
  },
  getLegendGraphicUrl(layer, size, forceLabels) {
    if (!size) {
      size = 16;
    }
    let url = layer.serverUrl;
    if (!layer.serverUrl.endsWith('&')) {
      url += '?';
    }
    let legendOptions = 'fontAntiAliasing:true;fontName:Roboto;fontSize:12;fontColor:0x091E42;labelMargin:10;';
    if (!!forceLabels) {
      legendOptions += 'forceLabels:on;';
    } else {
      url += `WIDTH=${size}&`;
      legendOptions += 'forceLabels:off';
    }
    url += 'SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&';
    url += `HEIGHT=${size}&LAYER=${layer.layerName}&STYLE=${layer.style || ''}&LEGEND_OPTIONS=${legendOptions}`;
    return url;
  },
  parseFeatures(json) {
    if (!json) {
      return [];
    }
    let features = [];
    Object.keys(json).forEach(key => {
      if (Array.isArray(json[key])) {
        json[key].forEach(origFeature => {
          features.push(this.parseSingleFeature(origFeature));
        });
      } else if (key.endsWith('_feature')) {
        features.push(this.parseSingleFeature(json[key]));
      } else if (typeof json[key] === 'object') {
        features = [...features, ...this.parseFeatures(json[key])];
      }
    });
    return features;
  },
  parseSingleFeature(origFeature) {
    let feature = {};
    Object.keys(origFeature).filter(key => !key.startsWith('gml:')).forEach(key => feature[this.prettyfyKey(key)] = origFeature[key]);
    return feature;
  },
  prettyfyKey(text) {
    if (!text) {
      return '';
    }
    return (text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)).replace(/_/g, ' ');
  },
  getResolutionForScale(scale) {
    return parseFloat(scale) / (this.inchPerMeter * this.dpi);
  },
  getScaleForResolution(resolution) {
    return Math.round(parseFloat(resolution) * this.inchPerMeter * this.dpi);
  },
  getDefaultBaseLayer(layers) {
    //TODO how to find?
    return layers.filter(l => l.titleEst === 'Eesti kaart' && l.serviceType === 'TMS').map((layer, index) => (
      <TMSLayer key={index} url={layer.serverUrl} zIndex={layer.zIndex} queryable={false} />
    ));
  },
  getPhotoBaseLayer(layers) {
    //TODO how to find?
    return layers.filter(l => l.titleEst === 'Ortofoto' && l.serviceType === 'WMS').map((layer, index) => this.getWMSLayer(layer, index, false));
  },
  getCadastreLayer(layers) {
    //TODO how to find?
    return layers.filter(l => l.serviceType === 'WMS' && l.layerName?.toLowerCase() === this.cadastreLayerName)
      .map((layer, index) => this.getWMSLayer(layer, index, true));
  },
  getCadastreWithAddressLayer(layers) {
    //TODO how to find?
    return layers.filter(l => l.serviceType === 'WMS' && l.layerName?.toLowerCase().indexOf(this.cadastreWithAddressLayerName) !== -1)
      .map((layer, index) => this.getWMSLayer(layer, index, true));
  },
  getDomainLayers(layers, domain) {
    return layers.filter(l =>
      l.domain === domain ||
      l.mapGroup?.domain === domain ||
      l.mapGroup?.parentGroup?.domain === domain
    ).map((l, index) => this.getWMSLayer(l, index, false));
  },
  getLayerByLayerName(layers, layerName, filter, zIndex) {
    return layers.filter(l => l.serviceType === 'WMS' && l.layerName?.toUpperCase() === layerName.toUpperCase())
      .map((layer, index) => this.getWMSLayer(layer, index, false, filter, zIndex));
  },
  getWMSLayer(layer, index, queryable, cqlFilter, zIndex) {
    return <ImageWMSLayer key={index} url={layer.serverUrl} layers={layer.layerName} zIndex={zIndex || layer.zIndex}
      queryable={queryable} title={layer.title} layerLayerName={layer.layerLayerName} cqlFilter={cqlFilter} styles={layer.style} />
  },
  toWkt(geoJsonGeometries) {
    return geoJsonGeometries.map(geometry => {
      const olGeometry = geoJsonFormat.readGeometry(geometry);
      return new WKT().writeGeometry(olGeometry);
    });
  },
  copyToClipboard(text) {
    if (!navigator.clipboard) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    } else {
      navigator.clipboard.writeText(text);
    }
  },
  convertHexToRgb(hex) {
    if(!hex){
      return null;
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  },
  convertRgbToHex(rgb) {
    if(!rgb){
      return null;
    }
    // eslint-disable-next-line no-mixed-operators
    return "#" + (1 << 24 | rgb.r << 16 | rgb.g << 8 | rgb.b).toString(16).slice(1);
  },
  convertToRgbString(color) {
    return !!color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a || 1})` : '';
  },
  fetchFeaturesByLayerName: async (layers, layerName, geometry, dispatch) => {
    const olGeometry = geoJsonFormat.readGeometry(geometry);
    const layer = layers.find(l => l.layerName?.toUpperCase() === layerName.toUpperCase());
    if (olGeometry && layer) {
      const featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:3301',
        featureTypes: [layerName],
        outputFormat: 'application/json',
        geometryName: layer.geometryName,
        bbox: olGeometry.getExtent(),
        filter: new Intersects(layer.geometryName, olGeometry)
      });

      const result = await dispatch(fetchWFSGetFeatureDirect(layer.serverUrl, featureRequest));
      return result;
    }
  },
  formatLength: (line) => {
    const length = line.getLength();
    let output;
    if (length > 100) {
      output = TableUtils.formatNumber(Math.round((length / 1000) * 100) / 100) + ' km';
    } else {
      output = TableUtils.formatNumber(Math.round(length * 100) / 100) + ' m';
    }
    return output;
  },
  formatArea: (area) => {
    let output, unit;
    if (area > 10000000) {
      output = area / 1000000;
      unit = 'km\u00B2';
    } else if (area > 10000) {
      output = area / 10000;
      unit = 'ha';
    } else {
      output = area;
      unit = 'm\u00B2';
    }
    return `${TableUtils.formatNumber(Math.round(output * 100) / 100)} ${unit}`;
  },
  handleCadastreSelect: (evt) => {
    const map = evt.map;
    const dispatch = map.dispatch;
    const view = map.getView();
    const viewResolution = view.getResolution();
    const coordinate = evt.coordinate;

    map.getLayers().forEach((layer) => {
      const source = layer.getSource();
      if (source.getFeatureInfoUrl && layer.getSource().getParams().LAYERS?.toLowerCase().indexOf(MapUtils.cadastreLayerName) !== -1) {
        const externalUrl = layer.getSource().getFeatureInfoUrl(
          coordinate,
          viewResolution,
          'EPSG:3301',
          {
            'INFO_FORMAT': 'application/json',
            'FEATURE_COUNT': 1,
            'QUERY_LAYERS': MapUtils.cadastreLayerName
          }
        );
        dispatch(fetchCadastreWithCallback(externalUrl, coordinate));
        dispatch(toggleLoadingOverlay(true));
        return;
      }
    });
  },
  getLocation: (onSuccess, onError) => {
    const geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: MapUtils.projection
    });
    geolocation.on('change', function (evt) {
      const position = geolocation.getPosition();
      if (position) {
        onSuccess(point(position));
        geolocation.setTracking(false);
      }
    });
    geolocation.on('error', function (error) {
      onError(error.message);
      geolocation.setTracking(false);
    });
  },
  strip: (geom, test) => {
    if (!geom.length) return;
    if (typeof geom[0] !== "number") {
        return geom.map(g => MapUtils.strip(g, test));
    }
    return geom.filter(test);
  },
  stripZFromFeature: (feature) => {
    if(feature?.getGeometry) {
      const geom = feature.getGeometry();
      if(geom && geom.stride > 2){ 
        const coords = feature.getGeometry().getCoordinates();
        geom.setCoordinates(MapUtils.strip(coords, (v, i) => 2 > i % geom.stride));
      }
    }
  }
};

export default MapUtils;