import shpwrite from "@mapbox/shp-write";
import { CircularProgress, SvgIcon, Switch, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import booleanIntersects from "@turf/boolean-intersects";
import buffer from "@turf/buffer";
import difference from "@turf/difference";
import lineSplit from "@turf/line-split";
import union from "@turf/union";
import { saveAs } from 'file-saver';
import { Overlay } from "ol";
import { click } from "ol/events/condition";
import { Circle, LineString, MultiLineString, MultiPolygon, Polygon } from "ol/geom";
import MultiPoint from "ol/geom/MultiPoint";
import OLMultiPolygon from 'ol/geom/MultiPolygon';
import OLDraw from 'ol/interaction/Draw';
import OLModify from 'ol/interaction/Modify';
import OLSelect from 'ol/interaction/Select';
import OLSnap from 'ol/interaction/Snap';
import OLTranslate from 'ol/interaction/Translate';
import { default as OLVectorLayer, default as VectorLayer } from "ol/layer/Vector";
import { unByKey } from "ol/Observable";
import OLVectorSource from "ol/source/Vector";
import { useContext, useEffect, useState } from "react";
import { TwitterPicker } from 'react-color';
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Icon, Tooltip } from "styleguide";
import { Authority } from "../../../constants/authConstants";
import { GeometryType } from "../../../constants/mapConstants";
import { uploadCadFile } from "../../../stores/cad2GeoJson";
import { removeDrawingFeatureFromQueue, setDrawingFeatures } from "../../../stores/map/feature";
import { centerFeatures, setActiveDrawVectorLayer, toggleActiveControl, toggleSelectActive } from "../../../stores/map/map";
import { showInfo, showWarning } from "../../../stores/notification";
import { savePlanningTempFeature, updatePlanningTempFeature } from "../../../stores/planning/planningTempObject";
import { setDetails } from "../../../stores/workOrder/workOrder";
import AuthUtils from "../../../utils/AuthUtils";
import MapUtils from "../../../utils/MapUtils";
import LayerStyles from "../LayerStyles";
import MapContext from "../MapContext";
import MapControlButton from "./MapControlButton";

const ActionButton = ({ type, icon, activeType, handleClick, handleDblClick }) => {
  const { t } = useTranslation();
  const isActive = activeType === type;
  const activeClass = isActive ? 'active' : '';

  return <Tooltip title={t(`map.control.draw${type}`)} placement="right">
    <button className={`${type} ${activeClass}`}
      actiontype={type}
      onClick={() => handleClick(type)}
      onDoubleClick={handleDblClick}>
      <Icon icon={icon} color={isActive ? Colors.sinineVaal : 'white'} />
    </button>
  </Tooltip>
};

const ColorPickerButton = ({ handleChange, color }) => {
  const { t } = useTranslation();
  const type = 'ColorPicker';

  const [showColorPicker, setShowColorPicker] = useState(false);

  return <span>
    <Tooltip title={t(`map.control.drawColorPicker`)} placement="right">
      <button className={`${type}`}
        actiontype={type}
        onClick={() => setShowColorPicker(!showColorPicker)}>
        <SvgIcon style={{ color: MapUtils.convertToRgbString(color), border: '2px solid white', borderRadius: 4 }}>
          <rect opacity="1" width="24" height="24" rx="2" />
        </SvgIcon>
      </button>
    </Tooltip>
    {showColorPicker &&
      <TwitterPicker triangle="hide" color={color}
        onChangeComplete={(event) => {
          setShowColorPicker(false);
          return handleChange(event.rgb);
        }}
      />}
  </span>
};


const SnapSwitch = withStyles((theme) => ({
  root: {
    width: 24,
    height: 12,
    padding: 0,
    margin: '15px auto',
    display: 'flex',
  },
  switchBase: {
    padding: 0.75,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.primary.main,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.white,
      },
    },
  },
  thumb: {
    width: 10.5,
    height: 10.5,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 12 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const UploadCadFile = () => {
  const dispatch = useDispatch();
  const { job, isLoading } = useSelector(state => state.cad2GeoJson);

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    accept: '.dwg, .DWG, .dgn, .DGN',
    maxSize: 2048576,
    onDrop: (files) => {
      if(files?.length){
        dispatch(uploadCadFile(files[0]));
      } else {
        dispatch(showWarning('map.cad2GeoJson.unsupportedFile'));
      }
    }
  });

  if(!!job || isLoading) {
    return <CircularProgress size={24} thickness={7} />;
  }

  return <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Icon icon="uploadMap" color={isDragActive ? Colors.sinineVaal : 'white'}/>
    </div>;
};

const deleteAllFeatures = (drawLayer, dispatch) => {
  drawLayer.getSource().clear();
  dispatch(setDrawingFeatures());
};

const formatAreaPolygon = (polygon) => MapUtils.formatArea(polygon.getArea());

const formatAreaCircle = (circle) => MapUtils.formatArea(Math.PI * Math.pow(circle.getRadius(), 2));

const MultiDrawControl = ({ restrictedGeometryTypes, geometry, setFeatureCallback, defaultActive, allowSingleFeature }) => {
  const controlName = 'draw';
  const { map } = useContext(MapContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activeControl, activeDrawVectorLayer } = useSelector(state => state.map);
  const { drawingFeaturesQueue } = useSelector(state => state.feature);
  const { authUser } = useSelector(state => state.auth);
  const [type, setType] = useState(restrictedGeometryTypes?.length ? restrictedGeometryTypes[0] : GeometryType.POINT);
  const [showAddWorkOrder, setShowAddWorkOrder] = useState(false);
  const [drawLayer, setDrawLayer] = useState();
  const [initDrawLayer, setInitDrawLayer] = useState();
  const [snapEnabled, setSnapEnabled] = useState(true);
  const [color, setColor] = useState({ r: 127, g: 92, b: 242, a: 1 });

  const isActive = activeControl === controlName;
  const hasWorkOrderPermission = AuthUtils.hasAuthority(authUser, Authority.UC26_work_orders);

  useEffect(() => {
    if (!map) return;

    const layer = new OLVectorLayer({
      source: new OLVectorSource(),
      style: LayerStyles.drawedFeature,
      zIndex: 1000
    });
    layer.getSource().on('addfeature', () => dispatch(setDrawingFeatures(layer.getSource().getFeatures())));
    layer.getSource().on('changefeature', () => dispatch(setDrawingFeatures(layer.getSource().getFeatures())));
    layer.getSource().on('removefeature', () => dispatch(setDrawingFeatures(layer.getSource().getFeatures())));

    if (geometry) {
      const feature = MapUtils.geometryToOLFeature(geometry);
      layer.getSource().addFeature(feature);
      dispatch(centerFeatures([feature]));
    }

    map.addLayer(layer);
    setDrawLayer(layer);
    setInitDrawLayer(layer);

    if (!!defaultActive) {
      dispatch(toggleActiveControl(controlName, true));
    }

    return () => {
      map.removeLayer(layer);
    }
  }, [map, dispatch, geometry, defaultActive]);

  useEffect(() => {
    if(drawLayer && drawingFeaturesQueue?.length) {
      const featureCollection = MapUtils.toOLFeatures(drawingFeaturesQueue);
      drawLayer.getSource().addFeatures(featureCollection);

      const features = featureCollection.features || featureCollection;
      if (activeDrawVectorLayer) {
        features.forEach(feature => {
          dispatch(savePlanningTempFeature(feature, drawLayer.get('title'), activeDrawVectorLayer, color, true));
        });
      }
      dispatch(removeDrawingFeatureFromQueue());
    }
  }, [dispatch, drawLayer, drawingFeaturesQueue, activeDrawVectorLayer, color]);

  const canEditVectorLayers = !setFeatureCallback && AuthUtils.hasAuthority(authUser, Authority.UC41_edit_drawing_layer);
  useEffect(() => {
    if(map){
      if(isActive){
        setDrawLayer(initDrawLayer);
        dispatch(setActiveDrawVectorLayer());
        
        if(canEditVectorLayers) {
          const tempDrawingLayers = map.getLayers().getArray().filter(layer => layer instanceof VectorLayer && layer.get('type'));
          if(tempDrawingLayers.length) {
            const layer = tempDrawingLayers[0];
            setDrawLayer(layer);
            dispatch(setActiveDrawVectorLayer(layer.get('type')));
            if(tempDrawingLayers.length > 1) {
              dispatch(showInfo('map.multipleDrawingLayers', {title: layer.get('title')}));
            }
          }
        }
      } else {
        dispatch(setActiveDrawVectorLayer());
      }
    }
  }, [map, isActive, initDrawLayer, dispatch, canEditVectorLayers]);

  useEffect(() => {
    if (!map || !drawLayer || !isActive) return;
    let draw, modify, remove, drag, select, sketch, measureListener;
    const snapInteractions = [];

    const createMeasureTooltip = () => {
      if(!map.getOverlayById('measure-tooltip')){
        const measureTooltipElement = document.createElement('div');
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        const measureTooltip = new Overlay({
          id: 'measure-tooltip',
          element: measureTooltipElement,
          offset: [0, -15],
          positioning: 'bottom-center',
          stopEvent: false,
          insertFirst: false,
        });
        map.addOverlay(measureTooltip);
      }
    };

    const removeMeasureTooltip = () => {
      const measureTooltip = map.getOverlayById('measure-tooltip');
      if(measureTooltip) {
        map.removeOverlay(measureTooltip);
      };
    };

    const createMeasureListener = (feature, coordinate) => {
      const measureTooltip = map.getOverlayById('measure-tooltip');

      // set sketch
      sketch = feature;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      let tooltipCoord = coordinate;

      measureListener = sketch.getGeometry().on('change', function (evt) {
        const geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = formatAreaPolygon(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if(geom instanceof MultiPolygon) {
          output = formatAreaPolygon(geom);
          tooltipCoord = geom.getInteriorPoints().getCoordinates();
        } else if (geom instanceof LineString || geom instanceof MultiLineString) {
          output = MapUtils.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        } else if (geom instanceof Circle) {
          output = formatAreaCircle(geom);
          tooltipCoord = geom.getCenter();
        }
        measureTooltip.getElement().innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    };

    const createDrawInteraction = (geometryType) => {
      draw = new OLDraw({
        source: drawLayer.getSource(),
        type: geometryType,
        style: LayerStyles.drawingFeature
      });
      map.addInteraction(draw);

      draw.on('drawstart', (evt) => {
        createMeasureTooltip();
        if([GeometryType.LINE_STRING, GeometryType.POLYGON].includes(type)) {
          createMeasureListener(evt.feature, evt.coordinate);
        }
      });
      draw.on('drawend', (event) => {
        if([GeometryType.LINE_STRING, GeometryType.POLYGON].includes(type)) {
          sketch = null;
          unByKey(measureListener);
        }
      });
    };

    switch (type) {
      case GeometryType.POINT:
      case GeometryType.LINE_STRING:
      case GeometryType.POLYGON:
        createDrawInteraction(type);

        draw.on('drawstart', (evt) => {
          if(setFeatureCallback && allowSingleFeature) {
            drawLayer.getSource().clear();
          }
        });
        draw.on('drawend', (event) => {
          event.feature.setStyle(LayerStyles.drawedFeatureWithCustomColor(color));
          if(setFeatureCallback) {
            setFeatureCallback(MapUtils.toGeoJSONGeometry(event.feature));
          } else if (hasWorkOrderPermission) {
            let isPoint = event.feature.getGeometry().getType() === GeometryType.POINT;
            isPoint &= drawLayer.getSource().getFeatures().every(f => f.getGeometry().getType() === GeometryType.POINT);
            setShowAddWorkOrder(isPoint);
          }
          if (activeDrawVectorLayer) {
            dispatch(savePlanningTempFeature(event.feature, drawLayer.get('title'), activeDrawVectorLayer, color));
          }
        });
        break;
      case GeometryType.MULTI_POINT:
      case GeometryType.MULTI_LINE_STRING:
      case GeometryType.MULTI_POLYGON:
        createDrawInteraction(type);

        draw.on('drawend', (event) => {
          if (setFeatureCallback) {
            let feature = event.feature;
            drawLayer.getSource().forEachFeature(f => {
              switch (f.getGeometry().getType()) {
                case GeometryType.POINT:
                  feature.getGeometry().appendPoint(f.getGeometry());
                  break;
                case GeometryType.MULTI_POINT:
                  f.getGeometry().getPoints().forEach(p => feature.getGeometry().appendPoint(p));
                  break;
                case GeometryType.LINE_STRING:
                  feature.getGeometry().appendLineString(f.getGeometry());
                  break;
                case GeometryType.MULTI_LINE_STRING:
                  f.getGeometry().getLineStrings().forEach(p => feature.getGeometry().appendLineString(p));
                  break;
                case GeometryType.POLYGON:
                  feature.getGeometry().appendPolygon(f.getGeometry());
                  break;
                case GeometryType.MULTI_POLYGON:
                  f.getGeometry().getPolygons().forEach(p => feature.getGeometry().appendPolygon(p));
                  break;
                default:
              }
            });
            drawLayer.getSource().clear();
            setFeatureCallback(MapUtils.toGeoJSONGeometry(feature))
          }
          if (activeDrawVectorLayer) {
            dispatch(savePlanningTempFeature(event.feature, drawLayer.get('title'), activeDrawVectorLayer, color));
          }
        });
        break;
      case 'Modify':
        createMeasureTooltip();
        modify = new OLModify({ source: drawLayer.getSource(), style: LayerStyles.drawingFeature });
        map.addInteraction(modify);

        modify.on('modifystart', (evt) => {
          evt.features.forEach(feature => {
            createMeasureListener(feature, evt.coordinate);
          });
        });

        modify.on('modifyend', (event) => {
          if (setFeatureCallback) {
            setFeatureCallback(MapUtils.toGeoJSONGeometry(event.features.item(0)));
          }
          if (activeDrawVectorLayer) {
            dispatch(updatePlanningTempFeature(event.features.item(0), drawLayer.get('title'), activeDrawVectorLayer));
          }
        });
        
        break;
      case 'Delete':
        remove = new OLSelect({
          layers: [drawLayer]
        });
        remove.on('select', (evt) => {
          if (evt.mapBrowserEvent?.originalEvent?.altKey) {
            deleteAllFeatures(drawLayer, dispatch);
          } else if (evt.selected && evt.selected.length) {
            const feature = evt.selected[0];
            const geometry = feature.getGeometry();
            switch (geometry.getType()) {
              case GeometryType.MULTI_POINT:
                const closestPoint = feature.getGeometry().getClosestPoint(evt.mapBrowserEvent.coordinate);
                geometry.setCoordinates(geometry.getCoordinates().filter(c => !(c[0] === closestPoint[0] && c[1] === closestPoint[1])));
                break;
              case GeometryType.MULTI_POLYGON:
                const newGeom = new OLMultiPolygon({ coordinates: [] });
                const polygons = feature.getGeometry().getPolygons();
                polygons.filter(p => !p.intersectsCoordinate(evt.mapBrowserEvent.coordinate)).forEach(p => newGeom.appendPolygon(p));
                feature.setGeometry(newGeom);
                break;
              default:
                drawLayer.getSource().removeFeature(feature);
            }
            remove.getFeatures().clear();
            if (setFeatureCallback && drawLayer.getSource().getFeatures().length) {
              setFeatureCallback(MapUtils.toGeoJSONGeometry(drawLayer.getSource().getFeatures()[0]));
            }
          }
        });

        map.addInteraction(remove);

        removeMeasureTooltip();
        break;
      case 'Drag':
        select = new OLSelect({
          layers: [drawLayer],
          style: LayerStyles.selectedFeature
        });
        map.addInteraction(select);

        drag = new OLTranslate({
          features: select.getFeatures()
        });
        map.addInteraction(drag);
        drag.on('translateend', (event) => {
          if (setFeatureCallback) {
            setFeatureCallback(MapUtils.toGeoJSONGeometry(event.features.item(0)));
          }
          if (activeDrawVectorLayer) {
            dispatch(updatePlanningTempFeature(event.features.item(0), drawLayer.get('title'), activeDrawVectorLayer));
          }
        });
        break;
      case 'Divide':
        createDrawInteraction(GeometryType.LINE_STRING);

        draw.on('drawend', (event) => {
          const line = MapUtils.toTurfFeature(event.feature);

          drawLayer.getSource().getFeatures().forEach(olFeature => {
            const geometryType = olFeature.getGeometry().getType();
            if (geometryType === GeometryType.LINE_STRING ||
              geometryType === GeometryType.POLYGON ||
              geometryType === GeometryType.MULTI_POLYGON) {
              const feature = MapUtils.toTurfFeature(olFeature);
              if (booleanIntersects(line, feature)) {
                if (geometryType === GeometryType.LINE_STRING) {
                  const splitFeatures = lineSplit(feature, line);
                  if (splitFeatures) {
                    drawLayer.getSource().addFeatures(MapUtils.fromTurfFeatures(splitFeatures.features));
                  }
                } else {
                  const linePolygon = buffer(line, 0.1, { units: 'meters' });
                  let diff = difference(feature, linePolygon);
                  if (diff) {
                    //if (diff.type === 'Feature' && diff.geometry?.type === GeometryType.MULTI_POLYGON) {
                    //  diff = diff.geometry.coordinates.map(coordinates => polygon(coordinates));
                    //}
                    drawLayer.getSource().addFeatures(MapUtils.fromTurfFeatures(diff));
                  }
                }
                drawLayer.getSource().removeFeature(olFeature);
              }
            }
          });

          drawLayer.getSource().once('addfeature', (event) => drawLayer.getSource().removeFeature(event.feature));
        });
        break;
      case 'Union':
        select = new OLSelect({
          layers: [drawLayer],
          style: LayerStyles.selectedFeature,
          toggleCondition: click,
          condition: click,
          filter: (feature) => feature.getGeometry().getType() === GeometryType.POLYGON
        });
        map.addInteraction(select);

        select.on('select', () => {
          const features = select.getFeatures();
          if (features.getLength() === 2) {
            const f1 = MapUtils.toTurfFeature(features.item(0));
            const f2 = MapUtils.toTurfFeature(features.item(1));
            if (booleanIntersects(f1, f2)) {
              const unionFeature = union(f1, f2);
              drawLayer.getSource().addFeature(MapUtils.fromTurfFeature(unionFeature));
              drawLayer.getSource().removeFeature(features.item(0));
              drawLayer.getSource().removeFeature(features.item(1));
            } else {
              dispatch(showWarning('error.map.doNotIntersect'));
            }
            features.clear();
          }
        });

        break;
      case 'Text':
        createDrawInteraction(GeometryType.POINT);

        draw.on('drawend', (event) => {
          const point = event.feature;
          const textField = document.getElementById('draw-text-input');
          point.setStyle(LayerStyles.textOnly(textField.value));
          point.set('ignored', true);
          textField.value = '';
        });
        break;
      default:
    }

    if (snapEnabled) {
      if (map.vectorLayer) {
        map.vectorLayer.forEach(l => {
          const snap = new OLSnap({ source: l.getSource() });
          map.addInteraction(snap);
          snapInteractions.push(snap);
        });
      }

      const snap = new OLSnap({ source: drawLayer.getSource() });
      map.addInteraction(snap);
      snapInteractions.push(snap);
    }

    dispatch(toggleSelectActive(!type));

    return () => {
      draw && map.removeInteraction(draw);
      modify && map.removeInteraction(modify);
      remove && map.removeInteraction(remove);
      drag && map.removeInteraction(drag);
      select && map.removeInteraction(select);
      snapInteractions && snapInteractions.forEach(snap => map.removeInteraction(snap));
      removeMeasureTooltip();
    }
  }, [dispatch, map, isActive, type, drawLayer, setFeatureCallback, allowSingleFeature, hasWorkOrderPermission, snapEnabled, color, activeDrawVectorLayer]);

  const handleActionClick = (newType) => {
    if (type === newType) {
      setType(null);
    } else {
      setType(newType);
    }
  };

  const handleAddWorkOrder = () => {
    const features = drawLayer.getSource().getFeatures().filter(f => !f.get('ignored'));
    if (features.length === 0) {
      return;
    }
    let feature = features[0];
    if (features.length > 1) {
      let geometry = new MultiPoint([]);
      features.forEach(f => {
        geometry.appendPoint(f.getGeometry());
      });
      feature.setGeometry(geometry);
    }
    dispatch(setDetails({ geometry: MapUtils.toGeoJSONGeometry(feature) }));
    navigate('/workOrder/edit');
  };

  const handleDownloadShapefile = () => {
    const features = drawLayer.getSource().getFeatures();
    if(!features.length) {
      return;
    }
    const title = drawLayer.get('title') || 'joonistuskiht';
    const geoJsonFeatures = MapUtils.toGeoJSONFeatures(features);
    geoJsonFeatures.filter(f => !!f.properties?.procedureType).forEach(f => delete f.properties?.procedureType);
    const featureCollection = {
      type: "FeatureCollection",
      features: geoJsonFeatures
    };
    
    const options = {
      folder: title,
      outputType: "blob",
      compression: "DEFLATE",
    };
    shpwrite.zip(featureCollection, options).then(data => saveAs(data, `${title}.zip`));
  };

  const renderActionButton = (actionType, icon) => {
    if (!restrictedGeometryTypes || restrictedGeometryTypes.includes(actionType)) {
      return <ActionButton type={actionType} icon={icon || `draw${actionType}`} activeType={type} handleClick={handleActionClick} />;
    }
    return <></>;
  }

  const canDrawLine = !restrictedGeometryTypes ||
    restrictedGeometryTypes.includes(GeometryType.LINE_STRING) ||
    restrictedGeometryTypes.includes(GeometryType.MULTI_LINE_STRING);

  const canDrawPolygon = !restrictedGeometryTypes ||
    restrictedGeometryTypes.includes(GeometryType.POLYGON) ||
    restrictedGeometryTypes.includes(GeometryType.MULTI_POLYGON);

  return <MapControlButton controlName={controlName} icon="pencil">
    <div className="ol-draw-buttons">
      <Tooltip title={t(snapEnabled ? 'map.control.snapActive' : 'map.control.snapNotActive')} placement="right">
        <SnapSwitch checked={snapEnabled} onChange={(event) => setSnapEnabled(event.target.checked)} />
      </Tooltip>
      {renderActionButton(GeometryType.POINT)}
      {!!restrictedGeometryTypes && renderActionButton(GeometryType.MULTI_POINT, 'drawPoint')}
      {renderActionButton(GeometryType.LINE_STRING)}
      {!!restrictedGeometryTypes && renderActionButton(GeometryType.MULTI_LINE_STRING, 'drawLineString')}
      {renderActionButton(GeometryType.POLYGON)}
      {!!restrictedGeometryTypes && renderActionButton(GeometryType.MULTI_POLYGON, 'drawPolygon')}

      {!allowSingleFeature && !activeDrawVectorLayer && <ActionButton type="Text" icon="addText" activeType={type} handleClick={handleActionClick} />}
      <ActionButton type="Drag" icon="drag" activeType={type} handleClick={handleActionClick} />
      <ActionButton type="Modify" icon="modify" activeType={type} handleClick={handleActionClick} />
      {(canDrawLine || canDrawPolygon) && !activeDrawVectorLayer &&
        <ActionButton type="Divide" icon="divide" activeType={type} handleClick={handleActionClick} />
      }
      {!allowSingleFeature && canDrawPolygon && !activeDrawVectorLayer &&
        <ActionButton type="Union" icon="union" activeType={type} handleClick={handleActionClick} />
      }
      {!activeDrawVectorLayer && <ActionButton type="Delete" icon="delete" activeType={type} handleClick={handleActionClick}
        handleDblClick={() => deleteAllFeatures(drawLayer, dispatch)} />
      }

      {AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
        <Tooltip title={t('map.control.uploadCadFile')} placement="right">
          <button>
            <UploadCadFile/>
          </button>
        </Tooltip>
      }

      {!allowSingleFeature && 
        <Tooltip title={t('map.control.downloadShapeFile')} placement="right">
          <button onClick={handleDownloadShapefile}>
            <Icon icon="downloadMap" color="white"/>
          </button>
        </Tooltip>
      }

      {!allowSingleFeature && <ColorPickerButton handleChange={(value) => setColor(value)} color={color} />}

      {!!showAddWorkOrder &&
        <Tooltip title={t('workOrder.form.add')} placement="right">
          <button className="addWorkOrder" onClick={handleAddWorkOrder}>
            {t('map.control.addWorkOrder')}
          </button>
        </Tooltip>
      }
      {type === 'Text' && <div className="ol-draw-text-panel">
        <TextField id="draw-text-input" label="" size="small" variant="outlined" autoFocus />
      </div>}
    </div>
  </MapControlButton>;
};

export default MultiDrawControl;