import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import WorkOrderReport from '../components/report/WorkOrderReport';
import BusStopTypeReport from '../components/report/BusStopTypeReport';
import CableTypeReport from '../components/report/CableTypeReport';
import DitchNetworkReport from '../components/report/DitchNetworkReport';
import LampTypesReport from '../components/report/LampTypesReport';
import RainwaterNetworkReport from '../components/report/RainwaterNetworkReport';
import ReportList from '../components/report/ReportList';
import RoadAgeReport from '../components/report/RoadAgeReport';
import RoadBreakdownReport from '../components/report/RoadBreakdownReport';
import RoadSurfacesReport from '../components/report/RoadSurfacesReport';
import RoadTypesReport from '../components/report/RoadTypesReport';
import SettlementReport from '../components/report/SettlementReport';
import ShieldReport from '../components/report/ShieldReport';
import { Domain } from '../constants/classifierConstants';
import PageContainer from '../parts/PageContainer';
import ApplicationReport from '../components/report/ApplicationReport';
import RoadDetailsReport from '../components/report/RoadDetailsReport';
import TrafficReport from '../components/report/TrafficReport';
import RoadIlluminationReport from '../components/report/RoadIlluminationReport';
import AuthUtils from '../utils/AuthUtils';
import RouteUtils from '../utils/RouteUtils';
import BasicObjectReport from '../components/report/BasicObjectReport';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road];

export default function Report() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);
  const mobile = useSelector(state => state.global.mobileView);

  useEffect(() => {
    RouteUtils.setDocumentTitle('report');
  }, []);

  if (!!mobile) {
    return <Navigate to="/" />;
  }

  if (location.pathname === '/report' || location.pathname === '/report/') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Navigate to={`/report/${defaultDomain}`} />
  }

  return <PageContainer>
    <Routes>
      <Route path={Domain.rainwater} element={<ReportList domain={Domain.rainwater} />}/>
      <Route path={Domain.streetlight} element={<ReportList domain={Domain.streetlight} />}/>
      <Route path={Domain.road} element={<ReportList domain={Domain.road} />}/>
      <Route path={Domain.infrastructure} element={<ReportList domain={Domain.infrastructure} />}/>
      <Route path={Domain.landscaping} element={<ReportList domain={Domain.landscaping} />}/>
      <Route path="other" element={<ReportList domain="other" />}/>

      <Route path="/settlement/:domain" element={<SettlementReport/>} />
      <Route path="/surfaces" element={<RoadSurfacesReport/>} />
      <Route path="/roadType" element={<RoadTypesReport/>} />
      <Route path="/breakdown" element={<RoadBreakdownReport/>} />
      <Route path="/busStopType" element={<BusStopTypeReport/>} />
      <Route path="/roadAge" element={<RoadAgeReport/>} />
      <Route path="/roadDetails" element={<RoadDetailsReport/>} />
      <Route path="/traffic" element={<TrafficReport/>} />
      <Route path="/illumination" element={<RoadIlluminationReport/>} />
      <Route path="/network" element={<RainwaterNetworkReport/>} />
      <Route path="/ditchNetwork" element={<DitchNetworkReport/>} />
      <Route path="/lampType" element={<LampTypesReport/>} />
      <Route path="/cableType" element={<CableTypeReport/>} />
      <Route path="/shield" element={<ShieldReport/>} />
      <Route path="/workOrder" element={<WorkOrderReport/>} />
      <Route path="/application" element={<ApplicationReport/>} />
      <Route path="/AR/:basicObjectTypeId" element={<BasicObjectReport/>} />
      <Route path="/HH/:basicObjectTypeId" element={<BasicObjectReport/>} />
    </Routes>
  </PageContainer>;
}