import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, TableHeader } from 'styleguide';
import { fetchMapGroups } from '../../stores/admin/mapGroup';
import { fetchMapLayers, setDetails, updateFilter, updatePageable } from '../../stores/admin/mapLayer';
import { InfiniteScrollTableContainer } from '../table/PageableTable';

export default function MapLayersTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { filteredRows, filter, pageable, isLoading } = useSelector(state => state.mapLayer);
  const groups = useSelector(state => state.mapGroup.rows);

  const columns = useMemo(() => [
    {
      field: 'mapGroupId', headerName: t('admin.mapLayer.mapGroup'), filter: true, notSortable: true,
      type: 'customIdList', listValues: groups, listLabelKey: 'title',
      renderCell: (column, row) => {
        let title = '';
        if (row?.mapGroup?.parentGroup) {
          title += `${row.mapGroup.parentGroup.title} / `;
        }
        title += row?.mapGroup?.title;
        return title;
      }
    },
    { field: 'title', headerName: t('admin.mapLayer.title'), filter: true },
    { field: 'layerName', headerName: t('admin.mapLayer.layerName'), filter: true },
    { field: 'serviceType', headerName: t('admin.mapLayer.serviceType'), filter: true },
    { field: 'serverUrl', headerName: t('admin.mapLayer.serverUrl'), filter: true },
  ], [t, groups]);

  useEffect(() => !pageable.loaded && dispatch(fetchMapLayers(pageable, filter, columns)), [dispatch, pageable, filter, columns]);
  useEffect(() => !groups && dispatch(fetchMapGroups()), [dispatch, groups]);

  const handleAddNew = () => {
    dispatch(setDetails({}));
    navigate(`/admin/mapLayer`);
  };

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    navigate(`/admin/mapLayer/${selected.id}`);
  };

  const header = () => (
    <TableHeader buttons={<Button size="extra-small" onClick={handleAddNew}>{t('button.add')}</Button>}>
      {t('admin.mapLayer.label')}
    </TableHeader>
  );

  return <InfiniteScrollTableContainer
    rows={filteredRows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}