import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Heading, Paragraph, Colors, Icon, TabContainer } from 'styleguide';
import { add, save, fetchSelected, resetSubmitted, addFile, removeFile } from '../../stores/workOrder/workOrder';
import DialogForm from '../form/DialogForm';
import MapDialog from '../map/MapDialog';
import LoadingIndicator from '../form/LoadingIndicator';
import ClassifierSelect from '../form/ClassifierSelect';
import { ClassifierType, Domain, Priority } from '../../constants/classifierConstants';
import VTextField from '../form/VTextField';
import dateAdd from 'date-fns/add'
import VDateTimePicker from '../form/VDateTimePicker';
import GovUserSelect from '../form/GovUserSelect';
import ControlledAutocomplete from '../form/ControlledAutocomplete';
import { fetchPartnerUsers } from '../../stores/admin/user';
import { cloneDeep } from 'lodash';
import VCheckbox from '../form/VCheckbox';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import ConfirmButton from '../table/ConfirmButton';
import TableUtils from '../../utils/TableUtils';
import DateUtils from '../../utils/DateUtils';
import { toggleLayerDrawer } from '../../stores/map/map';
import { showWarning } from '../../stores/notification';
import { GeometryType } from '../../constants/mapConstants';
import MapUtils from '../../utils/MapUtils';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  formBlock: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 446,
      padding: 25,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: 5,
    },
    margin: 'auto',
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  formBlockContainer: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    paddingTop: 12,
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  }
}));

function NotificationForm({ index, register, control, errors, isLoading, remove, showRemove }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const error = errors?.notifications && errors.notifications[index];
  const domain = useWatch({ control, name: `notifications[${index}].domain` });

  return <Grid item container direction="column" className={classes.formBlockContainer}>
    <Grid item container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <ClassifierSelect name={`notifications[${index}].domain`} label={t('workOrder.notificationDomain')}
          control={control} fullWidth disabled={isLoading}
          error={!!error?.domain} helperText={error?.domain?.message}
          item={{}} classifierType={ClassifierType.domain} />
      </Grid>
      {showRemove &&
        <Grid item>
          <ConfirmButton message={t('form.confirmDelete')} icon="delete" size="small"
            onConfirm={() => remove(index)} aria-label="delete notification" />
        </Grid>
      }
    </Grid>
    <Box paddingTop={3}>
      <GovUserSelect name={`notifications[${index}].user`} fullWidth label={t('workOrder.notificationUser')}
        control={control} disabled={isLoading}
        error={!!error?.user} helperText={error?.user?.message}
        domain={domain} />
    </Box>
    <Box paddingTop={3}>
      <VTextField name={`notifications[${index}].content`} label={t('workOrder.notificationContent')}
        register={register} fullWidth maxLength={4000}
        error={!!error?.content} helperText={error?.content?.message}
        disabled={isLoading} multiline minRows={3} />
    </Box>
  </Grid>
}

function NotificationsBlock({ notifications, register, control, errors, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { classifiers } = useSelector(state => state.classifier);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notifications',
    keyName: 'fid',
  });

  useEffect(() => !fields.length && append({}), [fields, append]);

  const handleAddNew = (type) => {
    append({});
  };

  return <Grid container direction="column" spacing={4}>
    {notifications?.map((notification, index) =>
      <Grid item key={index} className={classes.formBlockContainer} container direction="column">
        <Paragraph fontSize="12B">
          {`${notification.fullName} 
            (${TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, notification.domain)})`}
        </Paragraph>
        <Paragraph fontSize="12">{notification.content}</Paragraph>
      </Grid>
    )}
    {fields.map((row, index) =>
      <NotificationForm key={row.fid} index={index} remove={remove} showRemove={fields.length > 1}
        control={control} register={register} errors={errors} isLoading={isLoading}
      />
    )}
    <Grid item container direction="row" className={classes.uploadRow} onClick={handleAddNew}>
      <Icon icon='add' color={Colors.sinineVaal} />
      <Box marginLeft={2}>
        <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('workOrder.form.addNotification')}</Paragraph>
      </Box>
    </Grid>
  </Grid>;
}

function FilesBlock({ files, control, errors, handleAddFile, handleRemoveFile, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { classifiers } = useSelector(state => state.classifier);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      handleAddFile(files[0]);
    }
  });

  return <Grid container direction="column" spacing={4}>
    {files.map((file, index) => <Grid item key={index} className={classes.formBlockContainer} container direction="row" justifyContent="space-between">
      <Paragraph fontSize='12'>
        {`${TableUtils.getClassifierTitle(classifiers, ClassifierType.documentType, file.documentType) || ''} ${file.fileName}`}
      </Paragraph>
      <ConfirmButton message={t('fileUpload.confirmDelete')} icon="delete" size="small"
        onConfirm={() => handleRemoveFile(file, index)} aria-label="delete work order file" />
    </Grid>)}

    <Grid item container direction="row" {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
      <ClassifierSelect item={{}} name="documentType" label="workOrder.documentType"
        control={control} errors={errors} fullWidth
        disabled={isLoading} classifierType={ClassifierType.documentType}
      />

      <Grid item container direction="row" className={classes.uploadRow} onClick={open}>
        <Icon icon='add' color={Colors.sinineVaal} />
        <Box marginLeft={2}>
          <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
        </Box>
        <input type="file" {...getInputProps()} />
      </Grid>
    </Grid>
  </Grid >;
}

function PartnerSelect({ control, disabled, errors, setValue }) {
  const name = 'fulfiller';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { partnerUsers } = useSelector(state => state.user);

  const shouldFetchPartners = partnerUsers.length === 0;
  useEffect(() => shouldFetchPartners && dispatch(fetchPartnerUsers()), [shouldFetchPartners, dispatch]);

  const filteredUsers = partnerUsers.filter(u => !u.isCompany);
  const defaultUser = filteredUsers.length === 1 ? filteredUsers[0] : null;

  const value = useWatch({ control, name: name });
  const isCurrentValueCorrectDomain = value && partnerUsers.some(p => value.isCompany === p.isCompany && value.id === p.id);
  const shouldSetValue = !shouldFetchPartners && defaultUser !== value && !isCurrentValueCorrectDomain;

  useEffect(() => shouldSetValue && setValue(name, defaultUser), [setValue, defaultUser, shouldSetValue]);

  return <div>
    <ControlledAutocomplete
      name={name}
      control={control}
      label={t('workOrder.fulfiller')}
      disabled={disabled}
      errors={errors}
      options={partnerUsers}
      getOptionLabel={(o) => o.title}
      getOptionSelected={(option, value) => {
        return option.isCompany === value.isCompany && option.id === value.id;
      }}
    />
  </div>;
}

export default function WorkOrderForm({ callback }) {
  const { t } = useTranslation();
  let { id } = useParams();

  const dispatch = useDispatch();
  const { selected, isLoading, submitted, files } = useSelector(state => state.workOrder);
  const authUser = useSelector(state => state.auth.authUser);
  const mobile = useSelector(state => state.global.mobileView);

  const classes = useStyles();
  const navigate = useNavigate();
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [readOnlyMap, setReadOnlyMap] = useState(false);
  const [filesNotUploaded, setFilesNotUploaded] = useState([]);
  const [geometry, setGeometry] = useState(selected?.geometry);

  const { register, control, errors, handleSubmit, reset, setValue, getValues, setError } = useForm({
    defaultValues: selected || {}
  });

  const domain = selected?.domain;
  const showFiles = useWatch({ control, name: 'sendFiles', defaultValue: false });
  const showNotifications = useWatch({ control, name: 'sendNotifications', defaultValue: false });
  const isGovValue = useWatch({ control, name: 'isGov', defaultValue: selected?.fulfiller?.isGov });
  const isGov = isGovValue === true || isGovValue === 'true';

  useEffect(() => id && (!selected || Number(id) !== selected.id) && dispatch(fetchSelected(id)), [dispatch, id, selected]);
  useEffect(() => reset(selected), [selected, reset]);
  useEffect(() => {
    if (submitted) {
      navigate(`/workOrder/${domain}`, { replace: true });
      dispatch(resetSubmitted());
    }
  }, [submitted, navigate, dispatch, domain]);

  if (!selected) {
    return <LoadingIndicator />;
  }

  const title = t('workOrder.form.title');

  const validateRequiredField = (data, field) => {
    if (!data[field]) {
      setError(field, { type: 'required', message: t('validation.required') })
      return false;
    }
    return true;
  }

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    if (data.fulfiller) {
      const fulfiller = data.fulfiller;
      if (!!fulfiller.isCompany) {
        data.cooperationPartnerCompany = fulfiller;
        data.userFulfiller = null;
      } else {
        data.userFulfiller = fulfiller;
        data.cooperationPartnerCompany = null;
      }
    }
    if (data.deadline) {
      data.deadline = DateUtils.formatISODateTime(data.deadline);
    }

    data.geometry = geometry || undefined;

    if (data.notifications?.length) {
      data.notificationList = data.notifications.filter(n => !!n.user && !!n.content).map(n => ({
        user: n.user,
        content: n.content
      }));
    }

    const sendForFulfillment = data.sendForFulfillment === 'true';
    if (sendForFulfillment) {
      let valid = true;
      if (!data.geometry) {
        dispatch(showWarning('validation.geometryRequired'));
        valid = false;
      }
      valid &= validateRequiredField(data, 'domain');
      valid &= validateRequiredField(data, 'description');
      valid &= validateRequiredField(data, 'deadline');
      if (isGov) {
        valid &= validateRequiredField(data, 'userFulfiller');
      } else {
        valid &= validateRequiredField(data, 'fulfiller');
      }
      if (!valid) {
        return;
      }
    }
    data.fulfiller = undefined;
    data.notifications = undefined;
    data.sendForFulfillment = undefined;

    if (data.id) {
      dispatch(save(data, sendForFulfillment));
    } else {
      data.id = undefined;
      dispatch(add(data, filesNotUploaded, sendForFulfillment, callback));
    }
  };

  const handleShowMap = () => {
    dispatch(toggleLayerDrawer(false));
    setReadOnlyMap(true);
    setShowMapDialog(true);
  };

  const handleEditGeometry = () => {
    dispatch(toggleLayerDrawer(false));
    setReadOnlyMap(false);
    setShowMapDialog(true);
  };

  const handleSetMyLocation = () => {
    const handleSuccess = (point) => {
      if (point && point.geometry) {
        setGeometry(point.geometry);
        dispatch(toggleLayerDrawer(false));
        setReadOnlyMap(false);
        setShowMapDialog(true);
      }
    };
    const handleError = (errorMsg) => {
      dispatch(showWarning(errorMsg));
    };
    MapUtils.getLocation(handleSuccess, handleError);
  };

  const handleConfrimMap = (newGeometry) => {
    setGeometry(newGeometry);
    setShowMapDialog(false);
  };

  const handleChangeFulfillerType = (isGov) => {
    setValue('userFulfiller', null);
    setValue('isGov', isGov);
    setValue('fulfiller', null);
  };

  const handlePriorityChange = (event) => {
    let deadline = '';
    switch (event.target.value) {
      case Priority.operative4Hours:
        deadline = dateAdd(new Date(), { hours: 4 });
        break;
      case Priority.urgent1Day:
        deadline = dateAdd(new Date(), { days: 1 });
        break;
      case Priority.important5Days:
        deadline = dateAdd(new Date(), { days: 5 });
        break;
      case Priority.ordinary15Days:
        deadline = dateAdd(new Date(), { days: 15 });
        break;
      case Priority.low30Days:
        deadline = dateAdd(new Date(), { days: 30 });
        break;
      default:
    }
    setValue('deadline', deadline);
  };

  const handleAddFile = (file) => {
    const fileData = { file: file, documentType: getValues('documentType') };
    dispatch(addFile(selected.id, fileData));
    if (!selected.id) {
      setFilesNotUploaded([...filesNotUploaded, fileData]);
    }
    setValue('documentType', '');
  };

  const handleRemoveFile = (file, index) => {
    dispatch(removeFile(selected.id, file.id, index));
  };

  const handleOpenRelated = () => {
    if (selected.relatedTable && selected.relatedId) {
      navigate(`/register/view/${selected.relatedTable}/${selected.relatedId}`);
    }
  };

  const handleSubmitButtonClick = (sendForFulfillment) => {
    setValue('sendForFulfillment', sendForFulfillment);
    handleSubmit(onSubmit)();
  };

  const defaultAuthor = selected.userAuthor || { id: authUser.id, fullName: `${authUser.fullName}` };

  return <>
    <DialogForm
      title={<span>
        <Heading level='3'>{title}</Heading>
        {!!selected.id && !!geometry && <MuiButton color="primary" size="small" onClick={handleShowMap}>
          <Icon icon="earth" color={Colors.sinineVaal} margin="6px" />
          <span>{t('button.showMap')}</span>
        </MuiButton>}
        {!!selected.id && !!selected.relatedTable && <MuiButton color="primary" size="small" onClick={handleOpenRelated}>
          <Icon icon="register" color={Colors.sinineVaal} margin="6px" />
          <span>{t('button.openRegister')}</span>
        </MuiButton>}
      </span>}
      leftActions={
        <Button disabled={isLoading} color="secondary" onClick={() => handleSubmitButtonClick(false)}>
          {t(mobile ? 'button.save' : 'button.saveAndContinue')}
        </Button>
      }
      mainAction={
        <Button disabled={isLoading} onClick={() => handleSubmitButtonClick(true)}>
          {t('button.done')}
        </Button>
      }
      onSubmit={handleSubmit(onSubmit)}
      disabled={isLoading}
    >
      <input type="hidden" name="id" ref={register} />
      <input type="hidden" name="isGov" ref={register} />
      <input type="hidden" name="relatedTable" ref={register} />
      <input type="hidden" name="relatedId" ref={register} />
      <input type="hidden" name="sendForFulfillment" ref={register} />
      <Paper elevation={0} className={classes.formBlock}>
        <Grid container direction="column" spacing={4} justifyContent="center" alignContent="center">
          <Grid item>
            <TabContainer width='100%' size={mobile ? 'small' : 'big'}>
              <div className={!isGov && 'active'}
                onClick={() => handleChangeFulfillerType(false)}>
                {t('workOrder.form.toPartner')}
              </div>
              <div className={isGov && 'active'}
                onClick={() => handleChangeFulfillerType(true)}>
                {t('workOrder.form.toGovUser')}
              </div>
            </TabContainer>
          </Grid>
          <Grid item>
            <ClassifierSelect name="priority" label={t('workOrder.priority')}
              control={control} required errors={errors} fullWidth disabled={isLoading}
              item={selected} classifierType={ClassifierType.priority}
              sortBy="itemCode" onChange={handlePriorityChange} />
          </Grid>
          {!selected.id && <Grid item>
            <GovUserSelect name="userAuthor" label={t('workOrder.author')} defaultValue={defaultAuthor}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>}
          <Grid item>
            <ClassifierSelect name="domain" label={t('workOrder.domain')}
              control={control} required errors={errors} fullWidth disabled={isLoading || !!selected.relatedTable}
              item={selected} classifierType={ClassifierType.domain} filter={(c) => Domain.plan !== c.itemCode} />
          </Grid>
          <Grid item>
            {isGov ?
              <GovUserSelect name="userFulfiller" label={t('workOrder.fulfiller')}
                control={control} errors={errors} disabled={isLoading} /> :
              <PartnerSelect control={control} errors={errors} disabled={isLoading} setValue={setValue} />
            }
          </Grid>
          <Grid item>
            <VDateTimePicker name="deadline" label={t('workOrder.deadline')}
              control={control} errors={errors} fullWidth disabled={isLoading}
              item={selected} />
          </Grid>
          {!selected.relatedTable && <Grid item>
            <VTextField name="source" label={t('workOrder.source')}
              register={register} errors={errors} fullWidth maxLength={500} disabled={isLoading} />
          </Grid>}
          <Grid item>
            <VTextField name="description" label={t('workOrder.description')}
              register={register} errors={errors} fullWidth maxLength={4000}
              disabled={isLoading} multiline minRows={3} />
          </Grid>
          {!selected.relatedTable && <Grid item>
            <MuiButton onClick={handleEditGeometry} disabled={isLoading}>
              <Icon icon="earth" color={Colors.sinineVaal} margin="6px" />
              {!!geometry ? t('form.editGeometry') : t('form.setGeometry')}
            </MuiButton>
          </Grid>}
          {!selected.relatedTable && !geometry && <Grid item>
            <MuiButton onClick={handleSetMyLocation} disabled={isLoading}>
              <Icon icon="position" color={Colors.sinineVaal} margin="6px" />
              {t('form.setGeometryFromLocation')}
            </MuiButton>
          </Grid>}
          <Grid item>
            <VCheckbox name="sendFiles" label={t('workOrder.form.sendFiles')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          {showFiles && <Grid item>
            <FilesBlock files={files} isLoading={isLoading}
              control={control} errors={errors} handleAddFile={handleAddFile} handleRemoveFile={handleRemoveFile} />
          </Grid>}
          <Grid item>
            <VCheckbox name="sendNotifications" label={t('workOrder.form.sendNotifications')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          {showNotifications && <Grid item>
            <NotificationsBlock notifications={selected.notificationList}
              control={control} register={register} errors={errors} isLoading={isLoading} />
          </Grid>}
        </Grid>
      </Paper>
    </DialogForm>
    {
      showMapDialog && <MapDialog
        geometry={geometry}
        geometryTypes={[GeometryType.MULTI_POINT]}
        readOnly={readOnlyMap}
        onConfirm={handleConfrimMap}
        onClose={() => setShowMapDialog(false)}
      />
    }
  </>;
}