import { Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Colors, Button, Heading, Icon, NavButton, Paragraph, TableHeader } from 'styleguide';
import { fetchGet, fetchRowData, toggleRow } from '../../stores/register/registerReport';
import ChartUtils from '../../utils/ChartUtils';
import DateUtils from '../../utils/DateUtils';
import PdfUtils from '../../utils/PdfUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import SimplePageableTable from '../table/SimplePageableTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  exportChart: {
    right: 24,
    top: 24,
    float: 'right',
    zIndex: 2
  },
  iconCell: {
    verticalAlign: 'middle',
    marginRight: 8
  },
  chartTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: 26
  }
}));

export default function TypeReport({ classifierType, groupedColumns, domain, reportName, handleRenderCollapsibleRow, chartField,
  hideHeader, typeColumnLabel, reportTitle, tableHeader, chartConfigs }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector(state => state.registerReport.rows[reportName]);
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const { classifiers } = useSelector(state => state.classifier);
  const compact = useSelector(state => state.global.mobileView);

  const title = reportTitle || t(`report.${domain}.${reportName}`);
  const subTitle = t('report.query');
  const header = tableHeader || <TableHeader>{t(`report.${domain}.${reportName}TableTitle`)}</TableHeader>;

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'type', headerName: typeColumnLabel, notSortable: true,
        renderCell: (column, cell) => <Grid container>
          {!!cell.type && <Icon icon={cell.open ? 'minus' : 'plus'} className={classes.iconCell} />}
          <Paragraph fontSize='14B'>
            {!!cell.type ? TableUtils.getClassifierTitle(classifiers, classifierType, cell.type) :
            cell.type === '' ? t('report.table.undefined') : t('report.table.total')}
          </Paragraph>
        </Grid>
      },
      ...groupedColumns
    ];
    return columns;
  }, [t, classifiers, classes, classifierType, groupedColumns, typeColumnLabel]);

  const shouldFetch = !rows;
  useEffect(() => shouldFetch && dispatch(fetchGet(domain, reportName)), [dispatch, domain, reportName, shouldFetch]);

  const handleClose = () => navigate(-1);

  if (!rows || isLoading) {
    return <CenteredLoadingIndicator />;
  }

  const handleChartExport = (chartId, chartTitle) => {
    PdfUtils.saveImage(chartId, `${subTitle} ${chartTitle || title} ${DateUtils.nowISODate()}`);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    if (percent < 0.05) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontWeight="bold" fontSize="22px" stroke="black" strokeWidth="1px"
        textAnchor={'middle'} dominantBaseline="central">
        {`${name} ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleRowSelection = (row) => {
    if (row.type) {
      if (!row.data) {
        dispatch(fetchRowData(domain, reportName, row, row.type));
      }
      dispatch(toggleRow(row, !row.open, reportName));
    }
  };

  if(!chartConfigs) {
    chartConfigs = [{
      id: `chart-${reportName}`,
      field: chartField
    }];
  }

  return <div>
    {!hideHeader &&
      <Toolbar className={classes.toolbar}>
        <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
        <span className={classes.header}>
          <Heading level={compact ? '4' : '3'}>{title}</Heading>
          <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
        </span>
      </Toolbar>
    }

    <Box paddingTop={4}>
      <SimplePageableTable
        id="report-table"
        rows={rows}
        columns={columns}
        header={header}
        pageSize={200}
        disableLoading
        loading={isLoading || !rows}
        loadingRowsCount={1}
        onRenderCollapsibleRow={handleRenderCollapsibleRow}
        onRowSelected={handleRowSelection}
      />
    </Box>

    {!compact && rows && chartConfigs.map(chart => (
      <Box key={chart.id} paddingTop={4}>
        <Paper>
          {!!chart.title && <Heading level="4" className={classes.chartTitle}>{chart.title}</Heading>}
          <Button size="extra-small" color="tertiary" onClick={() => handleChartExport(chart.id, chart.title)}
            disabled={isLoading || !rows?.length} className={classes.exportChart}>
            <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPng')}
          </Button>
          <ResponsiveContainer width="100%" height={600} id={chart.id}>
            <PieChart
              width={500}
              height={600}
              margin={{
                top: -50,
                right: 20,
                left: 0,
                bottom: 100,
              }}>
              <Pie
                data={rows.filter(r => !!r.type)}
                labelLine={false}
                label={renderCustomizedLabel}
                fill="#8884d8"
                dataKey={chart.field}
              >
                {rows.map((row, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={ChartUtils.getGraphColor(index)}
                    name={TableUtils.getClassifierTitle(classifiers, classifierType, row.type)} />
                ))}
              </Pie>
              <Tooltip />
              <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{ position: 'relative' }} />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Box>
    ))}
  </div>;
}