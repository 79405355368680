import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import { deleteNotification, fetchRows, setRead, updateFilter, updatePageable } from '../../stores/admin/userNotification';
import ConfirmButton from '../table/ConfirmButton';
import TableHeaderWithCount from '../table/TableHeaderWithCount';
import RouteUtils from '../../utils/RouteUtils';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  readRow: {
    opacity: 0.5
  }
}));

export default function UserNotificationTable({ domain }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const { rows, filter, pageable, totalElements, isLoading } = useSelector(state => state.userNotification);

  const handleRowSelection = (selected) => {
    dispatch(setRead(selected));
    RouteUtils.navigateToObject(navigate, selected.relatedTable, selected.relatedId);
  };

  const columns = useMemo(() => [
    { field: "sentTime", type: "dateTime", headerName: t('userNotification.sentTime') },
    { field: "location", headerName: t('userNotification.location'), filter: true },
    { field: 'domain', headerName: t('userNotification.domain'), type: "classifier", classifierType: ClassifierType.domain, filter: true, },
    {
      field: 'notificationType', headerName: t('userNotification.notificationType'),
      renderCell: (column, cell) => t(`userNotification.type.${cell.notificationType}`)
    },
    {
      field: "title", headerName: t('userNotification.titleField'), filter: true, renderCell: (column, cell) => {
        if (cell.content) {
          return <Tooltip title={cell.content} label={cell.title} onOpen={() => dispatch(setRead(cell))} />;
        }
        return cell.title;
      }
    },
    {
      field: 'action', width: 50, renderCell: (column, cell) =>
        <ConfirmButton message={t('form.confirmDelete')} icon="delete" size="small"
          onConfirm={() => dispatch(deleteNotification(cell.id))} aria-label="delete notification" />
    }
  ], [t, dispatch]);

  useEffect(() => !pageable.loaded && dispatch(fetchRows(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  return <InfiniteScrollTableContainer
    rows={rows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={<TableHeaderWithCount count={totalElements}>
      {t('userNotification.notificationsTab')}
    </TableHeaderWithCount>}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
    rowClassNameFunction={(row) => !!row?.read && classes.readRow}
  />;
}