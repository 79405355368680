import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { TableHeader } from 'styleguide';
import { fetchUsers, resetUser, updateFilter, updatePageable } from '../../stores/admin/user';
import { useNavigate } from "react-router-dom";
import LinkButton from '../LinkButton';
import ValidIcon from '../table/ValidIcon';
import { InfiniteScrollTableContainer } from '../table/PageableTable';

const formatValidRoles = (user) => {
  return (user && user.roles) ? user.roles.filter(r => r.role.valid).flatMap(r => r.role.title).sort().join(", ") : "";
};

export default function UserTable() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, filter, pageable, isLoading } = useSelector(state => state.user);

  const columns = useMemo(() => [
    {
      field: 'valid', headerName: t('table.status'), width: 150, notSortable: true,
      renderCell: (column, cell) => <ValidIcon valid={cell.valid} />,
    },
    { field: "personCode", type: "relation", relation: "person", relationField: "code", headerName: t('admin.user.code'), filter: true },
    { field: "personFirstName", type: "relation", relation: "person", relationField: "firstName", headerName: t('admin.user.firstName'), filter: true },
    { field: "personLastName", type: "relation", relation: "person", relationField: "lastName", headerName: t('admin.user.lastName'), filter: true },
    {
      field: 'roles', headerName: t('admin.user.roles'), notSortable: true,
      renderCell: (column, cell) => formatValidRoles(cell)
    }
  ], [t]);

  useEffect(() => !pageable.loaded && dispatch(fetchUsers(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  const handleRowSelection = (selected) => {
    dispatch(resetUser());
    navigate(`/admin/user/${selected.id}`);
  };

  const header = () => (
    <TableHeader buttons={<LinkButton size="extra-small" to="/admin/user">{t('button.add')}</LinkButton>}>
      {t('admin.usersTitle')}
    </TableHeader>
  );

  return <InfiniteScrollTableContainer
    rows={users}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}