import { Box, makeStyles, Toolbar } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, Routes, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { Heading, NavButton, Paragraph } from 'styleguide';
import { Authority } from '../../constants/authConstants';
import { PlanningStatus } from '../../constants/classifierConstants';
import { fetchSelected } from '../../stores/planning/planning';
import AuthUtils from '../../utils/AuthUtils';
import PlanningUtils from '../../utils/PlanningUtils';
import { PlanningAddDocumentForm, PlanningAddDocumentVersionForm, PlanningDecisionDocumentForm, PlanningEditDocumentForm, PlanningEditDocumentLimitedForm } from '../application/plan/PlanDocumentForm';
import { PlanningSendNotificationForm } from '../application/plan/SendNotificationForm';
import SecureWrapper from '../auth/SecureWrapper';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import ScrollToTopOnMount from '../form/ScrollToTopOnMount';
import NavigationTabs from '../table/NavigationTabs';
import OperationForm from './OperationForm';
import ParticipantForm from './ParticipantForm';
import PlanningActions from './PlanningActions';
import { PlanningCoordinateForm, PlanningDontCoordinateForm } from './PlanningCoordinationForm';
import PlanningDetails from './PlanningDetails';
import PlanningDocuments from './PlanningDocuments';
import PlanningEditForm from './PlanningEditForm';
import PlanningLocations from './PlanningLocations';
import PlanningOperations from './PlanningOperations';
import PlanningOpinionForm from './PlanningOpinionForm';
import PlanningParticipants from './PlanningParticipants';
import PlanningStatusLabel from './PlanningStatusLabel';
import ProposalForm from './ProposalForm';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
}));

export default function PlanningView({ mapView }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const compact = useSelector(state => state.global.mobileView) || mapView;
  const url = useResolvedPath("").pathname;

  const planning = useSelector(state => state.planning.selected);
  const authUser = useSelector(state => state.auth.authUser);

  const planningId = planning?.id;

  //fetch selected
  const shouldFetch = !planningId || planningId !== Number(id);
  useEffect(() => shouldFetch && dispatch(fetchSelected(id)),
    [shouldFetch, id, dispatch]);

  const handleClose = () => {
    navigate(-1);
  };

  if (!id || !planning) {
    return <CenteredLoadingIndicator />;
  }

  const title = t('planning.view.header', { number: planning.number });
  const tabs = [
    <NavLink key="main" to={`${url}`} end replace>{t('planning.view.details')}</NavLink>,
    <NavLink key="location" to={`${url}/location`} replace>{t('planning.view.locations')}</NavLink>,
    <NavLink key="participant" to={`${url}/participant`} replace>{t('planning.view.participants')}</NavLink>,
    <NavLink key="document" to={`${url}/document`} replace>{t('planning.view.documents')}</NavLink>,
    <NavLink key="operation" to={`${url}/operation`} replace>{t('planning.view.operations')}</NavLink>,
  ];

  if (AuthUtils.hasAuthority(authUser, Authority.UC11_planning)) {
    tabs.push(<NavLink key="action" to={`${url}/action`} replace>{t('planning.view.actions')}</NavLink>);
  }

  const isGovUser = AuthUtils.isGov(authUser);
  const readOnly = !isGovUser || !PlanningUtils.isHandler(authUser, planning);
  const planReadOnly = PlanningStatus.initiated !== planning.status || readOnly;

  const showRestricted = isGovUser || planning.isParticipant;

  return <div>
    <ScrollToTopOnMount />
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level='3'>{title}</Heading>
        <Paragraph fontSize='14B'>{planning.location}</Paragraph>
      </span>

      <span className={classes.flex}></span>

      <Box textAlign="right">
        <PlanningStatusLabel status={planning.status} />
      </Box>
    </Toolbar>

    {(tabs.length > 1) &&
      <Box py={compact ? 2 : 5}>
        <NavigationTabs>
          {tabs}
        </NavigationTabs>
      </Box>
    }

    <Routes>
      <Route path="" element={<PlanningDetails readOnly={readOnly} title={title} />} />
      <Route path="/action" element={<PlanningActions />} />
      <Route path="/document" element={<PlanningDocuments readOnly={readOnly} showRestricted={showRestricted} />} />
      <Route path="/location" element={<PlanningLocations readOnly={planReadOnly} />} />
      <Route path="/participant" element={<PlanningParticipants readOnly={readOnly} showRestricted={showRestricted} />} />
      <Route path="/operation" element={<PlanningOperations readOnly={readOnly} showRestricted={showRestricted} />} />

      <Route path="/addProposal" element={
        <SecureWrapper authority={Authority.UC1_application_submit}>
          <ProposalForm/>
        </SecureWrapper>
      }/>
      <Route path="/addOpinion/:operationId" element={
        <SecureWrapper authority={Authority.UC1_application_submit}>
          <PlanningOpinionForm/>
        </SecureWrapper>
      }/>
      <Route path="/coordinate/:operationId" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <PlanningCoordinateForm/>
        </SecureWrapper>
      }/>
      <Route path="/dontCoordinate/:operationId" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <PlanningDontCoordinateForm/>
        </SecureWrapper>
      }/>
      <Route path="/addDocument" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningAddDocumentForm/>
        </SecureWrapper>
      }/>
      <Route path="/addOperationDocument/:operationId" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningAddDocumentForm/>
        </SecureWrapper>
      }/>
      <Route path="/decision/:decision" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningDecisionDocumentForm/>
        </SecureWrapper>
      }/>
       <Route path="/editDocument/:documentId" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningEditDocumentForm/>
        </SecureWrapper>
      }/>
      <Route path="/editDocumentLimited/:documentId" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningEditDocumentLimitedForm/>
        </SecureWrapper>
      }/>
      <Route path="/addDocumentVersion/:documentId" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningAddDocumentVersionForm/>
        </SecureWrapper>
      }/>
      <Route path="/editParticipant/:participantId" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <ParticipantForm/>
        </SecureWrapper>
      }/>
      <Route path="/edit" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningEditForm/>
        </SecureWrapper>
      }/>
      <Route path="/editOperation/:operationId?" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <OperationForm/>
        </SecureWrapper>
      }/>
      <Route path="/askOpinion" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningSendNotificationForm/>
        </SecureWrapper>
      }/>
      <Route path="/sendCoordination" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningSendNotificationForm/>
        </SecureWrapper>
      }/>
      <Route path="/sendNotification/:operationId?" element={
        <SecureWrapper authority={Authority.UC11_planning}>
          <PlanningSendNotificationForm/>
        </SecureWrapper>
      }/>
    </Routes>
  </div >;
}