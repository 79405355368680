import { Box, makeStyles, Toolbar } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Colors, Heading, Icon, Paragraph } from "styleguide";
import NavButton from "styleguide/build/components/Buttons/NavButton/NavButton";
import { toggleLoadingOverlay } from "../../stores/notification";
import MapUtils from "../../utils/MapUtils";
import PdfUtils from "../../utils/PdfUtils";
import Layers from "../map/layers/Layers";
import Map from '../map/Map';
import SettlementSelect from "../table/SettlementSelect";
import { fetchPost } from '../../stores/register/registerReport';
import { Domain } from "../../constants/classifierConstants";
import LayerStyles from "../map/LayerStyles";
import VectorLayer from "../map/layers/VectorLayer";
import DateUtils from "../../utils/DateUtils";

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  mapContainer: {
    height: 700,
    borderRadius: 4,
    overflow: 'hidden',
    margin: 12
  },
}));

export default function RoadIlluminationReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector(state => state.registerReport.rows['illumination'])
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const { layers } = useSelector(state => state.map);
  const compact = useSelector(state => state.global.mobileView);

  const [settlements, setSettlements] = useState([]);

  const title = t(`report.TE.illumination`);
  const subTitle = t('report.report');

  const handleClose = () => navigate(-1);

  const handlePdfExport = async () => {
    dispatch(toggleLoadingOverlay(true));
    document.body.style.cursor = 'progress';

    const pdf = PdfUtils.newPdfLanscape();

    PdfUtils.addTitle(pdf, title);

    const handlePdfDone = () => {
      PdfUtils.savePdf(pdf, `${subTitle} ${title} ${DateUtils.nowISODate()}`);

      document.body.style.cursor = 'auto';
      dispatch(toggleLoadingOverlay(false));
    };

    for (var index = 0; index < rows.length; index++) {
      await PdfUtils.addImageLandscape(pdf, `map-container-${index}`, rows[index].title, !index);
    }

    handlePdfDone();
  };

  const handleGenerate = () => {
    dispatch(fetchPost(Domain.road, 'illumination', settlements));
  };

  const getFeature = (geometry) => {
    const jsonGeometry = JSON.parse(geometry);
    return MapUtils.geometryToFeature(jsonGeometry);
  };

  const getExtent = (geometry) => {
    const jsonGeometry = JSON.parse(geometry);
    return MapUtils.getFeaturesExtent([MapUtils.geometryToOLFeature(jsonGeometry)]);
  };

  const getCqlFilter = (settlement) => `location='${settlement.title}'`;

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
      <span className={classes.flex}></span>
      <Button size="extra-small" color="tertiary" onClick={handlePdfExport} disabled={!rows?.length || isLoading}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPdf')}
      </Button>
    </Toolbar>

    <Box padding={3} display="flex" alignItems="center">
      <SettlementSelect values={settlements} handleChange={setSettlements} disabled={isLoading} autoFocus />
      <Box paddingLeft={3}>
        <Button size="small" onClick={handleGenerate} disabled={isLoading}>{t('report.generate')}</Button>
      </Box>
    </Box>

    {!!rows?.length && rows.map((settlement, index) => <Box key={index}>
      <Heading level="3">{settlement.title}</Heading>
      <div id={`map-container-${index}`} className={classes.mapContainer}>
        <Map extent={getExtent(settlement.geometry)} disablePan>
          <Layers>
            {MapUtils.getDefaultBaseLayer(layers)}
            <VectorLayer features={[getFeature(settlement.geometry)]} style={LayerStyles.activeNoFillFeature} zIndex={1} />
            {MapUtils.getLayerByLayerName(layers, 'road_type_jalgrattatee', getCqlFilter(settlement))}
            {MapUtils.getLayerByLayerName(layers, 'road_type_jalgtee', getCqlFilter(settlement))}
            {MapUtils.getLayerByLayerName(layers, 'road_type_kergliiklus', getCqlFilter(settlement))}
            {MapUtils.getLayerByLayerName(layers, 'road_type_soidutee', getCqlFilter(settlement))}
            {MapUtils.getLayerByLayerName(layers, 'streetlight_lamp_HID', getCqlFilter(settlement), 3)}
            {MapUtils.getLayerByLayerName(layers, 'streetlight_lamp_HPS', getCqlFilter(settlement), 4)}
            {MapUtils.getLayerByLayerName(layers, 'streetlight_lamp_HQL', getCqlFilter(settlement), 5)}
            {MapUtils.getLayerByLayerName(layers, 'streetlight_lamp_LED', getCqlFilter(settlement), 6)}
            {MapUtils.getLayerByLayerName(layers, 'streetlight_lamp_MUU', getCqlFilter(settlement), 7)}
          </Layers>
        </Map>
      </div>
    </Box>)
    }
  </div >;
}