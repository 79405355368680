import { Box, FormControlLabel, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useNavigate, useLocation, Routes } from 'react-router-dom';
import { Button, Checkbox, Heading, Icon, Shadows, VAutocomplete } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import { addNotificationRequest, resetSubmitted } from '../../stores/admin/userNotificationRequest';
import { DialogContainer } from '../form/DialogForm';
import AddressSearch from '../map/AddressSearch';
import Controls from '../map/controls/Controls';
import SelectFeatureControl from '../map/controls/SelectFeatureControl';
import Layers from '../map/layers/Layers';
import SettlementSelect from '../table/SettlementSelect';
import Map from '../map/Map';
import LoadingIndicator from '../form/LoadingIndicator';
import MapUtils from '../../utils/MapUtils';
import { resetCadastre } from '../../stores/map/feature';
import { PageableTableContainer } from '../table/PageableTable';
import TableUtils from '../../utils/TableUtils';
import { showWarning, toggleLoadingOverlay } from '../../stores/notification';

const useStyles = makeStyles((theme) => ({
  formBlock: {
    [theme.breakpoints.up('md')]: {
      width: 846,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 25,
    paddingBottom: 88
  },
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 88
  },
  tableContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 177,
      marginRight: 177
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginRight: 4
    },
    maxHeight: 300,
    overflow: 'auto',
    position: 'absolute',
    bottom: 100,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: Shadows.shadow2
  },
}));

function DomainSelect({ handleChange, ...rest }) {
  const { t } = useTranslation();
  const { classifiers } = useSelector(state => state.classifier);

  const filteredClassifiers = TableUtils.getClassifiersByType(classifiers, ClassifierType.domain)
    .filter((c) => (c.itemCode !== Domain.infrastructure && c.itemCode !== Domain.landscaping));

  return <VAutocomplete
    multiple fullWidth disableCloseOnSelect
    label={t('userNotification.domain')}
    onChange={(event, value) => handleChange(value)}
    options={filteredClassifiers}
    renderOption={(option, { selected }) => (
      <FormControlLabel
        control={<Checkbox checked={selected} />}
        label={option.title}
      />
    )}
    getOptionLabel={(option) => option.title}
    {...rest}
  />;
}

function RequestForm({ isLoading, domains, setDomains, settlements, setSettlements }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <div className={classes.formBlock}>
    <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
      <Grid container item lg={7} direction="column" spacing={4}>
        <Grid item sm={12}>
          <DomainSelect value={domains} handleChange={setDomains} disabled={isLoading} />
        </Grid>
        <Grid item sm={12}>
          <SettlementSelect values={settlements} handleChange={setSettlements} disabled={isLoading} />
        </Grid>
        <Grid item sm={12}>
          <Link to="/notification/config/request/map">{t('userNotification.request.openMap')}</Link>
        </Grid>
      </Grid>
    </Grid>
  </div>;
}

function MapForm({ isLoading, domains, setDomains, cadastres, setCadastres }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const { cadastre } = useSelector(state => state.feature);

  //add cadastre
  useEffect(() => {
    if (cadastre) {
      dispatch(resetCadastre());
      setCadastres([...cadastres, cadastre]);
      dispatch(toggleLoadingOverlay(false));
    }
  }, [dispatch, cadastre, cadastres, setCadastres]);

  if (!layers) {
    return <LoadingIndicator />;
  }

  const handleDelete = (value) => {
    setCadastres(cadastres.filter(c => c.cadastre !== value))
  };

  let columns = [
    { field: 'address', headerName: t('application.applicationLocation.address') },
    { field: 'cadastre', headerName: t('application.applicationLocation.cadastre') },
    {
      field: 'action', width: 50, renderCell: (column, cell) =>
        <IconButton onClick={() => handleDelete(cell.cadastre)} disabled={isLoading}>
          <Icon icon="close" />
        </IconButton>
    }
  ];

  const handleAddressSelect = (address) => {
    if (address.cadastre) {
      setCadastres([...cadastres, { address: address.shortAddress, cadastre: address.cadastre }]);
    }
  };

  return <div className={classes.container}>
    <Map center={center} zoom={zoom} extent={extent}>
      <Layers>
        {MapUtils.getDefaultBaseLayer(layers)}
        {MapUtils.getCadastreLayer(layers)}
      </Layers>
      <Controls>
        <SelectFeatureControl onClick={MapUtils.handleCadastreSelect} />
      </Controls>
      <AddressSearch onSelect={handleAddressSelect} />
    </Map>

    <Paper className={classes.tableContainer}>
      <Heading level="4" margin="26px">{t('userNotification.request.addMap')}</Heading>

      <Box px={2} paddingBottom={2}>
        <DomainSelect value={domains} handleChange={setDomains} disabled={isLoading} />
      </Box>

      <PageableTableContainer
        rows={cadastres}
        columns={columns}
        totalRows={cadastres.length}
        size={'small'}
      />
    </Paper>
  </div>
}

export default function UserNotificationRequestForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoading = useSelector(state => state.userNotificationRequest.isLoading);
  const submitted = useSelector(state => state.userNotificationRequest.submitted);

  const [domains, setDomains] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [cadastres, setCadastres] = useState([]);

  const isSettlementForm = location.pathname === '/notification/config/request';

  useEffect(() => {
    if (submitted) {
      navigate('/notification/config', { replace: true });
      dispatch(resetSubmitted());
    }
  }, [submitted, dispatch, navigate]);

  const onSubmit = () => {
    let data = {};
    if (domains.length) {
      data.domain = domains.map(d => d.itemCode);
    }
    if (isSettlementForm && settlements.length) {
      data.location = settlements;
    } else if (!isSettlementForm && cadastres.length) {
      data.location = cadastres.map(c => c.cadastre);
    }

    if (data.domain?.length || data.location?.length) {
      dispatch(addNotificationRequest(data));
    } else {
      dispatch(showWarning('userNotification.request.invalid'));
    }
  };

  const handleClose = () => navigate('/notification/config', { replace: true });

  const title = isSettlementForm ? t('userNotification.request.add') : '';

  return <DialogContainer
    title={title}
    mainAction={<Button disabled={isLoading} onClick={onSubmit}>{t('button.save')}</Button>}
    onClose={handleClose}
    disabled={isLoading}>
    <Routes>
      <Route path="" element={
        <RequestForm isLoading={isLoading}
          domains={domains} setDomains={setDomains}
          settlements={settlements} setSettlements={setSettlements}
        />
      } />
      <Route path="/map" element={
        <MapForm isLoading={isLoading}
          domains={domains} setDomains={setDomains}
          cadastres={cadastres} setCadastres={setCadastres}
        />
      } />
    </Routes>
  </DialogContainer>;

}