import { Box, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'styleguide';
import { api } from '../../api/application';
import { Authority } from '../../constants/authConstants';
import { PlanningDocumentType, PlanningStatus } from '../../constants/classifierConstants';
import { showWarning } from '../../stores/notification';
import { changeHandler, getLogsPath, planAddPlankBooking, planSubmitToPlank, resetSubmitted } from '../../stores/planning/planning';
import AuthUtils from '../../utils/AuthUtils';
import PlanningUtils from '../../utils/PlanningUtils';
import { ConfirmWithHandlerSelect } from '../application/ApplicationActions';
import ActivityLogTable from '../table/ActivityLogTable';
import ConfirmButton, { ConfirmButtonWithReason } from '../table/ConfirmButton';

export default function PlanningActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const planning = useSelector(state => state.planning.selected);
  const documents = useSelector(state => state.planningDocument.rows);
  const submitted = useSelector(state => state.planning.submitted);
  const isLoading = useSelector(state => state.planning.isLoading);
  const authUser = useSelector(state => state.auth.authUser);

  const disabled = isLoading;
  const status = planning.status;
  const planningId = planning.id;

  useEffect(() => {
    if (submitted) {
      dispatch(resetSubmitted());
      navigate(`/plan/${status}`);
    }
  }, [dispatch, navigate, submitted, status]);

  const handleDecision = (decision, reason) => {
    navigate(`../decision/${decision}`);
  };

  const handleAddPlankBooking = () => {
    dispatch(planAddPlankBooking(planning.id));
  };

  const handleSubmitPlank = (reason) => {
    dispatch(planSubmitToPlank(planningId, reason));
  };

  let mainActions = [];
  let secondaryActions = [];

  const isHandler = PlanningUtils.isHandler(authUser, planning);

  const checkAddPlankBookingAction = () => {
    if(AuthUtils.hasAuthority(authUser, Authority.UC38_submit_plan_to_plank) && planning.name && !planning.plankSysId){
      secondaryActions.push(
        <ConfirmButton message={t('planning.action.confirmAddPlankBooking')} btnText={t('planning.action.addPlankBooking')}
              onConfirm={handleAddPlankBooking} aria-label="add plank booking" disabled={disabled} color="secondary"/>
      );
    }
  };

  const validatePlankSubmit = () => {
    const requiredDocs = [PlanningDocumentType.establish, PlanningDocumentType.invalidate, PlanningDocumentType.courtDecision];
    const legalBasisDocs = documents.filter(d => requiredDocs.includes(d.documentType) && (d.url || d.fileName));
    if(!legalBasisDocs.length){
      dispatch(showWarning('planning.warning.legalBasisMissing'));
      return false;
    }
    if(!legalBasisDocs.some(d => d.isPlan)){
      dispatch(showWarning('planning.warning.legalBasisNotIsPlan'));
      return false;
    }
    const signedDocs = documents.filter(d => PlanningDocumentType.signedPlan === d.documentType && d.fileName);
    if(!signedDocs.length){
      dispatch(showWarning('planning.warning.signedPlanMissing'));
      return false;
    }
    if(!signedDocs.some(d => d.isPlan)){
      dispatch(showWarning('planning.warning.signedPlanNotIsPlan'));
      return false;
    }
    return true;
  };

  const checkAddPlankSubmitAction = () => {
    if(AuthUtils.hasAuthority(authUser, Authority.UC38_submit_plan_to_plank) && 
      (!planning.plankImportId || ['KINNITATUD', 'VIGA', 'PUUDUB'].includes(planning.plankImportStatus))) {
      mainActions.push(
        <ConfirmButtonWithReason message={t('planning.action.confirmSubmitPlank')} btnText={t('planning.action.submitPlank')}
              onConfirm={handleSubmitPlank} aria-label="submit plan to plank" disabled={disabled} 
              onBeforeOpen={validatePlankSubmit} reasonNotRequired/>
      );
    }
  };

  if (isHandler) {
    switch (planning.status) {
      case PlanningStatus.initiated:
        mainActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.accepted)} disabled={disabled}>
            {t('planning.action.accept')}
          </Button>
        );

        secondaryActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.completed)} disabled={disabled} color="secondary">
            {t('planning.action.complete')}
          </Button>
        );
        checkAddPlankBookingAction();
        break;
      case PlanningStatus.accepted:
        mainActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.established)} disabled={disabled}>
            {t('planning.action.establish')}
          </Button>
        );

        secondaryActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.completed)} disabled={disabled} color="secondary">
            {t('planning.action.complete')}
          </Button>
        );
        checkAddPlankBookingAction();
        break;
      case PlanningStatus.established:
        checkAddPlankSubmitAction();

        secondaryActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.invalid)} disabled={disabled} color="secondary">
            {t('planning.action.invalidate')}
          </Button>
        );
        secondaryActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.partiallyValid)} disabled={disabled} color="secondary">
            {t('planning.action.partiallyInvalidate')}
          </Button>
        );
        break;
      case PlanningStatus.partiallyValid:
        checkAddPlankSubmitAction();

        secondaryActions.push(
          <Button onClick={() => handleDecision(PlanningStatus.invalid)} disabled={disabled} color="secondary">
            {t('planning.action.invalidate')}
          </Button>
        );
        break;
      case PlanningStatus.invalid:
        checkAddPlankSubmitAction();
        break;
      default:
    }
  }

  const handleChangeHandler = (reason, handler) => {
    if (handler.id !== planning.handlerId) {
      dispatch(changeHandler(planningId, reason, handler.id));
    }
  };

  if (AuthUtils.hasAuthority(authUser, Authority.UC11_planning)) {
    secondaryActions.push(<ConfirmWithHandlerSelect
      message={t('planning.action.confirmChangeHandler')} btnText={t('planning.action.changeHandler')}
      color="secondary" onConfirm={handleChangeHandler}
      aria-label="send application for processing" disabled={disabled} />
    );
  }

  return <div>
    <Box paddingTop={2} paddingBottom={4}>
      <ActivityLogTable
        api={api}
        fetchLogsUrl={getLogsPath(planningId)}
        singleRowOpenable
      />
    </Box>

    <Grid container direction="row" spacing={4}>
      {mainActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
      <Box flexGrow={1}></Box>
      {secondaryActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
    </Grid>
  </div>;
}