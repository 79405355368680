import { Grid, Toolbar, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Heading } from "styleguide";
import { Authority } from "../../constants/authConstants";
import { addDrawingGeometryToQueue } from "../../stores/map/feature";
import AuthUtils from "../../utils/AuthUtils";
import ToolbarButton from "../table/ToolbarButton";
import StopRoundedIcon from '@material-ui/icons/StopRounded';

const useStyles = makeStyles((theme) => ({
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
}));

export default function PlanTempObjectView() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const title = location.state?.data?.mapLayerTitle;
  const data = location.state?.data;

  const { authUser } = useSelector(state => state.auth);

  if (!data) {
    navigate(-1);
  }

  const handleAddToDrawingLayer = (event) => {
    event.stopPropagation();
    dispatch(addDrawingGeometryToQueue(data.geometry));
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Heading level='4'>{title}</Heading>
      </span>
      <span className={classes.flex}></span>
      {data.geometry && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
        <ToolbarButton compact={true} onClick={handleAddToDrawingLayer} icon="pencil" label={t('button.addToDrawingLayer')} />
      }
    </Toolbar>

    <Grid container spacing={0}>
      <Grid item sm={12}>
        <div className={classes.detailRowDense}>
          <span className={classes.label}>{t('planning.temp.name')}</span>
          {data.name}
        </div>
      </Grid>
      <Grid item sm={12}>
        <div className={classes.detailRowDense}>
          <span className={classes.label}>{t('planning.temp.description')}</span>
          {data.description}
        </div>
      </Grid>
      <Grid item sm={12}>
        <div className={classes.detailRowDense}>
          <span className={classes.label}>{t('planning.temp.color')}</span>
          <StopRoundedIcon style={{ height: 24, width: 24, color: data.color, verticalAlign: 'bottom' }} />
        </div>
      </Grid>
    </Grid>
  </div>;
}