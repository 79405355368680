import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ApplicationRedirect from '../components/application/ApplicationRedirect';
import ApplicationTable from '../components/application/ApplicationTable';
import ApplicationView from '../components/application/ApplicationView';
import PartnerApplicationTable from '../components/application/PartnerApplicationTable';
import AddApplicationTab from '../components/application/citizen/AddApplicationTab';
import MyApplicationsTable from '../components/application/citizen/MyApplicationsTable';
import ClauseForm from '../components/application/clause/ClauseForm';
import ApplicationForm from '../components/application/form/ApplicationForm';
import SecureWrapper from '../components/auth/SecureWrapper';
import { Authority } from '../constants/authConstants';
import { Domain } from '../constants/classifierConstants';
import PageContainer from '../parts/PageContainer';
import AuthUtils from '../utils/AuthUtils';
import RouteUtils from '../utils/RouteUtils';

const allowedDomains = [Domain.plan, Domain.rainwater, Domain.streetlight, Domain.road];

export default function Application() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('application');
  }, []);

  if (location.pathname === '/application' || location.pathname === '/application/') {
    let defaultDomain = authUser?.domain || Domain.rainwater;
    if (AuthUtils.hasAuthority(authUser, Authority.UC6_my_applications)) {
      defaultDomain = 'my';
    } else if (AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application)) {
      defaultDomain = Domain.streetlight;
    } else {
      defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    }
    return <Navigate to={`/application/${defaultDomain}`} />;
  }

  const isPartner = AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application);

  return <PageContainer>
    <Routes>
      {isPartner && <>
        <Route path={`/${Domain.plan}`} element={
          <SecureWrapper authority={Authority.UC19_partner_application}>
            <PartnerApplicationTable domain={Domain.plan}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.rainwater}`} element={
          <SecureWrapper authority={Authority.UC19_partner_application}>
            <PartnerApplicationTable domain={Domain.rainwater}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.streetlight}`} element={
          <SecureWrapper authority={Authority.UC19_partner_application}>
            <PartnerApplicationTable domain={Domain.streetlight}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.road}`} element={
          <SecureWrapper authority={Authority.UC19_partner_application}>
            <PartnerApplicationTable domain={Domain.road}/>
          </SecureWrapper>
        }/>
        <Route path="archive" element={
          <SecureWrapper authority={Authority.UC19_partner_application}>
            <PartnerApplicationTable domain="archive"/>
          </SecureWrapper>
        }/>
      </>}
      {!isPartner && <>
        <Route path={`/${Domain.plan}`} element={
          <SecureWrapper authority={[Authority.UC7_application, Authority.UC7_view_application]}>
            <ApplicationTable domain={Domain.plan}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.rainwater}`} element={
          <SecureWrapper authority={[Authority.UC7_application, Authority.UC7_view_application]}>
            <ApplicationTable domain={Domain.rainwater}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.streetlight}`} element={
          <SecureWrapper authority={[Authority.UC7_application, Authority.UC7_view_application]}>
            <ApplicationTable domain={Domain.streetlight}/>
          </SecureWrapper>
        }/>
        <Route path={`/${Domain.road}`} element={
          <SecureWrapper authority={[Authority.UC7_application, Authority.UC7_view_application]}>
            <ApplicationTable domain={Domain.road}/>
          </SecureWrapper>
        }/>
        <Route path="archive" element={
          <SecureWrapper authority={[Authority.UC7_application, Authority.UC7_view_application]}>
            <ApplicationTable domain="archive"/>
          </SecureWrapper>
        }/>
      </>}

      <Route path="/redirect/:id" element={<ApplicationRedirect/>} />
      <Route path="/view/:id/*" element={<ApplicationView/>} />

      <Route path="/my" element={
        <SecureWrapper authority={Authority.UC6_my_applications}>
          <MyApplicationsTable/>
        </SecureWrapper>
      }/>
      <Route path="/my/plans" element={
        <SecureWrapper authority={Authority.UC6_my_applications}>
          <MyApplicationsTable plan/>
        </SecureWrapper>
      }/>
      <Route path="/add" element={
        <SecureWrapper authority={Authority.UC1_application_submit}>
          <AddApplicationTab/>
        </SecureWrapper>
      }/>
      <Route path="/form/:id?/:step?" element={
        <SecureWrapper authority={[Authority.UC1_application_submit, Authority.UC37_add_application]}>
          <ApplicationForm/>
        </SecureWrapper>
      }/>
      <Route path="/clause/:id?/:step?" element={
        <SecureWrapper authority={[Authority.UC7_application, Authority.UC19_partner_application]}>
          <ClauseForm/>
        </SecureWrapper>
      }/>
    </Routes>
  </PageContainer>;
}