import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Colors, Icon, TableHeader } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { setDetails, updateFilter, updatePageable, fetchRows, setInvalid, getCSVUrl } from '../../stores/admin/partner';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import ValidIcon from '../table/ValidIcon';
import ConfirmButton from '../table/ConfirmButton';

export default function PartnerTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading } = useSelector(state => state.partner);

  const columns = useMemo(() => [
    {
      field: 'validCompany', headerName: t('admin.partner.valid'), width: 150, type: "boolean", filter: true,
      notSortable: true, renderCell: (column, cell) => <ValidIcon valid={cell.valid} />
    },
    { field: 'regCode', headerName: t('admin.partner.regCode'), filter: true },
    { field: 'title', headerName: t('admin.partner.title'), filter: true },
    { field: 'domain', headerName: t('admin.partner.domain'), type: "classifier", classifierType: ClassifierType.domain, filter: true },
    { field: "validFromDate", type: "date", headerName: t('admin.partner.validFromDate') },
    { field: "validToDate", type: "date", headerName: t('admin.partner.validToDate') },
    { field: "notes", headerName: t('admin.partner.notes'), filter: true },
    {
      field: 'action', renderCell: (column, cell) =>
        cell.valid && <ConfirmButton message={t('admin.roleForm.confirmSetInvalid')} icon="delete" onConfirm={() => dispatch(setInvalid(cell.id))} aria-label="set partner invalid" />
    }
  ], [t, dispatch]);

  useEffect(() => !pageable.loaded && dispatch(fetchRows(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  const handleAddNew = () => {
    dispatch(setDetails({}));
    navigate(`/admin/partner`);
  };

  const handleRowSelection = (selected) => {
    dispatch(setDetails(null));
    navigate(`/admin/partner/${selected.id}`);
  };

  const downloadCSV = () => {
    window.open(getCSVUrl(pageable, filter, columns), '_blank', 'noopener,noreferrer');
  };

  const header = () => (
    <TableHeader
      buttons={<>
        <Button size="extra-small" color="tertiary" onClick={downloadCSV} disabled={isLoading}>
          <Icon icon="files" color={Colors.sinineVaal} />
          {t('table.downloadCSV')}
        </Button>
        <Button size="extra-small" onClick={handleAddNew} disabled={isLoading}>{t('button.add')}</Button>
      </>}>
      {t('admin.partner.label')}
    </TableHeader>
  );

  return <InfiniteScrollTableContainer
    rows={rows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}