import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Heading, ToDoListItem, ToDoListItemLeft, ToDoListItemRight } from 'styleguide';
import { ToDoList } from 'styleguide/build/components/Lists/ToDoList/ToDoList';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchNeedToConsider } from '../../stores/application/application';
import DateUtils from '../../utils/DateUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import LinkButton from '../LinkButton';
import CroppedText from '../table/CroppedText';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 635,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    marginBottom: 40,
  },
  title: {
    paddingLeft: 18,
    display: 'inline'
  },
  button: {
    float: 'right',
    marginRight: 3
  }
}));


export default function ToDoBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const classifiers = useSelector(state => state.classifier.classifiers);

  const [rows, setRows] = useState();

  useEffect(() => {
    async function loadData() {
      const r = await dispatch(fetchNeedToConsider());
      setRows(r);
    }
    loadData();
  }, [dispatch]);

  if (!!rows && !rows.length) {
    return "";
  }

  const handleClick = (row) => {
    navigate(`/application/redirect/${row.id}`);
  };

  return <div className={classes.container}>
    {!rows && <CenteredLoadingIndicator />}
    {!!rows && <>
      <Heading level="3" className={classes.title}>{t('homepage.block.toDo')}</Heading>

      <LinkButton to="/application" size="extra-small" color="tertiary" className={classes.button}>
        {t('button.showAll')}
      </LinkButton>

      <ToDoList>
        {rows.map(row => <ToDoListItem key={row.id} onClick={() => handleClick(row)} color={DateUtils.getDeadlineColor(row.deadline)}>
          <ToDoListItemLeft
            primary={<CroppedText value={row.address} length={45} />}
            secondary={row.applicationNumber}
          />
          <ToDoListItemRight
            primary={DateUtils.formatDate(row.deadline)}
            secondary={TableUtils.getClassifierTitle(classifiers, ClassifierType.deadlineType, row.deadlineType)}
          />
        </ToDoListItem>)}
      </ToDoList>
    </>}
  </div>;
}