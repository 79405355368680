import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Heading } from 'styleguide';
import ApplicationTypeIllustration from '../application/ApplicationTypeIllustration';
import { DialogContainer } from '../form/DialogForm';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    textAlign: 'center'
  }
}));

export default function SigningView({ application }) {
  const classes = useStyles();

  const { message } = useSelector(state => state.sign);

  return <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.container}>
    <ApplicationTypeIllustration applicationType={application.applicationType} size="100px" />

    <Box paddingTop={6} paddingBottom={2}>
      <Heading level="2">{message}</Heading>
    </Box>
  </Grid>;
}

export function SigningForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return <DialogContainer
    actions={<Button color='secondary' onClick={() => navigate(-1)}>{t('button.cancel')}</Button>}>
    <SigningView {...props} />
  </DialogContainer>
}