import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  layerDrawerOpen: {
    marginLeft: 425,
  },
}));

export default function GlobalAlert({ alert }) {
  const location = useLocation();
  const classes = useStyles();
  const { layerDrawerOpen } = useSelector((state) => state.map);
  const [open, setOpen] = useState(true);

  const maintenancePath = "/maintenance";

  if (!alert || !open) {
    return <></>;
  }

  if (alert.isMaintenance) {
    if (location.pathname !== maintenancePath) {
      return <Navigate to={maintenancePath} />;
    }
    return <></>;
  }

  const mapLayerDrawerOpen = layerDrawerOpen && location.pathname.startsWith("/map");

  return (
    <Alert
      severity="warning"
      className={mapLayerDrawerOpen ? classes.layerDrawerOpen : ''}
      onClose={() => setOpen(false)}>
      {alert.text}
    </Alert>
  );
}
