import { Grid, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetSubmitted, savePlanning } from "../../stores/planning/planning";
import DialogForm from "../form/DialogForm";
import VCheckbox from "../form/VCheckbox";
import VTextField from "../form/VTextField";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
}));

export default function PlanningEditForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selected: planning, submitted, isLoading } = useSelector(state => state.planning);

  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: planning || {}
  });

  useEffect(() => submitted && dispatch(resetSubmitted()) && navigate(-1), [submitted, dispatch, navigate]);

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    dispatch(savePlanning(planning.id, data));
  };

  return <DialogForm
    title={t('planning.view.editPlanning')}
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <VTextField name="name" label="planning.name"
          disabled={isLoading} maxLength={2000}
          register={register} errors={errors} fullWidth multiline
        />
      </Grid>
      <Grid item>
        <VTextField name="purpose" label="planning.purpose"
          disabled={isLoading} maxLength={2000} multiline
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <VTextField name="description" label="planning.description"
          disabled={isLoading} maxLength={2000} multiline
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <VCheckbox name="isYpAppropriate" label={t('planning.isYpAppropriate')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VCheckbox name="isKshNecessity" label={t('planning.isKshNecessity')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VTextField name="kshDescription" label={t('planning.kshDescription')} multiline
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={2000} />
      </Grid>
      <Grid item>
        <VTextField name="planId" label={t('planning.planId')} 
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={200} />
      </Grid>
    </Grid>
  </DialogForm>;
}
