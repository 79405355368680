import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContainer } from '../../form/DialogForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, NavButton } from 'styleguide';
import Step1Location from './Step1Location';
import Step2Purpose from './Step2Purpose';
import Step3File from './Step3File';
import Step4Applicant from './Step4Applicant';
import Step5Overview from './Step5Overview';
import { cancel, fetchSelected, resetSubmitted, signApplication, submitApplication } from '../../../stores/application/application';
import { setApplicantStatus } from '../../../stores/application/applicationApplicant';
import { setPurposeStatus } from '../../../stores/application/applicationPurpose';
import LoadingIndicator from '../../form/LoadingIndicator';
import ConfirmCancel from './ConfirmCancel';
import { ApplicationDocumentType, ApplicationType, ClassifierType, Domain } from '../../../constants/classifierConstants';
import { showWarning } from '../../../stores/notification';
import TableUtils from '../../../utils/TableUtils';
import { fetchRequiredTypes } from '../../../stores/application/applicationFile';
import SigningView from '../SigningView';
import AuthUtils from '../../../utils/AuthUtils';
import ApplicationUtils from '../../../utils/ApplicationUtils';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: 16
  }
});

export default function ApplicationForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { step, id } = useParams();
  const [nextDirection, setNextDirection] = useState();
  const [consented, setConsented] = useState(false);
  const [consentedFinance, setConsentedFinance] = useState(false);

  const { selected, isLoading, submitted } = useSelector(state => state.application);
  const { rows: locations, isLoading: isLocationLoading } = useSelector(state => state.applicationLocation);
  const { rows: files, requiredTypes, isLoading: isFileLoading } = useSelector(state => state.applicationFile);
  const { isLoading: isApplicantLoading, applicantStatus } = useSelector(state => state.applicationApplicant);
  const { applicants } = useSelector(state => state.applicationApplicant);
  const { isLoading: isPurposeLoading, purposeStatus } = useSelector(state => state.applicationPurpose);
  const classifiers = useSelector(state => state.classifier.classifiers);
  const authUser = useSelector(state => state.auth.authUser);
  const govCanAddApplication = authUser && ApplicationUtils.isGovCanAddApplication(authUser);
  const govCanAddYPApplication = authUser && ApplicationUtils.isGovCanAddYPApplication(authUser);

  const mobile = useSelector(state => state.global.mobileView);
  const disabled = isLoading || isLocationLoading || isFileLoading || isApplicantLoading || isPurposeLoading;
  const selectedId = selected?.id;
  //fetch selected
  useEffect(() => !selectedId && dispatch(fetchSelected(id)), [selectedId, id, dispatch]);
  //fetch required types 
  useEffect(() => !!selectedId && dispatch(fetchRequiredTypes(selectedId)), [selectedId, dispatch]);
  //redirect to first step if no step is present
  useEffect(() => !step && navigate(`/application/form/${id}/1`, { replace: true }),
    [navigate, step, id]);
  //redirect after successful insert
  useEffect(() => !!step && !!selectedId && isNaN(id) &&
    navigate(`/application/form/${selectedId}/${step}`, { replace: true }),
    [selectedId, id, step, navigate]);
  //redirect after form submit
  useEffect(() => step === '3' && applicantStatus === 'OK' &&
    dispatch(setApplicantStatus(null)) &&
    navigate(`/application/form/${id}/${Number(step) + nextDirection}`),
    [dispatch, step, applicantStatus, navigate, id, nextDirection]);
  //redirect after form submit
  useEffect(() => step === '2' && purposeStatus === 'OK' &&
    dispatch(setPurposeStatus(null)) &&
    navigate(`/application/form/${id}/${Number(step) + nextDirection}`),
    [dispatch, step, purposeStatus, navigate, id, nextDirection]);
  //redirect after submit
  useEffect(() => submitted && dispatch(resetSubmitted()) && navigate('/application/my', { replace: true }), [dispatch, navigate, submitted]);

  let title;
  if (step !== '1' && !isNaN(step)) {
    if (ApplicationType.DP === selected?.applicationType) {
      title = t(`plan.form.step.${step}`);
    } else if (ApplicationType.KT === selected?.applicationType && step === '2') {
      title = t(`plan.form.step.2KT`);
    } else if (ApplicationType.YP === selected?.applicationType && step === '2') {
      title = t(`plan.form.step.2YP`);
    } else {
      title = t(`application.form.step.${step}`);
    }
  }

  const currentStepProgress = Number(step) * 100 / 5;

  const handleClose = () => {
    if (AuthUtils.isGov(authUser)) {
      navigate(`/application/${Domain.plan}`, { replace: true });
    } else {
      navigate('/application/my', { replace: true });
    }
    return true;
  };

  const redirect = (step) => {
    const path = `/application/form/${id}/${step}`;
    navigate(path);
  };

  const validateFiles = () => {
    if (files?.some(file => !file.documentType)) {
      dispatch(showWarning('ErrorCode.cannotSubmitApplicationNoDocumentType'));
      return false;
    }
    if ((govCanAddApplication || govCanAddYPApplication) &&
      !files?.some(f => f.documentType === ApplicationDocumentType.application)) {
      dispatch(showWarning('ErrorCode.cannotSubmitApplicationNotAdded'));
      return false;
    }
    if (requiredTypes?.length) {
      const missingTypes = requiredTypes.filter(type => !files.some(file => file.documentType === type));
      if (missingTypes.length) {
        const types =
          missingTypes
            .map(type => TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationDocumentType, type))
            .join(', ');
        dispatch(showWarning('ErrorCode.requiredDocumentTypeNotAdded', { types }));
        return false;
      }
    }
    return true;
  };

  const validateContacts = () => {
    if (applicants.representative && !applicants.applicant) {
      dispatch(showWarning('ErrorCode.missingApplicant'));
      return false;
    }
    return true;
  }

  const handleNavigation = (direction) => {
    setNextDirection(direction);
    switch (step) {
      case '1':
        if (!locations?.length) {
          dispatch(showWarning('ErrorCode.locationMissing'));
          return;
        }
        break;
      case '2':
        dispatch(setPurposeStatus('SUBMIT'));
        return;
      case '3':
        dispatch(setApplicantStatus('SUBMIT'));
        return;
      case '4':
        if (direction > 0 && !validateFiles()) {
          return;
        }
        break;
      default:
    }
    redirect(Number(step) + direction);
  };

  const handleSubmit = () => {
    if (consented && validateContacts() && validateFiles()) {
      if (govCanAddApplication || govCanAddYPApplication) {
        dispatch(submitApplication(selected.id, authUser, () => navigate(-1)));
      } else {
        dispatch(signApplication(selected.id, authUser, () => navigate(-1)));
        redirect('sign');
      }
    }
  };

  const handleDelete = () => {
    dispatch(cancel(selected.id, govCanAddApplication, govCanAddYPApplication));
  };

  let actions = [];
  actions.push(step > 1 &&
    <NavButton key='1' onClick={() => handleNavigation(-1)} disabled={disabled}>{t('button.back')}</NavButton>);

  actions.push(step === '5' && (govCanAddApplication || govCanAddYPApplication) &&
    <Button className={classes.actionButton} key='2' disabled={isLoading} color="secondary" onClick={() => handleClose()}>
      {t(mobile ? 'button.save' : 'button.saveAndContinue')}
    </Button>);

  let mainAction;
  switch (step) {
    case '5':
      let submitDisabled = disabled || !consented;
      if ((ApplicationType.DP === selected?.applicationType ||
        ApplicationType.YP === selected?.applicationType)) {
        submitDisabled = submitDisabled || !consentedFinance;
      }
      mainAction = <Button onClick={handleSubmit} disabled={submitDisabled}>
        {(govCanAddApplication || govCanAddYPApplication) ? t('button.done') : t('button.submit')}
      </Button>;
      break;
    case 'cancel':
      mainAction = <Button onClick={handleDelete} disabled={disabled}>
        {(govCanAddApplication || govCanAddYPApplication) ? t('button.deleteApplication') : t('button.cancelApplication')}
      </Button>;
      actions = <Button color='secondary' onClick={() => navigate(-1)} disabled={disabled}>
        {(govCanAddApplication || govCanAddYPApplication) ? t('button.deleteApplicationDelete') : t('button.cancelApplicationCancel')}
      </Button>;
      break;
    case 'sign':
      actions = <Button color='secondary' onClick={() => navigate(-1)} disabled={disabled}>{t('button.cancel')}</Button>;
      break;
    default:
      mainAction = <Button onClick={() => handleNavigation(1)} disabled={disabled}>{t('button.next')}</Button>;
  }

  const renderStep = () => {
    if (!step || isNaN(id) || !selected || !selected.id) {
      return <LoadingIndicator />;
    }
    switch (step) {
      case '1': return <Step1Location application={selected} />;
      case '2': return <Step2Purpose application={selected} />;
      case '3': return <Step4Applicant application={selected} />;
      case '4': return <Step3File application={selected} />;
      case '5':
        return <Step5Overview
          application={selected}
          consented={consented}
          onConsented={setConsented}
          consentedFinance={consentedFinance}
          onConsentedFinance={setConsentedFinance}
          isLoading={isLoading}
          onDelete={() => redirect('cancel')}
        />;
      case 'cancel':
        return <ConfirmCancel application={selected} />;
      case 'sign':
        return <SigningView application={selected} />;
      default:
    }
  };

  return <DialogContainer
    title={title}
    actions={actions}
    mainAction={mainAction}
    disabled={disabled}
    progress={currentStepProgress}
    onClose={handleClose}
  >
    {renderStep()}
  </DialogContainer>;
}