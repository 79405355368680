import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import { PlanningStatus } from '../constants/classifierConstants';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PlanningTable from '../components/planning/PlanningTable';
import PlanningView from '../components/planning/PlanningView';
import OperationsTable from '../components/planning/OperationsTable';
import RouteUtils from '../utils/RouteUtils';

export default function Planning() {
  const location = useLocation();

  useEffect(() => {
    RouteUtils.setDocumentTitle('planning');
  }, []);

  switch (location.pathname) {
    case '/plan':
    case '/plan/':
      return <Navigate to="/plan/public" />;
    case `/plan/${PlanningStatus.completed}`:
    case `/plan/${PlanningStatus.invalid}`:
      return <Navigate to={`/plan/archive`} />
    default:
  }
  if (location.pathname === '/plan' || location.pathname === '/plan/') {
    return <Navigate to={`/plan/${PlanningStatus.initiated}`} />;
  }

  return <PageContainer>
    <Routes>
      <Route path="public" element={<OperationsTable/>} />
      <Route path={PlanningStatus.initiated} element={<PlanningTable status={PlanningStatus.initiated} />} />;
      <Route path={PlanningStatus.accepted} element={<PlanningTable status={PlanningStatus.accepted} />} />;
      <Route path={PlanningStatus.established} element={<PlanningTable status={PlanningStatus.established} />} />;
      <Route path="archive" element={<PlanningTable status="archive" />} />;

      <Route path="/view/:id/*" element={<PlanningView/>} />
    </Routes>
  </PageContainer>;
}