import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RegisterView from '../components/register/RegisterView';
import RegisterForm from '../components/register/RegisterForm';
import { Authority } from '../constants/authConstants';
import RegisterTable from '../components/register/RegisterTable';
import { Domain } from '../constants/classifierConstants';
import SelectRegisterObject from '../components/register/SelectRegisterObject';
import { useSelector } from 'react-redux';
import AuthUtils from '../utils/AuthUtils';
import RouteUtils from '../utils/RouteUtils';
import SecureWrapper from '../components/auth/SecureWrapper';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road, Domain.landscaping, Domain.infrastructure];

export default function Register() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('register');
  }, []);

  if (location.pathname === '/register') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Navigate to={`/register/${defaultDomain}`} />
  }

  return <PageContainer>
    <Routes>
      <Route path={Domain.rainwater} element={<RegisterTable domain={Domain.rainwater} />} />
      <Route path={Domain.streetlight} element={<RegisterTable domain={Domain.streetlight} />} />
      <Route path={Domain.road} element={<RegisterTable domain={Domain.road} />} />
      <Route path={Domain.infrastructure} element={<RegisterTable domain={Domain.infrastructure} />} />
      <Route path={Domain.landscaping} element={<RegisterTable domain={Domain.landscaping} />} />
      <Route path="/servitude" element={
        <SecureWrapper authority={[Authority.UC31_register_object_data, Authority.UC31_TV_register_object_aata]}>
          <RegisterTable domain="servitude" />
        </SecureWrapper>
      }/>

      <Route path="/view/:objectType/:id/*" element={<RegisterView/>} />
      <Route path="/select/:objectType" element={<SelectRegisterObject/>} />
      <Route path="/edit/:objectType/:id?" element={
        <SecureWrapper authority={[Authority.UC31_register_object_data, Authority.UC31_TV_register_object_aata]}>
          <RegisterForm />
        </SecureWrapper>
      }/>
    </Routes>
  </PageContainer>;
}