import { Box } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { TabContainer } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import { fetchRowsPartner, resetRows, resetSubmitted, updateFilter, updatePageable } from '../../stores/application/application';
import TableUtils from '../../utils/TableUtils';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import ApplicationStatusLabel from './ApplicationStatusLabel';
import TableHeaderWithCount from '../table/TableHeaderWithCount';
import CroppedText from '../table/CroppedText';
import DateUtils from '../../utils/DateUtils';
import RouteUtils from '../../utils/RouteUtils';

function ApplicationTabs() {
  const { t } = useTranslation();
  const mobile = useSelector(state => state.global.mobileView);

  const renderNavLink = (domain) => <NavLink to={`/application/${domain}`}>{t(`application.tabs.${domain}`)}</NavLink>;
  return <TabContainer size={mobile ? 'small' : 'big'}>
    {renderNavLink(Domain.streetlight)}
    {renderNavLink("archive")}
  </TabContainer>;
}

export default function PartnerApplicationTable({ domain }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading, totalElements, submitted, selected } = useSelector(state => state.application);
  const { classifiers } = useSelector(state => state.classifier);

  const columns = useMemo(() => [
    { field: 'applicationNumber', headerName: t('application.applicationNumber'), filter: true },
    {
      field: 'location', headerName: t('application.location'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.location} length={50} />
      )
    },
    {
      field: 'status', headerName: t('application.status'), width: 150,
      type: 'classifier', classifierType: ClassifierType.applicationStatus, filter: true, renderCell: (column, cell) =>
        <ApplicationStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.applicationStatus, cell.status)} />
    },
    {
      field: 'deadline', headerName: t('application.deadline'), renderCell: (column, row) => {
        if (row.deadline && row.deadlineType) {
          const type = TableUtils.getClassifierTitle(classifiers, ClassifierType.deadlineType, row.deadlineType);
          const date = DateUtils.formatDate(row.deadline);
          return `${type} ${date}`;
        }
        return '';
      }
    },
    {
      field: 'handlerId', headerName: t('application.handler'), type: 'govUser', filter: true, notSortable: true,
      renderCell: (column, cell) => cell.handlerName
    },
    { field: 'proceedingsDeadline', type: "date", headerName: t('application.proceedingsDeadline') },
  ], [t, classifiers]);

  useEffect(() => dispatch(resetRows()), [dispatch, domain]);
  useEffect(() => !!classifiers.length && !pageable.loaded && dispatch(fetchRowsPartner(domain, pageable, filter, columns)),
    [dispatch, pageable, filter, columns, classifiers, domain]);

  const detailsPath = !!selected && `/application/view/${selected.id}`;
  useEffect(() => {
    if (submitted && !!detailsPath) {
      dispatch(resetSubmitted());
      navigate(detailsPath);
    }
  }, [dispatch, navigate, submitted, detailsPath]);

  const handleRowSelection = (selected) => {
    RouteUtils.navigateToObject(navigate, 'application', selected.id);
  };

  const header = () => (
    <TableHeaderWithCount count={totalElements}>
      {t(`application.tableTitle.${domain}`)}
    </TableHeaderWithCount>
  );

  return <div>
    <ApplicationTabs />

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}