import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { setDetails, updateFilter, updatePageable, fetchRows } from '../../stores/admin/applicationType';
import { PageableTableContainer } from '../table/PageableTable';
import { useNavigate } from 'react-router-dom';

export default function ApplicationTypeTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { filteredRows, filter, pageable, isLoading } = useSelector(state => state.applicationType);

  const columns = useMemo(() => [
    { field: 'applicationType', headerName: t('admin.applicationType.code'), filter: true },
    { field: 'domain', headerName: t('admin.applicationType.domain'), type: "classifier", classifierType: ClassifierType.domain },
    { field: 'applicationType', headerName: t('admin.applicationType.title'), type: "classifier", classifierType: ClassifierType.applicationType }
  ], [t]);

  useEffect(() => !pageable.loaded && dispatch(fetchRows(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    navigate(`/admin/applicationType/${selected.id}`);
  };

  const header = () => (
    <TableHeader>
      {t('admin.applicationType.label')}
    </TableHeader>
  );

  return <PageableTableContainer
    rows={filteredRows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}