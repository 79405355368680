import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "styleguide";

function LinkButton({ to, onClick, ...rest }) {
  const navigate = useNavigate();

  return (
    <Button
      {...rest}
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick(event);
        navigate(to);
      }}
    />
  );
}

export default LinkButton;
