import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Heading, Paragraph } from 'styleguide';
import { addRegisterObject, fetchObjectRows, setObjectType, updateFilter, updatePageable } from '../../stores/register/register';
import DialogForm from '../form/DialogForm';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import { PageableTableContainer } from '../table/PageableTable';
import TableUtils from '../../utils/TableUtils';
import { cloneDeep } from 'lodash';

export default function SelectRegisterObject() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { objectType } = useParams();
  const { objectType: registerObjectType, objectRows, filter, pageable, isLoading, formSaved } = useSelector(state => state.register);
  const postApiPathPrefix = location.state.postApiPathPrefix;
  const stateFilter = location.state.filter;

  useEffect(() => objectType && dispatch(setObjectType(objectType, stateFilter)), [dispatch, objectType, stateFilter]);
  useEffect(() => objectType === registerObjectType && !pageable.loaded && dispatch(fetchObjectRows(objectType, pageable, filter, TableUtils.getPageSize())), [dispatch, registerObjectType, objectType, pageable, filter]);
  useEffect(() => !!formSaved && navigate(-1), [navigate, formSaved]);

  const handleShowMap = (row) => {
    dispatch(MapUtils.showRegisterObjectOnMap(navigate, objectType, row));
  };

  const handleRowSelection = (row) => {
    let object = cloneDeep(row);
    object.postApiPathPrefix = postApiPathPrefix;
    dispatch(addRegisterObject(objectType, object));
  };

  const columns = RegisterUtils.getRegisterColumns(objectType, handleShowMap);

  return <DialogForm
    title={<span>
      <Heading level='3'>Vali objekt millega siduda</Heading>
      <Paragraph fontSize='14B'>{t(`register.${RegisterUtils.getSuperObjectType(objectType)}.label`)}</Paragraph>
    </span>}
    disabled={isLoading}
  >
    <PageableTableContainer
      rows={objectRows}
      columns={columns}
      filter={filter}
      pageable={pageable}
      loading={isLoading}
      onRowSelected={handleRowSelection}
      onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
      onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
    />
  </DialogForm>;
}