import { Box } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClassifierType } from '../../constants/classifierConstants';
import { resetDetails } from '../../stores/planning/planning';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import TableHeaderWithCount from '../table/TableHeaderWithCount';
import PlanningUtils from '../../utils/PlanningUtils';
import { fetchOperationRows, updateFilter, updatePageable } from '../../stores/planning/planningOperationPaged';
import { PlanningTabs } from './PlanningTable';

export default function OperationsTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading, totalElements } = useSelector(state => state.planningOperationPaged);

  const columns = useMemo(() => [
    {
      field: 'operationType', headerName: t('planning.operation.operationType'), type: 'classifier',
      classifierType: ClassifierType.planningOperationType, filter: true
    },
    {
      field: 'description', headerName: t('planning.operation.description'), filter: true, renderCell: (column, cell) => (
        <Box whiteSpace="pre-line">{cell.description}</Box>
      )
    },
    {
      field: 'startDate', headerName: t('planning.operation.period'), width: 250,
      renderCell: (column, row) => PlanningUtils.getOperationPeriodLabel(row)
    },
  ], [t]);

  useEffect(() => !pageable.loaded && dispatch(fetchOperationRows(pageable, filter, columns)),
    [dispatch, pageable, filter, columns]);

  const handleRowSelection = (selected) => {
    dispatch(resetDetails());
    navigate(`/plan/view/${selected.planningId}`);
  };

  const header = <TableHeaderWithCount count={totalElements}>
    {t('planning.tableTitle.public')}
  </TableHeaderWithCount>;

  return <div>
    <PlanningTabs />

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}