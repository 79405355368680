import { Box, FormControlLabel, Grid, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Colors, Icon, Paragraph } from 'styleguide';
import { ApplicationStatus, ClassifierType, PlanningStatus } from '../../../constants/classifierConstants';
import { setDetails } from '../../../stores/application/application';
import { showWarning, toggleLoadingOverlay } from '../../../stores/notification';
import { planInitiate, planInvalidate, planMakeDecision, resetDetails } from '../../../stores/planning/planning';
import { addDocument, addDocumentVersion, saveDocument } from '../../../stores/planning/planningDocument';
import DateUtils from '../../../utils/DateUtils';
import PlanningUtils from '../../../utils/PlanningUtils';
import ClassifierSelect from '../../form/ClassifierSelect';
import ControlledAutocomplete from '../../form/ControlledAutocomplete';
import DialogForm, { DialogContainer } from '../../form/DialogForm';
import VCheckbox from '../../form/VCheckbox';
import VDatePicker from '../../form/VDatePicker';
import VTextField from '../../form/VTextField';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
  formBlockContainer: {
    padding: 12,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
  fileFormContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: '0 auto'
    },
  }
}));

function DocumentForm({ title, onSubmit, showAuthor, showDocumentType, isLoading, document, actions }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const participants = useSelector(state => state.planningParticipant.rows);

  const [file, setFile] = useState(null);

  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: document || {
      documentDate: new Date()
    }
  });

  const editing = !!document;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      setFile(files[0]);
    }
  });

  const handleFormSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    if (!editing && !data.url && !file && !data.content) {
      dispatch(showWarning('planning.notification.noLinkOrFile'));
      return;
    }

    if (!data.url && !!file) {
      data.file = file;
    }
    if (data.author) {
      data.authorId = data.author.id;
      data.author = undefined;
    }
    data.documentDate = DateUtils.formatISODate(data.documentDate);
    onSubmit(data);
  };

  return <DialogForm
    title={title}
    onSubmit={handleSubmit(handleFormSubmit)}
    disabled={isLoading}
    actions={actions}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      {!!showAuthor && <Grid item>
        <ControlledAutocomplete name="author" label={t('planning.document.author')}
          disabled={isLoading || editing} control={control} errors={errors}
          options={participants} getOptionLabel={(o) => o.fullName}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </Grid>}
      {!!showDocumentType && <Grid item>
        <ClassifierSelect item={document || {}} name="documentType" label="planning.document.documentType"
          disabled={isLoading || editing} required classifierType={ClassifierType.planningDocumentType}
          control={control} errors={errors} formatItemTitle={PlanningUtils.formatPlanningDocumentType} sortBy="itemCode"
        />
      </Grid>}
      <Grid item>
        <VTextField name="description" label={t('planning.document.description')}
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={2000} />
      </Grid>
      <Grid item>
        <VTextField name="number" label={t('planning.document.number')}
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={100} />
      </Grid>
      <Grid item>
        <VDatePicker name="documentDate" label={t('planning.document.documentDate')}
          control={control} errors={errors} fullWidth disabled={isLoading} required
        />
      </Grid>
      <Grid item>
        <VTextField name="content" label={t('planning.document.content')} multiline
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={2000}
        />
      </Grid>
      {!editing && <Grid item>
        {!!file ?
          <Grid container direction="row" justifyContent="space-between" className={classes.formBlockContainer}>
            <Paragraph fontSize='12'>{file.name}</Paragraph>
            <IconButton size="small" onClick={() => setFile(null)} aria-label="delete file">
              <Icon icon="delete" />
            </IconButton>
          </Grid>
          :
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
            <Grid container direction="row" className={classes.uploadRow} onClick={open}>
              <Icon icon='add' color={Colors.sinineVaal} />
              <Box marginLeft={2}>
                <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
              </Box>
              <input type="file" {...getInputProps()} />
            </Grid>
          </div>
        }
      </Grid>}
      {(!editing || !!document.url) && <Grid item>
        <VTextField name="url" label={t('planning.document.link')}
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={2000} />
      </Grid>}
      <Grid item>
        <VCheckbox name="isPublic" label={t('planning.document.isPublic')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VCheckbox name="isPlan" label={t('planning.document.isPlan')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VCheckbox name="isFrontpage" label={t('planning.document.isFrontpage')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
    </Grid>
  </DialogForm>;
}

export function PlanInitiateForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const handleSubmit = async (data) => {
    dispatch(toggleLoadingOverlay(true));
    const planning = await dispatch(planInitiate(id, data));
    if (planning) {
      dispatch(setDetails(null));
      dispatch(resetDetails());
      navigate(`/plan/view/${planning.id}`, { replace: true });
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DocumentForm title={t(`planning.decision.${PlanningStatus.initiated}`)} onSubmit={handleSubmit} isLoading={isLoading} />;
}

export function PlanningAddDocumentForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, operationId } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const handleSubmit = async (data) => {
    dispatch(toggleLoadingOverlay(true));
    if (operationId) {
      data.operationId = operationId;
    }
    const document = await dispatch(addDocument(id, data));
    if (document) {
      navigate(-1);
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DocumentForm title={t('planning.document.addTitle')} onSubmit={handleSubmit} isLoading={isLoading} showDocumentType showAuthor />;
}

export function PlanningDecisionDocumentForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, decision } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const handleSubmit = async (data) => {
    dispatch(toggleLoadingOverlay(true));
    const document = await dispatch(planMakeDecision(id, decision, data));
    if (document) {
      navigate(-1);
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DocumentForm title={t(`planning.decision.${decision}`)} onSubmit={handleSubmit} isLoading={isLoading} />;
}

export function PlanningEditDocumentForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, documentId } = useParams();

  const participants = useSelector(state => state.planningParticipant.rows);
  const documents = useSelector(state => state.planningDocument.rows);
  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const document = cloneDeep(documents?.find(d => d.id === Number(documentId)));
  if (document.authorId) {
    document.author = participants?.find(p => p.id === document.authorId);
  }


  if (!documents || !id || !documentId || !document) {
    navigate(-1);
    return;
  }

  const handleSubmit = async (data) => {
    dispatch(toggleLoadingOverlay(true));
    const document = await dispatch(saveDocument(id, documentId, data));
    if (document) {
      navigate(-1);
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DocumentForm
    title={t('planning.document.editTitle')}
    onSubmit={handleSubmit}
    document={document}
    actions={!!document?.fileName &&
      <Button color="secondary" onClick={() => navigate(`/plan/view/${id}/addDocumentVersion/${documentId}`, { replace: true })} disabled={isLoading}>
        {t('planning.document.addVersionButton')}
      </Button>}
    isLoading={isLoading}
    showDocumentType showAuthor
  />;
}

export function PlanningEditDocumentLimitedForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, documentId } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);
  const documents = useSelector(state => state.planningDocument.rows);
  const document = documents?.find(d => d.id === Number(documentId));

  const { control, errors, handleSubmit } = useForm({
    defaultValues: document
  });

  if (!documents || !id || !documentId || !document) {
    navigate(-1);
    return;
  }

  const handleFormSubmit = async (inputData) => {
    let data = cloneDeep(inputData);
    const document = await dispatch(saveDocument(id, documentId, data));
    if (document) {
      navigate(-1);
    }
  };

  return <DialogForm
    title={t('planning.document.editTitle')}
    onSubmit={handleSubmit(handleFormSubmit)}
    actions={!!document?.fileName &&
      <Button color="secondary" onClick={() => navigate(`/plan/view/${id}/addDocumentVersion/${documentId}`, { replace: true })} disabled={isLoading}>
        {t('planning.document.addVersionButton')}
      </Button>}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <VCheckbox name="isPublic" label={t('planning.document.isPublic')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VCheckbox name="isPlan" label={t('planning.document.isPlan')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
    </Grid>
  </DialogForm>;
}

export function PlanningAddDocumentVersionForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, documentId } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const [file, setFile] = useState(null);
  const [replace, setReplace] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      setFile(files[0]);
    }
  });

  if (!id || !documentId) {
    navigate(-1);
    return;
  }

  const handleSubmit = async () => {
    if (!file) {
      dispatch(showWarning('planning.notification.noLinkOrFile'));
      return;
    }
    dispatch(toggleLoadingOverlay(true));
    const success = await dispatch(addDocumentVersion(id, documentId, { file, replace }));
    if (success) {
      navigate(-1);
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DialogContainer
    title={t('planning.document.addVersionTitle')}
    mainAction={<Button onClick={handleSubmit} disabled={isLoading || !file}>{t('button.save')}</Button>}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.fileFormContainer}>
      <Grid item>
        {!!file ?
          <Grid container direction="row" justifyContent="space-between" className={classes.formBlockContainer}>
            <Paragraph fontSize='12'>{file.name}</Paragraph>
            <IconButton size="small" onClick={() => setFile(null)} aria-label="delete file">
              <Icon icon="delete" />
            </IconButton>
          </Grid>
          :
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
            <Grid container direction="row" className={classes.uploadRow} onClick={open}>
              <Icon icon='add' color={Colors.sinineVaal} />
              <Box marginLeft={2}>
                <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
              </Box>
              <input type="file" {...getInputProps()} />
            </Grid>
          </div>
        }
      </Grid>
      <Grid item>
        <FormControlLabel
          label={t('planning.document.replaceVersion')}
          control={<Checkbox
            onChange={(e) => setReplace(e.target.checked)}
            disabled={isLoading}
            checked={replace}
          />}
        />
      </Grid>
    </Grid>
  </DialogContainer>
}

export function PlanInvalidateForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isLoading = useSelector(state => state.planningDocument.isLoading);

  const handleSubmit = async (data) => {
    dispatch(toggleLoadingOverlay(true));
    const success = await dispatch(planInvalidate(id, data));
    if (success) {
      dispatch(setDetails(null));
      dispatch(resetDetails());
      navigate(`/application/view/${id}`, { replace: true });
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <DocumentForm title={t(`planning.decision.${ApplicationStatus.decisionYes}`)} onSubmit={handleSubmit} isLoading={isLoading} />;
}