import React from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../api/register';
import RegisterUtils from '../../utils/RegisterUtils';
import ActivityLogTable from '../table/ActivityLogTable';

export default function RegisterHistory() {
  let { objectType, id } = useParams();
  const fetchLogsUrl = `${RegisterUtils.getSuperObjectType(objectType)}/${id}/log`;

  return <ActivityLogTable
    api={api}
    fetchLogsUrl={fetchLogsUrl}
  />;
}