import { Box, Grid, makeStyles, Paper, TableCell, Toolbar } from '@material-ui/core';
import { clone } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, Button, Heading, Icon, NavButton, Paragraph, TableHeader } from 'styleguide';
import { Domain } from '../../constants/classifierConstants';
import { fetchGet, fetchRowData, toggleRow } from '../../stores/register/registerReport';
import ChartUtils from '../../utils/ChartUtils';
import DateUtils from '../../utils/DateUtils';
import PdfUtils from '../../utils/PdfUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import ExportCSVButton from '../table/ExportCSVButton';
import PageableTable from '../table/PageableTable';
import SimplePageableTable from '../table/SimplePageableTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  exportChart: {
    right: 24,
    top: 24,
    float: 'right',
    zIndex: 2
  },
  iconCell: {
    verticalAlign: 'middle',
    marginRight: 8
  },
}));

export default function RoadAgeReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reportName = 'roadAge';
  const rows = useSelector(state => state.registerReport.rows[reportName]);
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const compact = useSelector(state => state.global.mobileView);

  const title = t(`report.TE.roadAge`);
  const subTitle = t('report.query');

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'period', headerName: t('register.road.establishmentYear'), notSortable: true,
        renderCell: (column, cell) => <Grid container>
          {!!cell.id && <Icon icon={cell.open ? 'minus' : 'plus'} className={classes.iconCell} />}
          <Paragraph fontSize='14B'>
            {!!cell.id ? cell.period : t('report.table.total')}
          </Paragraph>
        </Grid>
      },
      {
        field: 'count', headerName: t(`report.table.roadCount`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.id) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'length', headerName: t(`report.table.length`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length || 0)} ${t('report.table.unitKm')}`;
          if (!cell.id) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'percentage', headerName: t(`report.table.percentage`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.percentage) || 0} %`;
          if (!cell.id) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t, classes]);

  const detailColumns = useMemo(() => {
    let columns = [
      { field: 'title', headerName: t('register.road.title'), },
      { field: 'firstEstablishmentYear', headerName: t('register.road.firstEstablishmentYear'), type: "integer" },
      { field: 'lengthM', headerName: t('register.road.lengthM'), type: "number", unit: "m" },
      { field: 'lastMaintenanceYear', headerName: t('register.roadSurface.lastMaintenanceYear'), type: "integer" },
    ];
    return columns;
  }, [t]);

  const shouldFetch = !rows;
  useEffect(() => shouldFetch && dispatch(fetchGet(Domain.road, reportName)), [dispatch, shouldFetch]);

  const handleClose = () => navigate(-1);

  const exportCsvButton = <ExportCSVButton
    rows={rows}
    columns={columns.map(c => {
      const cloned = clone(c);
      delete cloned.renderCell;
      return cloned;
    })}
    filename={title}
  />;
  const header = <TableHeader buttons={exportCsvButton}>{t('report.TE.roadAgeTableTitle')}</TableHeader>;

  if (!rows || isLoading) {
    return <CenteredLoadingIndicator />;
  }

  const handleChartExport = () => {
    PdfUtils.saveImage('chart', `${subTitle} ${title} ${DateUtils.nowISODate()}`);
  };

  const handleRowSelection = (row) => {
    if (row.id) {
      if (!row.data) {
        dispatch(fetchRowData(Domain.road, reportName, row, row.id));
      }
      dispatch(toggleRow(row, !row.open, reportName));
    }
  };

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <TableCell colSpan={4} style={{ padding: 0 }}>
        <PageableTable
          rows={row.data}
          columns={detailColumns}
          totalRows={row.data.count}
        />
      </TableCell>;
    }
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
    </Toolbar>

    <Box py={4}>
      <SimplePageableTable
        id="report-table"
        rows={rows}
        columns={columns}
        header={header}
        pageSize={200}
        disableLoading
        loading={isLoading || !rows}
        loadingRowsCount={1}
        onRenderCollapsibleRow={handleRenderCollapsibleRow}
        onRowSelected={handleRowSelection}
      />
    </Box>

    {!compact && rows && <Paper>
      <Button size="extra-small" color="tertiary" onClick={handleChartExport}
        disabled={isLoading || !rows?.length} className={classes.exportChart}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPng')}
      </Button>
      <ResponsiveContainer width="100%" height={600} id="chart">
        <BarChart
          width={500}
          height={600}
          data={rows.filter(r => !!r.id)}
          margin={{
            top: 50,
            right: 50,
            left: 30,
            bottom: 100,
          }}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="period" />
          <YAxis orientation="left" unit={t('report.table.unitKm')} />
          <Tooltip />
          <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{ position: 'relative' }} />
          <Bar dataKey="length" name={t('report.table.length')} fill={ChartUtils.getGraphColor(1)} radius={4} label={{ position: 'top' }} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>}
  </div>;
}