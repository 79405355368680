
import './App.css';
import Header from './parts/Header';
//TODO fix https://material-ui.com/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme
import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, CssBaseline, Slide, ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme, makeStyles, useMediaQuery } from '@material-ui/core';
import { etEE } from '@material-ui/core/locale';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { et } from 'date-fns/locale';
import { Suspense, lazy, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Colors, Notification, Shadows } from 'styleguide';
import alerts from "./alerts.json";
import IdleTimer from './components/auth/IdleTimer';
import LogoutErrorHandler from './components/auth/LogoutErrorHandler';
import SecureWrapper from './components/auth/SecureWrapper';
import LoadingIndicator, { CenteredLoadingIndicator } from './components/form/LoadingIndicator';
import ScrollToTop from './components/table/ScrollToTop';
import UserProfileForm from './components/user/UserProfileForm';
import { AuthRole, Authority } from './constants/authConstants';
import './i18n';
import Admin from './pages/Admin';
import Application from './pages/Application';
import Homepage from './pages/Homepage';
import MainMap from './pages/MainMap';
import OpenData from './pages/OpenData';
import Planning from './pages/Planning';
import Register from './pages/Register';
import Report from './pages/Report';
import SearchPage from './pages/SearchPage';
import UserNotification from './pages/UserNotification';
import WorkOrder from './pages/WorkOrder';
import Error404 from './parts/Error404';
import GlobalAlert from './parts/GlobalAlert';
import Maintenance from './parts/Maintenance';
import { fetchBasicObjectTypes } from './stores/admin/basicObjectType';
import { fetchClassifiers } from './stores/admin/classifier';
import { fetchUnreadCount } from './stores/admin/userNotification';
import { fetchAuthUser } from './stores/auth';
import { changeViewMode } from './stores/global';
import { fetchLayers, toggleLayerDrawer } from './stores/map/map';
import { hideNotification } from './stores/notification';
import DateUtils from './utils/DateUtils';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.sinineVaal,
    },
    secondary: {
      main: Colors.vagaVesi,
    },
    error: {
      main: Colors.hapumarjapuu
    },
    warning: {
      main: Colors.paikesekiir
    },
    info: {
      main: Colors.lainehari
    },
    success: {
      main: Colors.maismaa
    },
    background: {
      default: Colors.hall4
    },
    text: {
      primary: Colors.vagaVesi
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize: 14,
          letterSpacing: 0.4,
          lineHeight: '24px'
        },
        a: {
          textDecoration: 'none',
          fontWeight: 'bold',
          color: Colors.sinineVaal
        },
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(9,30,66,0.1)',
          outline: '1px solid slategrey'
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(9,30,66,0.5)',
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
          color: Colors.sinineVaal
        },
        '&$selected:hover': {
          backgroundColor: 'rgba(47, 97, 224, 0.05)'
        }
      },
      button: {
        '&:hover': {
          backgroundColor: 'rgba(47, 97, 224, 0.05)'
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: Shadows.shadow1
      },
      elevation2: {
        boxShadow: Shadows.shadow2
      },
      elevation24: {
        boxShadow: Shadows.shadow1
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: Colors.hall2
      }
    },
    MuiButton: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
          opacity: 0.5,
          transition: 'all 0.2s ease-in-out'
        }
      }
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: Colors.withOpacity(Colors.maismaa, 0.3)
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
}, etEE);

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    zIndex: 1500
  }
}));

function Notifications() {
  const dispatch = useDispatch();
  const { open, message, type } = useSelector(state => state.notification);

  const handleClose = () => dispatch(hideNotification());

  return <Notification open={open} message={message} type={type} onClose={handleClose} TransitionComponent={Slide} />
}

function LoadingOverlay() {
  const classes = useStyles();
  const { showLoadingOveray } = useSelector(state => state.notification);

  return <Backdrop className={classes.loadingOverlay} open={showLoadingOveray}>
    <LoadingIndicator />
  </Backdrop>;
}

const isActiveAlert = (alert) => {
  const from = DateUtils.parseDateTime(alert?.from);
  const to = DateUtils.parseDateTime(alert?.to);
  return alert && alert.text && DateUtils.isPast(from) && DateUtils.isFuture(to);
};

const Monitoring = process.env.REACT_APP_SVMS === 'true' ? lazy(() => import('./svms/MonitoringApp')) : Error404;
const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

function App() {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.authUser);
  const authenticated = useSelector(state => state.auth.authenticated);
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [activeAlert, setActiveAlert] = useState();

  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
    }

    if(alerts && alerts.length) {
      const activeAlert = alerts.find(isActiveAlert);
      
      if(activeAlert) {
        const isMaintenance = DateUtils.isPast(DateUtils.parseDateTime(activeAlert.maintenanceFrom));
        setActiveAlert({ ...activeAlert, isMaintenance });
        
        if(isMaintenance) {
          return;
        }
      }
    }

    dispatch(fetchAuthUser());
    dispatch(fetchClassifiers());
    dispatch(fetchBasicObjectTypes());
  }, [dispatch]);

  useEffect(() => {
    if (authUser) {
      dispatch(fetchLayers(authUser));
      if (authenticated) {
        dispatch(fetchUnreadCount());
        if (trackingId) {
          ReactGA.set({ userId: authUser.id });
        }
      } else {
        if (trackingId) {
          ReactGA.set({ userId: 'anonymous' });
        }
      }
    }
  }, [dispatch, authenticated, authUser]);

  useEffect(() => {
    dispatch(changeViewMode(!desktop));
    dispatch(toggleLayerDrawer(desktop));
  }, [dispatch, desktop]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<CenteredLoadingIndicator />}>
        <MuiPickersUtilsProvider locale={et} utils={DateFnsUtils}>
          <CssBaseline />
          <Notifications />
          <LoadingOverlay />
          <ScrollToTop />
          <BrowserRouter>
            {!activeAlert?.isMaintenance && <Header/>}
            <GlobalAlert alert={activeAlert}/>
            <Routes>
              <Route path="/" element={<Homepage/>} />
              <Route path="/application/*" element={<Application/>} />
              <Route path="/plan/*" element={<Planning/>} />
              <Route path="/map/*" element={<MainMap/>} />
              <Route path="/register/*" element={<Register/>} />
              <Route path="/report/*" element={<Report/>} />
              <Route path="/search/*" element={<SearchPage/>} />
              <Route path="/openData/*" element={<OpenData/>} />
              <Route path="/notification/*" element={<UserNotification/>} />
              <Route path="/profile/*" element={<UserProfileForm/>} />
              <Route path="/loginError/:msg?" element={<LogoutErrorHandler/>} />
              <Route path="/maintenance" element={<Maintenance alert={activeAlert}/>}/>
              <Route 
                path="/workOrder/*" 
                element={
                  <SecureWrapper authority={[Authority.UC26_work_orders, Authority.UC27_partner_work_order]}>
                    <WorkOrder/>
                  </SecureWrapper>
                }
              />
              <Route 
                path="/admin/*" 
                element={
                  <SecureWrapper role={AuthRole.Admin}>
                    <Admin/>
                  </SecureWrapper>
                }
              />
              <Route 
                path="/monitoring/*" 
                element={
                  <SecureWrapper authority={Authority.SVMS_UC1_view}>
                    <Monitoring/>
                  </SecureWrapper>
                }
              />
            </Routes>
          </BrowserRouter>
          {authenticated && <IdleTimer/>}
        </MuiPickersUtilsProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
