import { Box, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, Routes, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { Heading, Icon, NavButton, Paragraph, TabContainer } from 'styleguide';
import { addDrawingGeometryToQueue, toggleFeaturesDialog } from '../../stores/map/feature';
import { toggleActiveControl } from '../../stores/map/map';
import { showSuccess, showWarning } from '../../stores/notification';
import { fetchDetails, setRegisterObjectGeometriesOnMap } from '../../stores/register/register';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import LoadingIndicator from '../form/LoadingIndicator';
import ToolbarButton from '../table/ToolbarButton';
import RegisterDetails from './RegisterDetails';
import RegisterHistory from './RegisterHistory';
import RegisterJoinForm from './RegisterJoinForm';
import RegisterRelatedDocuments from './RegisterRelatedDocuments';
import RegisterRelatedObjects from './RegisterRelatedObjects';
import AuthUtils from '../../utils/AuthUtils';
import { Authority } from '../../constants/authConstants';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  }
}));

export default function RegisterView({ mapView, mapCenter }) {
  const { objectType, id } = useParams();
  const url = useResolvedPath("").pathname;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { objects } = useSelector(state => state.register);
  const { rows: basicObjectTypes } = useSelector(state => state.basicObjectType);
  const compact = useSelector(state => state.global.mobileView) || mapView;
  const { authUser } = useSelector(state => state.auth);

  const objectDef = RegisterUtils.getObjectDef(objectType);
  const item = objects[objectType];
  const isGeometry = item && objectDef && 
    (objectType === 'shieldRegion' || !!RegisterUtils.getRegisterGeometries(objectType, item)?.length);

  useEffect(() => objectDef && (!item || Number(id) !== item.id) && dispatch(fetchDetails(objectType, id)), [dispatch, objectType, id, item, objectDef]);
  useEffect(() => mapCenter && item && dispatch(setRegisterObjectGeometriesOnMap(objectType, item, true)), [dispatch, objectType, item, mapCenter]);
  useEffect(() => {
    if (item && !!item.deleted) {
      if (mapView) {
        navigate(`/map`, { replace: true });
      } else {
        navigate(-1);
      }
    }
  }, [item, navigate, mapView]);
  //redirect if basicObject without correct objectType
  const shouldRedirectBasicObject = objectType === 'basicObject' && !!item && item.basicObjectType?.id;
  const basicObjectPath = shouldRedirectBasicObject && url.replace('basicObject', item.basicObjectType.id);
  useEffect(() => basicObjectPath && navigate(basicObjectPath, { replace: true }), [navigate, basicObjectPath])

  const handleClose = () => {
    navigate(`/register/${objectDef.domain}`, { replace: true });
  };

  const handleShowMap = () => {
    dispatch(setRegisterObjectGeometriesOnMap(objectType, item, !mapView));
    if (!mapView) {
      dispatch(toggleFeaturesDialog(false));
      dispatch(toggleActiveControl(null, false));
      navigate(`/map`);
    }
  };

  const handleShowMapNewWindow = () => {
    window.open(`/map/f/r/viewCenter/${objectType}/${id}`, '_blank');
  };

  const handleOpenRegister = () => {
    navigate(`/register/view/${objectType}/${id}`);
  };

  const handleExportWkt = () => {
    const geometries = RegisterUtils.getRegisterGeometries(objectType, item);
    if (geometries.length) {
      const wkts = MapUtils.toWkt(geometries);
      MapUtils.copyToClipboard(wkts.join(','));
      dispatch(showSuccess('map.control.wktCopied'));
    } else {
      dispatch(showWarning('error.map.noGeometry'));
    }
  };

  const handleAddToDrawingLayer = (event) => {
    event.stopPropagation();
    const geometries = RegisterUtils.getRegisterGeometries(objectType, item);
    if(geometries.length) {
      geometries.forEach(geometry => dispatch(addDrawingGeometryToQueue(geometry)));
    }
  };

  if (!id || !objectType || !item || !objectDef || !basicObjectTypes.length) {
    return <LoadingIndicator />;
  }

  let navTabs = [
    <NavLink key="details" to={`${url}`} end replace>{t('register.view.details')}</NavLink>
  ];
  if (!objectDef.noRelatedObjects) {
    navTabs.push(<NavLink key="related" to={`${url}/related`} replace>{t('register.view.relatedObjects')}</NavLink>);
  }
  if (!objectDef.noDocuments) {
    navTabs.push(<NavLink key="documents" to={`${url}/documents`} replace>{t('register.view.relatedDocuments')}</NavLink>);
  }
  if (!objectDef.noHistory) {
    navTabs.push(<NavLink key="history" to={`${url}/history`} replace>{t('register.view.history')}</NavLink>);
  }

  return <div>
    <Toolbar className={classes.toolbar}>
      {!mapView && <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>}
      <span className={classes.header}>
        <Heading level={mapView ? '4' : '3'}>{item.title}</Heading>
        <Paragraph fontSize={mapView ? '14' : '14B'}>{t(`register.${RegisterUtils.getSuperObjectType(objectType)}.label`)}</Paragraph>
      </span>
      <span className={classes.flex}></span>

      {mapView && <IconButton onClick={handleExportWkt} title={t('map.control.wkt')} size="small">
        <Icon icon='wkt' width="46" />
      </IconButton>}
      {mapView && <ToolbarButton compact={compact} onClick={handleOpenRegister} icon="register" label={t('button.openRegister')} />}
      {isGeometry && <>
        {mapView && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
          <ToolbarButton compact={compact} onClick={handleAddToDrawingLayer} icon="pencil" label={t('button.addToDrawingLayer')} />
        }
        <ToolbarButton compact={compact} onClick={handleShowMap} icon="earth" label={t('button.showMap')} />
        <ToolbarButton compact={compact} onClick={handleShowMapNewWindow} icon="arrowUpRight" label={t('button.showMapNewWindow')} />
      </>}
    </Toolbar>

    {navTabs.length > 1 && <Box py={compact ? 2 : 5}>
      <TabContainer size={(compact) ? 'small' : 'big'}>
        {navTabs.map(tab => tab)}
      </TabContainer>
    </Box>}

    <Routes>
      <Route path="" element={<RegisterDetails compact={compact} mapView={mapView} />} />
      <Route path="/related" element={<RegisterRelatedObjects compact={compact} />} />
      <Route path="/documents" element={<RegisterRelatedDocuments compact={compact} />} />
      <Route path="/history" element={<RegisterHistory />} />
      <Route path="/join" element={<RegisterJoinForm compact={compact} />} />
    </Routes>
  </div>;
}
