import { makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading } from 'styleguide';
import LoadingIndicator from '../form/LoadingIndicator';
import ProcedureUtils from '../../utils/ProcedureUtils';
import { fetchSelected } from '../../stores/planning/planning';
import PlanningFeatureDetails from './PlanningFeatureDetails';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  }
}));

export default function PlanningFeatureView() {
  const { objectType, id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selected } = useSelector(state => state.planning);

  const objectDef = ProcedureUtils.getObjectDef(objectType);

  useEffect(() => objectDef && (!selected || Number(id) !== selected.id) && dispatch(fetchSelected(id)), [dispatch, id, selected, objectDef]);

  useEffect(() => {
    if (selected && !!selected.deleted) {
      navigate(`/map`, { replace: true });
    }
  }, [selected, navigate]);

  if (!id || !objectType || !selected || !objectDef) {
    return <LoadingIndicator />;
  }

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Heading level='4'>{t(`procedure.${objectType}.label`)}</Heading>
      </span>
      <span className={classes.flex}></span>
    </Toolbar>
    <PlanningFeatureDetails objectType={objectType}/>
  </div>;
}
