import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { TableHeader } from 'styleguide';
import { ClassifierType, PlanningStatus } from '../../constants/classifierConstants';
import { fetchPlanningRows, resetDetails, resetRows, resetSubmitted, updateFilter, updatePageable, updateUserFilter } from '../../stores/planning/planning';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import CroppedText from '../table/CroppedText';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import PlanningStatusLabel from './PlanningStatusLabel';
import AuthUtils from '../../utils/AuthUtils';
import { Authority } from '../../constants/authConstants';
import UserFilter from '../table/UserFilter';
import NavigationTabs from '../table/NavigationTabs';

export function PlanningTabs() {
  const { t } = useTranslation();

  const renderNavLink = (status) => <NavLink to={`/plan/${status}`}>{t(`planning.tabs.${status}`)}</NavLink>;
  return <NavigationTabs>
    {renderNavLink("public")}
    {renderNavLink(PlanningStatus.initiated)}
    {renderNavLink(PlanningStatus.accepted)}
    {renderNavLink(PlanningStatus.established)}
    {renderNavLink("archive")}
  </NavigationTabs>;
}

export default function PlanningTable({ status }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading, totalElements, userFilter, submitted, selected } = useSelector(state => state.planning);
  const authUser = useSelector(state => state.auth.authUser);
  const hasAuthorityToViewAllGovUsers = AuthUtils.hasAnyAuthority(authUser, [Authority.UC26_work_orders,
  Authority.UC5_admin_application_types,
  Authority.UC22_admin_users,
  Authority.UC27_partner_work_order]);

  const columns = useMemo(() => {
    const c = [
      { field: 'number', headerName: t('planning.number'), filter: true },
      { field: 'name', headerName: t('planning.name'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.name} length={50} />
      ) },
      {
        field: 'location', headerName: t('planning.location'), filter: true, renderCell: (column, cell) => (
          <CroppedText value={cell.location} length={50} />
        )
      },
      {
        field: 'address', headerName: t('planning.address'), filter: true, renderCell: (column, cell) => (
          <CroppedText value={cell.address} length={50} />
        )
      },
      {
        field: 'status', headerName: t('planning.status'), width: 150,
        type: 'classifier', classifierType: ClassifierType.planningStatus, filter: true, renderCell: (column, cell) =>
          <PlanningStatusLabel status={cell.status} />
      },
      {
        field: 'handlerId', headerName: t('planning.handler'), type: hasAuthorityToViewAllGovUsers ? 'govUser' : '', filter: true, notSortable: true,
        renderCell: (column, cell) => cell.handlerName,
      },
    ];
    switch (status) {
      case PlanningStatus.initiated:
        c.splice(5, 0, { field: 'initiatedDate', headerName: t('planning.initiatedDate'), type: 'date' });
        break;
      case PlanningStatus.accepted:
        c.splice(5, 0, { field: 'acceptedDate', headerName: t('planning.acceptedDate'), type: 'date' });
        break;
      case PlanningStatus.established:
        c.splice(5, 0, { field: 'establishedDate', headerName: t('planning.establishedDate'), type: 'date' });
        break;
      case "archive":
        c.splice(5, 0, { field: 'archivedDate', headerName: t('planning.archivedDate'), type: 'date' });
        break;
      default:
    }
    return c;
  }, [t, status, hasAuthorityToViewAllGovUsers]);

  useEffect(() => dispatch(resetRows()), [dispatch, status]);
  useEffect(() => !pageable.loaded && dispatch(fetchPlanningRows(status, pageable, filter, columns, userFilter)),
    [dispatch, pageable, filter, columns, status, userFilter]);

  const detailsPath = !!selected && `/plan/view/${selected.id}`;
  useEffect(() => {
    if (submitted && !!detailsPath) {
      dispatch(resetSubmitted());
      navigate(detailsPath);
    }
  }, [dispatch, navigate, submitted, detailsPath]);

  const handleRowSelection = (selected) => {
    dispatch(resetDetails());
    navigate(`/plan/view/${selected.id}`);
  };

  const handleUserFilterChange = (event, filter) => {
    dispatch(updateUserFilter(filter));
  };

  const filterItems = [{
    value: 'assignedToMe',
    label: t('planning.filter.mine')
  }];
  const header = () => (
    <TableHeader>
      <Grid container direction="column">
        <Box paddingBottom={1}>
          {t(`planning.tableTitle.${status}`)}
          <TotalElementsLabel>{totalElements}</TotalElementsLabel>
        </Box>
        {AuthUtils.hasAuthority(authUser, Authority.UC11_planning) &&
          <UserFilter value={userFilter} items={filterItems} onChange={handleUserFilterChange} disabled={isLoading} />
        }
      </Grid>
    </TableHeader>
  );

  return <div>
    <PlanningTabs />

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}