import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Heading, ToDoListItem, ToDoListItemLeft, ToDoListItemRight } from 'styleguide';
import { ToDoList } from 'styleguide/build/components/Lists/ToDoList/ToDoList';
import { fetchMyActive, setDetails } from '../../stores/workOrder/workOrder';
import DateUtils from '../../utils/DateUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import LinkButton from '../LinkButton';
import CroppedText from '../table/CroppedText';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 635,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    marginBottom: 40,
  },
  title: {
    paddingLeft: 18,
    display: 'inline'
  },
  buttonBlock: {
    float: 'right',
    marginRight: 3
  },
  button: {
    marginLeft: 12
  },
}));


export default function WorkOrderActiveBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const authUser = useSelector(state => state.auth.authUser);

  const [rows, setRows] = useState();

  useEffect(() => {
    async function loadData() {
      const r = await dispatch(fetchMyActive());
      setRows(r);
    }
    loadData();
  }, [dispatch]);

  if (!!rows && !rows.length) {
    return "";
  }

  const handleClick = (row) => {
    navigate(`/workOrder/view/${row.id}`);
  };

  const handleAddNew = () => {
    dispatch(setDetails({ domain: authUser.domain }));
  };

  return <div className={classes.container}>
    {!rows && <CenteredLoadingIndicator />}
    {!!rows && <>
      <Heading level="3" className={classes.title}>{t('homepage.block.workOrderActive')}</Heading>

      <div className={classes.buttonBlock}>
        <LinkButton to="/workOrder" size="extra-small" color="tertiary">
          {t('button.showAll')}
        </LinkButton>
        <LinkButton to="/workOrder/edit" onClick={handleAddNew} size="extra-small" className={classes.button}>
          {t('button.add')}
        </LinkButton>
      </div>

      <ToDoList>
        {rows.map(row => <ToDoListItem key={row.id} onClick={() => handleClick(row)} color={DateUtils.getDeadlineColor(row.deadline)}>
          <ToDoListItemLeft
            primary={<CroppedText value={row.location} length={45} />}
            secondary={t('workOrder.view.workOrderWithNumber', { number: row.id })}
          />
          <ToDoListItemRight
            primary={DateUtils.formatDateTime(row.deadline)}
            secondary={t('workOrder.view.fulfillerWithName', { fulfiller: row.fulfiller })}
          />
        </ToDoListItem>)}
      </ToDoList>
    </>}
  </div>;
}