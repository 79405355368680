import { Box } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph, TableHeader, Tooltip as VTooltip } from 'styleguide';
import TableUtils from '../../utils/TableUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import { useParams } from 'react-router-dom';
import ReportTableCell from './ReportTableCell';
import TypeReport from './TypeReport';
import ExportCSVButton from '../table/ExportCSVButton';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchCSVData } from '../../stores/register/registerReport';
import { useDispatch } from 'react-redux';

const getClassifierType = (objectDef) => {
  const kind = objectDef.columns.find(c => c.field === 'basicObjectKind');
  if (kind) {
    return kind.classifierType;
  }
  return null;
};

const isShowArea = (objectDef) => {
  const geometry = objectDef.columns.find(c => c.field === 'geometry');
  if (geometry) {
    return geometry.geometryTypes.includes('Polygon');
  }
  return false;
};

const getDetailLabel = (d) => d.title || d.code || `[${d.id}]`;

export default function BasicObjectReport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { basicObjectTypeId } = useParams();

  const objectDef = RegisterUtils.objectDefs[basicObjectTypeId];

  const title = objectDef.title;
  const domain = objectDef.domain;
  const reportName = basicObjectTypeId;
  const classifierType = getClassifierType(objectDef);
  const showArea = isShowArea(objectDef);
  const chartField = showArea ? 'area' : 'count';

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'count', headerName: t('report.table.count'), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];

    if (showArea) {
      columns.push({
        field: 'area', headerName: t('report.table.area'), notSortable: true, renderCell: (column, cell) => {
          const value = <span>
            {`${TableUtils.formatNumber(cell.area) || 0} ${t('report.table.unitM')}`}
            <sup>2</sup>
          </span>;
          if (!cell.title) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      });
    }

    return columns;
  }, [showArea, t]);

  const excelColumns = [
    { field: "code", type: "integer", headerName: t('register.basicObject.code') },
    { field: "title", headerName: t('register.basicObject.title')},
    { field: "basicObjectKind", type: "classifier", classifierType: classifierType, headerName: t('register.basicObject.basicObjectKind') },
    { field: "installationMethod", type: "classifier", classifierType: ClassifierType.installationMethod, headerName: t('register.basicObject.installationMethod') },
    { field: "note", type: "textarea", headerName: t('register.basicObject.note') },
    { field: "registrationDate", type: "date", headerName: t('register.basicObject.registrationDate') },
    { field: "establishmentYear", type: "integer", headerName: t('register.basicObject.establishmentYear') },
    { field: "location", headerName: t('register.basicObject.location') },
  ];
  if(showArea) {
    excelColumns.push({ field: "area", type: "integer", headerName: t('report.table.area') });
  }

  const exportCsvButton = <ExportCSVButton
    columns={excelColumns}
    filename={title}
    handleFetchData={() => dispatch(fetchCSVData(domain, reportName, 'csv'))}
  />;
  const header = <TableHeader buttons={exportCsvButton}>{title}</TableHeader>;

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <ReportTableCell 
          data={row.data} 
          getContent={(d) => {
            const title = getDetailLabel(d);
            if(title && d.note) {
              return <VTooltip placement="right" label={title} title={<Box whiteSpace="pre-line">{d.note}</Box>} />;
            } else {
              return title; 
            }
          }}
        />
        <ReportTableCell data={row.data}/>
        <ReportTableCell 
          data={row.data} 
          getContent={(d) => !!d.area && `${TableUtils.formatNumber(d.area)} ${t('report.table.unitM2')}`}
        />
      </>;
    }
  };

  return <TypeReport
    classifierType={classifierType}
    groupedColumns={columns}
    domain={domain}
    reportName={reportName}
    handleRenderCollapsibleRow={handleRenderCollapsibleRow}
    chartField={chartField}
    typeColumnLabel={t('report.table.type')}
    reportTitle={title}
    tableHeader={header}
  />;
}