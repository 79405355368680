import { FormControlLabel, Grid, IconButton, List, ListItem, makeStyles, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteBlock, deleteGroup, saveGroup, setGroupBlock, setGroupValue } from "../../stores/admin/clauseConf";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import { Button, Checkbox, Colors, Heading, Icon, Paragraph, VAutocomplete } from "styleguide";
import { DialogContainer } from "../form/DialogForm";
import ConfirmButton from "../table/ConfirmButton";
import VTextField from "../form/VTextField";
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ClauseBlockContent } from "../application/clause/ClauseOverview";
import { cloneDeep } from "lodash";
import { allowedParameters, EditGroupTitleButton } from "./ClauseConfForm";
import { ClassifierType, ClauseBlockType } from "../../constants/classifierConstants";
import ClassifierAutocomplete from "../form/ClassifierAutocomplete";
import { fetchClassifierTypes } from "../../stores/admin/classifier";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 1200,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    paddingTop: 12,
    paddingBottom: 88,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  blockContent: {
    lineHeight: '36px'
  },
  draggingPaper: {
    backgroundColor: Colors.hall3
  },
}));

function EditBlockButton({ onConfirm, defaultValue, title, label, icon, classifierTypes, ...rest }) {
  const { t } = useTranslation();
  const [block, setBlock] = useState(defaultValue || {});

  useEffect(() => setBlock(defaultValue || {}), [defaultValue]);

  return <ConfirmButton {...rest}
    message={label || t('admin.clauseConf.confirmEditBlock')}
    title={title || t('button.edit')}
    icon={icon || 'edit'}
    aria-label="edit block"
    isColumnLayout maxWidth="md"
    dialogContent={<Grid container direction="row" spacing={2}>
      <Grid item xs={12} lg={5}>
        <ClassifierAutocomplete codeValue={block?.blockType} classifierType={ClassifierType.clauseBlock} autoHighlight
          label={t('admin.clauseConf.blockType')} fullWidth
          handleChange={(value) => setBlock({
            ...block,
            blockType: value?.itemCode
          })}
        />
      </Grid>
      {block.blockType === ClauseBlockType.template && <Grid item xs={12} lg={5}>
        <VAutocomplete
          value={!!block.classifierTypeCode ? classifierTypes.find(c => c.code === block.classifierTypeCode) : {}}
          onChange={(event, value) => setBlock({
            ...block,
            classifierTypeCode: value?.code
          })}
          options={classifierTypes}
          getOptionLabel={(o) => o.title}
          getOptionSelected={(option, value) => option?.code === value?.code}
          renderInput={(params) =>
            <VTextField {...params} label={t('admin.clauseConf.classifierTypeCode')} />
          }
        />
      </Grid>}
      <Grid item xs={12}>
        <VTextField autoFocus value={block.content} multiline minRows={5} fullWidth
          onChange={(event) => setBlock({
            ...block,
            content: event.target.value
          })}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <VTextField value={block.indent || ''} label={t('admin.clauseConf.indent')} fullWidth
          onChange={(event) => setBlock({
            ...block,
            indent: event.target.value
          })}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <FormControlLabel
          label={t('admin.clauseConf.isNumerable')}
          control={<Checkbox checked={!!block.isNumerable}
            onChange={(event) => setBlock({
              ...block,
              isNumerable: event.target.checked
            })}
          />}
        />
      </Grid>
      <Grid item xs={12}>
        <Paragraph fontSize="14B">{t('admin.clauseConf.allowedParameters')}</Paragraph>
        {allowedParameters.map(param =>
          <Paragraph key={param} fontSize="12">{`{{${param}}} - ${t('admin.clauseConf.blockParameter.' + param)}`}</Paragraph>
        )}
      </Grid>
    </Grid>}
    onConfirm={() => onConfirm(block)}
    confirmDisabled={!block.content || !block.blockType}
  />;
}

function SortableBlock(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.block.id || props.block.fid });

  const style = {
    '--translate-x': transform ? transform.x : 0,
    '--translate-y': transform ? transform.y : 0,
    '--transition': transition,
  };

  return <ListItem ref={setNodeRef} style={style}>
    <Block {...props} attributes={attributes} listeners={listeners} />
  </ListItem>;
}

function Block({ block, disabled, attributes, listeners, handleEditBlock, handleDeleteBlock, classifierTypes }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Grid container direction="row" alignItems="center">
    <Grid item xs={10} className={classes.blockContent}>
      <ClauseBlockContent>{block.content}</ClauseBlockContent>
    </Grid>
    <Grid item>
      <EditBlockButton onConfirm={handleEditBlock} classifierTypes={classifierTypes}
        defaultValue={block} disabled={disabled} />
      <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={() => handleDeleteBlock(block)}
        aria-label="delete block" disabled={disabled} title={t('button.delete')} />
      <IconButton {...attributes} {...listeners} title={t('clause.button.drag')} disabled={disabled}>
        <Icon icon="drag" />
      </IconButton>
    </Grid>
  </Grid>;
}

export default function ClauseGroupConfForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const { groupId } = useParams();

  const clause = useSelector(state => state.clauseConf.clause);
  const disabled = useSelector(state => state.clauseConf.isLoading);
  const classifierTypes = useSelector(state => state.classifier.types);

  useEffect(() => !classifierTypes.length && dispatch(fetchClassifierTypes()), [dispatch, classifierTypes]);

  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  const [activeBlock, setActiveBlock] = useState();

  const group = clause?.groups.find(g => g.id === Number(groupId) || g.id === groupId);
  if (!clause || !group || !classifierTypes) {
    return <CenteredLoadingIndicator />;
  }

  const handleEditGroupTitle = (value) => {
    dispatch(setGroupValue(group, 'clauseGroupCode', value.itemCode));
    dispatch(setGroupValue(group, 'clauseGroupCodeTitle', value.title));
  };

  const handleDeleteGroup = async (group) => {
    if (await dispatch(deleteGroup(group))) {
      navigate(-1);
    }
  };

  const handleEditBlock = (block) => {
    dispatch(setGroupBlock(group, block));
  };

  const handleDeleteBlock = (block) => {
    dispatch(deleteBlock(group, block));
  };

  const handleDragStart = (event) => {
    setActiveBlock(group.blocks.find(g => g.id === event.active.id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = group.blocks.findIndex(b => b.id === active.id);
      const newIndex = group.blocks.findIndex(b => b.id === over.id);

      const items = cloneDeep(arrayMove(group.blocks, oldIndex, newIndex));
      items.forEach((item, index, array) => array[index].queueNumber = index + 1);
      dispatch(setGroupValue(group, 'blocks', items));
      setActiveBlock(null);
    }
  };

  const handleSave = async () => {
    const saved = await dispatch(saveGroup(clause, group));
    if (saved) {
      navigate(-1);
    }
  };

  return <DialogContainer
    title={t(`admin.clauseConf.formTitle.${clause.applicationType}`)}
    mainAction={<Button onClick={handleSave} disabled={disabled}>{t('button.save')}</Button>}
    disabled={disabled}
  >
    <div className={classes.container}>
      <List disablePadding>
        <ListItem>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10} className={classes.blockContent}>
              <Heading level="3">{group.clauseGroupCodeTitle}</Heading>
            </Grid>
            <Grid item>
              <EditGroupTitleButton defaultValue={group.clauseGroupCode} onConfirm={handleEditGroupTitle} disabled={disabled} />
              <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={() => handleDeleteGroup(group)}
                aria-label="delete group" disabled={disabled} title={t('button.delete')} />
              <EditBlockButton defaultValue={{}} onConfirm={handleEditBlock} icon="add" title={t('button.add')}
                disabled={disabled} classifierTypes={classifierTypes} />
            </Grid>
          </Grid>
        </ListItem>
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={group.blocks}
            strategy={verticalListSortingStrategy}
          >
            {group.blocks.map(block =>
              <SortableBlock key={block.id} block={block} disabled={disabled} classifierTypes={classifierTypes}
                handleEditBlock={handleEditBlock} handleDeleteBlock={handleDeleteBlock}
              />
            )}
          </SortableContext>
          <DragOverlay>
            {activeBlock ? <Paper className={classes.draggingPaper}>
              <Block block={activeBlock} disabled={true} />
            </Paper> : null}
          </DragOverlay>
        </DndContext>
      </List>
    </div>
  </DialogContainer>;
}