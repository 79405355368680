import { useContext, useEffect, useRef, useState } from "react";
import MapContext from "../MapContext";
import MapUtils from "../../../utils/MapUtils";
import { useTranslation } from "react-i18next";
import { Box, ClickAwayListener, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Colors, Icon } from "styleguide";
import { useLocation } from "react-router-dom";
import OLControl from "ol/control/Control";

const ScaleSelectControl = () => {
  const { map } = useContext(MapContext);
  const { t } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [scales, setScales] = useState([]);
  const [currentResolution, setCurrentResolution] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (!map || !ref) return;

    const handleChangeResolution = () => setCurrentResolution(map.getView().getResolution());

    setScales(
      map.getView().getResolutions().map((resolution, index) => {
        const scale = MapUtils.scales[index];
        return {
          label: `1:${scale}`,
          value: resolution
        };
      })
    );

    handleChangeResolution();

    map.getView().on('change:resolution', handleChangeResolution);


    const control = new OLControl({ element: ref.current });

    map.controls.push(control);

    return () => {
      map.controls.remove(control);
      map.getView().un('change:resolution', handleChangeResolution);
    }
  }, [map]);

  const handleChange = (resolution) => {
    map.getView().setResolution(resolution);
  };

  const currentScale = (scales.length && currentResolution) ? scales.find(s => s.value === currentResolution) : '';
  const mapDialogPadding = location.pathname.startsWith('/map') || location.pathname.startsWith('/monitoring/map') ? '' : 'ol-in-dialog';

  return <div>
    <div ref={ref} className={`ol-scale-text ol-control ${mapDialogPadding}`} onClick={() => setOpen(true)}>
      {currentScale?.label}
    </div>
    {open && <div className="ol-scale-select">
      <Box paddingTop={2} paddingLeft={2} paddingBottom={1}>
        {t('map.control.scaleSelect')}
      </Box>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <List dense disablePadding>
          {scales.map((scale, index) => (
            <ListItem key={index} button onClick={() => handleChange(scale.value)} selected={scale.value === currentResolution}>
              <ListItemText secondary={scale.label} />
              <ListItemSecondaryAction>
                {scale.value === currentResolution && <Icon icon="tick" color={Colors.sinineVaal} />}
              </ListItemSecondaryAction>
            </ListItem>))}
        </List>
      </ClickAwayListener>
    </div>}
  </div>;
};

export default ScaleSelectControl;