import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Colors, Heading, Icon, Paragraph } from "styleguide";
import NavButton from "styleguide/build/components/Buttons/NavButton/NavButton";
import { ClassifierType } from "../../constants/classifierConstants";
import { setActiveGeometries } from "../../stores/map/map";
import { toggleLoadingOverlay } from "../../stores/notification";
import DateUtils from "../../utils/DateUtils";
import MapUtils from "../../utils/MapUtils";
import PdfUtils from "../../utils/PdfUtils";
import TableUtils from "../../utils/TableUtils";
import RoadSearch from "../form/RoadSearch";
import Layers from "../map/layers/Layers";
import Map from '../map/Map';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  reportTable: {
    textAlign: 'center',
    border: 'none'
  },
  mapContainer: {
    height: 800,
    borderRadius: 4,
    overflow: 'hidden',
    margin: 12
  },
}));

export function DetailRow({ label, children }) {
  const { t } = useTranslation();

  return <TableRow>
    <TableCell>{t(label)}:</TableCell>
    <TableCell>{children}</TableCell>
  </TableRow>
}

export default function RoadDetailsReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { classifiers } = useSelector(state => state.classifier);
  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const compact = useSelector(state => state.global.mobileView);

  const [road, setRoad] = useState();

  const title = t(`report.TE.roadDetails`);
  const subTitle = t('report.report');

  const reportTitle = t('report.TE.roadDetailsTitle');
  const reportSubTitle = t('report.TE.roadDetailsSubTitle', { date: DateUtils.formatDate(new Date()) });

  const handleClose = () => navigate(-1);

  const mapTitle = t('report.TE.roadDetails3');

  const handlePdfExport = () => {
    dispatch(toggleLoadingOverlay(true));
    document.body.style.cursor = 'progress';

    const reportTableId = 'report-table';
    const reportTable = document.getElementById(reportTableId);
    const reportTableOrigWidth = reportTable.style.width;
    reportTable.style.width = PdfUtils.widthPx() + 'px';

    const pdf = PdfUtils.newPdf();

    PdfUtils.addTitle(pdf, reportTitle);

    pdf.setFontSize(14);
    pdf.text(reportSubTitle, PdfUtils.widthMm / 2, 21, { align: 'center' });

    PdfUtils.addTable(pdf, reportTableId, 30, true);

    PdfUtils.addPage(pdf);
    pdf.text(mapTitle, 10, 25);

    const handlePdfDone = () => {
      PdfUtils.savePdf(pdf, `${subTitle} ${title} ${DateUtils.nowISODate()}`);

      reportTable.style.width = reportTableOrigWidth;
      document.body.style.cursor = 'auto';
      dispatch(toggleLoadingOverlay(false));
    };

    PdfUtils.addImage(pdf, 'map-container', 10, 30).then(handlePdfDone);
  };

  const handleSetRoad = (road) => {
    setRoad(road);
    dispatch(setActiveGeometries([road.areaShape]));
  };

  const layerFilter = `road_id=${road?.id}`

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
      <span className={classes.flex}></span>
      <Button size="extra-small" color="tertiary" onClick={handlePdfExport} disabled={!road}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPdf')}
      </Button>
    </Toolbar>

    <Box py={4}>
      <RoadSearch handleSetRoad={handleSetRoad} />
    </Box>

    {!!road && <Box py={4}>
      <TableContainer component={Paper} className={classes.stickyHeaderTableContainer}>
        <Box display="flex" justifyContent="center" paddingTop={5}>
          <Heading level="3">{reportTitle}</Heading>
        </Box>
        <Box display="flex" justifyContent="center" paddingBottom={1}>
          <Paragraph fontSize="16B">{reportSubTitle}</Paragraph>
        </Box>
        <Table id="report-table" className={classes.reportTable}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>{t('report.TE.roadDetails1')}</TableCell>
            </TableRow>
            <DetailRow label="register.road.title">{road.title}</DetailRow>
            <DetailRow label="register.road.code">{road.code}</DetailRow>
            <DetailRow label="register.road.roadType">
              {TableUtils.getClassifierTitle(classifiers, ClassifierType.roadKind, road.roadType)}
            </DetailRow>
            <DetailRow label="register.road.note">{road.note}</DetailRow>
            <TableRow><TableCell colSpan={2} /></TableRow>
            <TableRow>
              <TableCell colSpan={2}>{t('report.TE.roadDetails2')}</TableCell>
            </TableRow>
            <DetailRow label="register.road.lengthM">{`${TableUtils.formatNumber(road.lengthM)} m`}</DetailRow>
            <DetailRow label="register.road.establishmentYear">{road.establishmentYear}</DetailRow>
            <TableRow><TableCell colSpan={2} /></TableRow>
          </TableBody>
        </Table>
        <div style={{ padding: 16 }}>{mapTitle}</div>
        <div id="map-container" className={classes.mapContainer}>
          <Map center={center} zoom={zoom} extent={extent} disablePan>
            <Layers>
              {MapUtils.getDefaultBaseLayer(layers)}
              {MapUtils.getLayerByLayerName(layers, 'road_type_jalgrattatee', layerFilter)}
              {MapUtils.getLayerByLayerName(layers, 'road_type_jalgtee', layerFilter)}
              {MapUtils.getLayerByLayerName(layers, 'road_type_kergliiklus', layerFilter)}
              {MapUtils.getLayerByLayerName(layers, 'road_type_soidutee', layerFilter)}
            </Layers>
          </Map>
        </div>
      </TableContainer>
    </Box>}
  </div >;
}