import { Box, Divider, Grid, makeStyles, Menu, MenuItem, Paper } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button } from 'styleguide';
import { Authority } from '../../constants/authConstants';
import { ObjectStatus } from '../../constants/classifierConstants';
import i18n from '../../i18n';
import { showWarning } from '../../stores/notification';
import { setDetails, deleteRegisterObject, setRegisterObjectGeometriesOnMap, postCustomAction, calculatePathToRainwaterRoute } from '../../stores/register/register';
import { resetRows } from '../../stores/register/registerCombine';
import { addWithRegisterObject } from '../../stores/workOrder/workOrder';
import AuthUtils from '../../utils/AuthUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import RouteUtils from '../../utils/RouteUtils';
import TableUtils from '../../utils/TableUtils';
import ConfirmButton from '../table/ConfirmButton';

const useStyles = makeStyles((theme) => ({
  detailRow: {
    padding: '20px 20px'
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  textarea: {
    whiteSpace: 'pre-line'
  },
  actionButton: {
    marginRight: 8,
    marginBottom: 8
  }
}));

export default function RegisterDetails({ compact, mapView }) {
  const { t } = useTranslation();
  let { objectType } = useParams();

  const { objects, isLoading } = useSelector(state => state.register);
  const { classifiers } = useSelector(state => state.classifier);
  const { authUser } = useSelector(state => state.auth);
  const { drawingFeatures } = useSelector(state => state.feature);
  const { rows: basicObjectTypes } = useSelector(state => state.basicObjectType);

  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const item = objects[objectType];

  const objectDef = RegisterUtils.getObjectDef(objectType);
  let columns = objectDef.columns.filter(column => !column.tableOnly && column.type !== 'geometry');
  let textareaColumns = [];
  if (columns.some(column => column.type === 'textarea')) {
    textareaColumns = columns.filter(column => column.type === 'textarea');
    columns = columns.filter(column => column.type !== 'textarea');
  }

  const renderRow = (index, column) => {
    let value = item[column.field];
    if (column.renderDetails) {
      value = column.renderDetails(column, item);
    } else {
      switch (column.type) {
        case 'textarea':
          value = <span className={classes.textarea}>{value}</span>;
          break;
        case 'relation':
          const rel = item[column.relation];
          if (rel) {
            if (RegisterUtils.getObjectDef(column.relation)) {
              value = rel ? <NavLink to={RouteUtils.registerPathAwareRoute(location, `/view/${column.relation}/${rel.id}`)}>{rel[column.relationField]}</NavLink> : '';
            } else {
              value = rel[column.relationField];
              if (!!column.relationFieldEng && i18n.language === 'en-US') {
                value = rel[column.relationFieldEng] || value;
              }
            }
          }
          break;
        default:
          value = TableUtils.getCellValue(column, item, classifiers);
      }
    }

    return <div key={index}>
      <div className={compact ? classes.detailRowDense : classes.detailRow}>
        {column.type !== 'textarea' && <span className={classes.label}>{RegisterUtils.getColumnName(objectType, column)}</span>}
        {value}
      </div>
      {!compact && <Divider />}
    </div>;
  }

  const renderPaper = (columns) => {
    return <Grid item xs={12} lg={6}>
      <Paper>
        {columns.map((column, index) => renderRow(index, column))}
      </Paper>
    </Grid>;
  }

  const renderLargeView = () => {
    const splitIndex = Math.ceil(columns.length / 2);
    const leftColumns = columns.slice(0, splitIndex).concat(textareaColumns);
    const rightColumns = columns.slice(splitIndex);
    return <>
      {renderPaper(leftColumns)}
      {renderPaper(rightColumns)}
    </>;
  };

  const renderCompactView = () => {
    return <Grid item xs={12} lg={12}>
      {columns.concat(textareaColumns).map((column, index) => renderRow(index, column))}
    </Grid>;
  };

  const handleEdit = () => {
    navigate(`/register/edit/${objectType}/${item.id}`, { state: { from: location.pathname }});
  };

  const handleCopy = () => {
    let newItem = cloneDeep(item);
    newItem.id = null;
    //set readonly fields to null
    objectDef.columns.filter(column => column.readonly).forEach(column => newItem[column.field] = null);
    navigate(`/register/edit/${objectType}`);
    dispatch(setDetails(objectType, newItem));
  };

  const handleAdd = (event) => {
    if (objectDef.genericAddNew) {
      let newItem = {};
      const columns = objectDef.columns;

      if (RegisterUtils.getSuperObjectType(objectType) === 'basicObject') {
        newItem.basicObjectType = basicObjectTypes.find(bot => bot.id === Number(objectType));
      }

      if (drawingFeatures?.length) {
        const fault = RegisterUtils.setDrawGeometries(newItem, drawingFeatures, columns);
        if (fault) {
          dispatch(showWarning('validation.wrongGeometryType', fault));
          return;
        }
      }
      navigate(`/register/edit/${objectType}`);
      dispatch(setDetails(objectType, newItem));
    } else {
      setAddMenuAnchorEl(event.currentTarget || event.target);
    }
  };

  const handleAddObjectType = (newObjectType) => {
    if (!mapView) {
      dispatch(setRegisterObjectGeometriesOnMap(objectType, item));
    }
    dispatch(RegisterUtils.addNew(objectDef.mainParentObjectType, item[objectDef.mainParentObjectType], newObjectType, drawingFeatures, navigate));
    setAddMenuAnchorEl(null);
  };

  const handleJoin = () => {
    dispatch(resetRows());
    navigate(RouteUtils.registerPathAwareRoute(location, `/view/${objectType}/${item.id}/join`));
  };

  const handleDelete = () => {
    dispatch(deleteRegisterObject(objectType, item));
  };

  const handleShowAllRelated = () => {
    dispatch(setRegisterObjectGeometriesOnMap(objectType, item, false, true));
  };

  const handleCustomPost = (action) => {
    dispatch(postCustomAction(objectType, item.id, action));
  };

  const handleAddWorkOrder = () => {
    dispatch(addWithRegisterObject(objectDef.domain, objectType, item.id, RegisterUtils.getRegisterGeometries(objectType, item)));
    navigate('/workOrder/edit');
  };

  let addMenuObjectTypes = [];
  if (objectDef.mainParentObjectType) {
    addMenuObjectTypes = Object.keys(RegisterUtils.objectDefs).filter(ot => RegisterUtils.objectDefs[ot].mainParentObjectType === objectDef.mainParentObjectType);
  }

  return <>
    {RegisterUtils.canEditDomain(objectDef.domain, authUser) &&
      <Box pb={compact ? 1 : 3}>
        {!objectDef.notUpdatable &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleEdit} disabled={isLoading}>
            {t('button.edit')}
          </Button>
        }
        {!objectDef.notInsertable && !objectDef.notCopyable &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleCopy} disabled={isLoading}>
            {t('button.copy')}
          </Button>
        }
        {(objectDef.genericAddNew || objectDef.mainParentObjectType) &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleAdd} disabled={isLoading}>
            {t('button.add')}
          </Button>
        }
        {objectDef.joinable &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleJoin} disabled={isLoading}>
            {t('button.join')}
          </Button>
        }
        {!!mapView && objectType === 'rainwaterRoute' &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleShowAllRelated} disabled={isLoading}>
            {t(`register.${objectType}.showAllRelated`)}
          </Button>
        }
        {!mapView && objectType === 'road' &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={() => handleCustomPost('generateSection')} disabled={isLoading}>
            {t(`register.${objectType}.generateSection`)}
          </Button>
        }
        {!objectDef.noWorkOrder && AuthUtils.hasAuthority(authUser, Authority.UC26_work_orders) &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'} onClick={handleAddWorkOrder} disabled={isLoading}>
            {t(`button.addWorkOrder`)}
          </Button>
        }
        {mapView && (
          objectType === 'pipe' ||
          objectType === 'ditch' ||
          objectType === 'culvert' ||
          objectType === 'cleaner' ||
          objectType === 'pumpingStation' ||
          objectType === 'flowmeter' ||
          objectType === 'waterQualityMeter' ||
          objectType === 'well') &&
          <Button className={classes.actionButton} size={compact ? 'extra-small' : 'small'}
            onClick={() => dispatch(calculatePathToRainwaterRoute(objectType, item.id))} disabled={isLoading}>
            {t(`register.rainwaterRoute.pathToRainwaterRoute`)}
          </Button>
        }
        {!objectDef.notDeletable && (!item.status || item.status === ObjectStatus.planned) &&
          <ConfirmButton message={t('register.form.confirmDelete')} onConfirm={handleDelete} btnText={t('button.delete')} color="tertiary"
            aria-label="delete register object" className={classes.actionButton} size={compact ? 'extra-small' : 'small'} disabled={isLoading} />
        }

        {!!addMenuObjectTypes.length && <Menu
          anchorEl={addMenuAnchorEl}
          keepMounted
          open={Boolean(addMenuAnchorEl)}
          onClose={() => setAddMenuAnchorEl(null)}
          elevation={2}
        >
          {addMenuObjectTypes.map(objectType =>
            <MenuItem key={objectType} onClick={() => handleAddObjectType(objectType)}>
              {t(`register.${objectType}.label`)}
            </MenuItem>
          )}
        </Menu>}
      </Box>
    }

    <Grid container spacing={2}>
      {compact ? renderCompactView() : renderLargeView()}
    </Grid>
  </>;
}