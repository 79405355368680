import { useContext } from "react";
import MapContext from "../MapContext";
import { MapConstants } from "../../../constants/mapConstants";
import MapControlButton from "./MapControlButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearFeatures } from "../../../stores/map/feature";
import { toggleActiveControl } from "../../../stores/map/map";
import VectorLayer from "ol/layer/Vector";

const ResetControl = () => {
  const { map } = useContext(MapContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(clearFeatures());
    dispatch(toggleActiveControl(null, false));
    map.getView().setZoom(MapConstants.initialZoom);
    map.getView().setCenter(MapConstants.initialCenter);
    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        layer.getSource().clear();
      }
    });
    navigate('/map', { replace: true });
  };

  return <MapControlButton
    controlName="reset"
    controlActive={false}
    icon="reset"
    onClick={handleClick}
    tooltipPlacement="left" />;
};

export default ResetControl;