import { Box, Collapse, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { Icon, BasicLabel, Heading, Button } from 'styleguide';
import { fetchRelatedObjectRows, fetchRelatedObjects, toggleRelatedObject, setDetails } from '../../stores/register/register';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import RouteUtils from '../../utils/RouteUtils';
import TableUtils from '../../utils/TableUtils';
import ChartUtils from '../../utils/ChartUtils';
import LoadingIndicator from '../form/LoadingIndicator';
import PageableTable from '../table/PageableTable';

const useStyles = makeStyles(() => ({
  iconCell: {
    verticalAlign: 'middle'
  },
}));


function RelatedObjectsTable({ item, onToggle, showAdd, parentType, parentObject }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const url = useResolvedPath("").pathname;
  const mapView = url.startsWith('/map');
  const { drawingFeatures } = useSelector(state => state.feature);
  const isServitude = item.objectType === 'servitudeArea';

  const handleShowMap = (row) => {
    dispatch(MapUtils.showRegisterObjectOnMap(navigate, item.objectType, row));
  };

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    navigate(RouteUtils.registerPathAwareRoute(location, `/view/${item.objectType}/${selected.id}`));
  };

  const handleAddNew = (newItemObjectType) => {
    dispatch(RegisterUtils.addNew(parentType, parentObject, newItemObjectType, drawingFeatures, navigate));
  };

  const handleAddRelation = (newItemObjectType) => {
    navigate(`/register/select/${newItemObjectType}`, 
      { state: {
        postApiPathPrefix: `${parentType}/${parentObject.id}/`,
        filter: { domain: [RegisterUtils.getObjectDef(parentType).domain] }
      }}
    );
  };

  const objectDef = RegisterUtils.getObjectDef(item.objectType);
  const columns = RegisterUtils.getRegisterColumns(item.objectType, handleShowMap, mapView);

  let infoPanel;
  if (item.objectType === 'careAreaObject' && parentType === 'careAreaTE' && item.open && item.rows?.length) {
    const length = ChartUtils.arraySum(item.rows, 'careLengthM');
    const area = ChartUtils.arraySum(item.rows, 'careAreaM');
    infoPanel = <Box paddingRight={1}>
      {!!length && <div>{t('register.careArea.length', { length: TableUtils.formatNumber(length) })}</div>}
      {!!area && <div>{t('register.careArea.area', { area: TableUtils.formatNumber(area) })}</div>}
    </Box>;
  }

  return <>
    <TableRow className={item.open ? 'collapsible-table-row-opened' : 'collapsible-table-row'}
      onClick={() => onToggle(item)}>
      <TableCell component='th' align='left'>
        <Icon icon={item.open ? 'minus' : 'plus'} className={classes.iconCell} />
      </TableCell>
      <TableCell component='th' scope='row'>
        <Grid container direction="row" justifyContent="space-between">
          <Heading level="4">
            {item.header}
            <BasicLabel isNumeric>{item.count}</BasicLabel>
          </Heading>
          {showAdd && !objectDef.notInsertable && <Box paddingRight={1}>
            {isServitude && <Box component="span" mr={1}><Button size="extra-small" onClick={(event) => { event.stopPropagation(); handleAddRelation(item.objectType) }}>
              {t('button.addRelation')}
            </Button></Box>}
            <Button size="extra-small" onClick={(event) => { event.stopPropagation(); handleAddNew(item.objectType) }}>
              {t('button.add')}
            </Button>
          </Box>}
          {infoPanel}
        </Grid>
      </TableCell>
    </TableRow>
    <TableRow className='collapsed-table'>
      <TableCell style={{ padding: 0 }} colSpan={3}>
        <Collapse in={item.open} timeout='auto' unmountOnExit>
          <Box margin={0}>
            <PageableTable
              rows={item.rows}
              columns={columns}
              loading={item.isLoading}
              totalRows={item.count}
              onRowSelected={handleRowSelection}
              size={mapView ? 'small' : 'medium'}
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
}

export default function RegisterRelatedObjects({ compact }) {
  let { objectType, id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { objects } = useSelector(state => state.register);
  const { authUser } = useSelector(state => state.auth);

  const item = objects[objectType];

  useEffect(() => item && !item.relatedObjects && dispatch(fetchRelatedObjects(objectType, id)), [dispatch, objectType, id, item]);

  if (!item || !item.relatedObjects) {
    return <LoadingIndicator />;
  }

  const objectDef = RegisterUtils.getObjectDef(objectType);
  const showAdd = RegisterUtils.canEditDomain(objectDef.domain, authUser) && !objectDef.noRelatedAdd && objectType !== 'careAreaTE';
  let filteredObjects = showAdd ? item.relatedObjects : item.relatedObjects.filter(r => r.count > 0);
  if (filteredObjects.length === 0) {
    return <Heading level="3">{t('register.error.relatedObjectsNotFound')}</Heading>
  }
  filteredObjects = filteredObjects.map(ro => ({ ...ro, header: t(`register.${ro.objectType}.label`) }));

  const handleToggle = (item) => {
    if (!item.count) {
      return;
    }
    if (!item.open && !item.rows?.length) {
      dispatch(fetchRelatedObjectRows(objectType, id, item));
    }
    dispatch(toggleRelatedObject(objectType, item));
  };

  return <div className='VTable'>
    <TableContainer component={Paper}>
      <Table aria-label="register related objects table" size={compact ? 'small' : 'medium'}>
        <TableBody>
          {TableUtils.sortArrayByString(filteredObjects, 'header').map((relatedItem, index) => (
            <RelatedObjectsTable key={index} item={relatedItem} onToggle={handleToggle} showAdd={showAdd} parentType={objectType} parentObject={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>;
}