import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Paragraph, Colors, Icon } from 'styleguide';
import { fetchSelected, resetSubmitted, setFulfilled } from '../../stores/workOrder/workOrder';
import DialogForm from '../form/DialogForm';
import LoadingIndicator from '../form/LoadingIndicator';
import VTextField from '../form/VTextField';
import { cloneDeep } from 'lodash';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import ConfirmButton from '../table/ConfirmButton';
import DateUtils from '../../utils/DateUtils';
import VDateTimePicker from '../form/VDateTimePicker';

const useStyles = makeStyles((theme) => ({
  formBlock: {
    [theme.breakpoints.up('md')]: {
      width: 446,
      padding: 25,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 5,
    },
    margin: 'auto',
  },
  formBlockContainer: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
}));

function FilesBlock({ files, handleAddFile, handleRemoveFile, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      handleAddFile(files[0]);
    }
  });

  return <Grid container direction="column" spacing={4}>
    {files.map((file, index) => <Grid item key={index} className={classes.formBlockContainer} container direction="row" justifyContent="space-between">
      <Paragraph fontSize='12'>{file.name}</Paragraph>
      <ConfirmButton message={t('fileUpload.confirmDelete')} icon="delete" size="small"
        onConfirm={() => handleRemoveFile(index)} aria-label="delete work order file" />
    </Grid>)}

    <Grid item {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
      <Grid container direction="row" className={classes.uploadRow} onClick={open}>
        <Icon icon='add' color={Colors.sinineVaal} />
        <Box marginLeft={2}>
          <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
        </Box>
        <input type="file" {...getInputProps()} />
      </Grid>
    </Grid>
  </Grid >;
}

export default function WorkOrderFulfilForm() {
  const { t } = useTranslation();
  let { id } = useParams();

  const dispatch = useDispatch();
  const { selected, isLoading, submitted } = useSelector(state => state.workOrder);
  const classes = useStyles();
  const navigate = useNavigate();
  const [filesNotUploaded, setFilesNotUploaded] = useState([]);

  const { register, control, errors, handleSubmit, reset } = useForm({
    defaultValues: selected || {}
  });

  const domain = selected?.domain || '';
  useEffect(() => id && (!selected || Number(id) !== selected.id) && dispatch(fetchSelected(id)), [dispatch, id, selected]);
  useEffect(() => reset(selected), [selected, reset]);
  useEffect(() => {
    if (submitted) {
      navigate(`/workOrder/${domain}`, { replace: true });
      dispatch(resetSubmitted());
    }
  }, [submitted, domain, navigate, dispatch]);

  if (!selected) {
    return <LoadingIndicator />;
  }

  const title = t('workOrder.view.setFulfilled');

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);

    if (data.fulfillmentDatetime) {
      data.fulfillmentDatetime = DateUtils.formatISODateTime(data.fulfillmentDatetime);
    }

    dispatch(setFulfilled(selected.id, data, filesNotUploaded));
  };

  const handleAddFile = (file) => {
    setFilesNotUploaded([...filesNotUploaded, file]);
  };

  const handleRemoveFile = (index) => {
    filesNotUploaded.splice(index, 1);
    setFilesNotUploaded([...filesNotUploaded]);
  };

  return <DialogForm
    title={title}
    mainAction={
      <Button type="submit" disabled={isLoading}>
        {t('workOrder.view.setFulfilled')}
      </Button>
    }
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="column" spacing={4} justifyContent="center" >
        <Grid item>
          <VTextField name="fulfillmentComment" label={t('workOrder.fulfillmentComment')}
            register={register} errors={errors} fullWidth maxLength={4000}
            disabled={isLoading} multiline minRows={4} />
        </Grid>
        <Grid item>
          <VDateTimePicker name="fulfillmentDatetime" label={t('workOrder.fulfillmentDatetime')}
            control={control} errors={errors} fullWidth disabled={isLoading}
            item={selected} defaultValue={new Date()} />
        </Grid>
        <Grid item>
          <FilesBlock files={filesNotUploaded} isLoading={isLoading}
            control={control} errors={errors} handleAddFile={handleAddFile} handleRemoveFile={handleRemoveFile} />
        </Grid>
      </Grid>
    </Paper>
  </DialogForm>;
}