import { Grid, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClassifierType } from "../../constants/classifierConstants";
import { addOperation, resetSubmitted, saveOperation } from "../../stores/planning/planningOperation";
import ClassifierSelect from "../form/ClassifierSelect";
import ControlledAutocomplete from "../form/ControlledAutocomplete";
import DialogForm from "../form/DialogForm";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import VCheckbox from "../form/VCheckbox";
import VDatePicker from "../form/VDatePicker";
import VTextField from "../form/VTextField";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
}));

function BaseOperationForm({ operation, title, handleSubmitOperation, disableTypeSelect }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, submitted } = useSelector(state => state.planningOperation);
  const participants = useSelector(state => state.planningParticipant.rows);

  useEffect(() => submitted && dispatch(resetSubmitted()) && navigate(-1), [submitted, dispatch, navigate]);

  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: operation || {}
  });


  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    if (data.supervisor) {
      data.supervisorId = data.supervisor.id;
      data.supervisor = undefined;
    }
    if (data.executor) {
      data.executorId = data.executor.id;
      data.executor = undefined;
    }
    handleSubmitOperation(data);
  };

  return <DialogForm
    title={title}
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <ClassifierSelect item={operation} name="operationType" label="planning.operation.operationType"
          disabled={isLoading || disableTypeSelect} control={control} errors={errors} fullWidth required
          classifierType={ClassifierType.planningOperationType}
        />
      </Grid>
      <Grid item>
        <VDatePicker name="startDate" label={t('planning.operation.startDate')}
          control={control} errors={errors} fullWidth disabled={isLoading} required
        />
      </Grid>
      <Grid item>
        <VDatePicker name="endDate" label={t('planning.operation.endDate')}
          control={control} errors={errors} fullWidth disabled={isLoading}
        />
      </Grid>
      <Grid item>
        <ControlledAutocomplete name="supervisor" label={t('planning.operation.supervisor')}
          disabled={isLoading} control={control} errors={errors}
          options={participants} getOptionLabel={(o) => o.fullName}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </Grid>
      <Grid item>
        <ControlledAutocomplete name="executor" label={t('planning.operation.executor')}
          disabled={isLoading} control={control} errors={errors}
          options={participants} getOptionLabel={(o) => o.fullName}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </Grid>
      <Grid item>
        <VCheckbox name="isPublic" label={t('planning.operation.isPublic')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VCheckbox name="sendNotification" label={t('planning.operation.sendNotification')}
          control={control} errors={errors} disabled={isLoading} />
      </Grid>
      <Grid item>
        <VTextField name="description" label={t('planning.operation.description')} multiline
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={2000} />
      </Grid>
      {!!operation.participants && <Grid item>
        <ControlledAutocomplete name="participants" label={t('planning.operation.participants')}
          disabled={isLoading} control={control} errors={errors}
          options={participants || []} getOptionLabel={(o) => o.fullName}
          getOptionSelected={(option, value) => option.id === value.id}
          multiple defaultValue={[]}
        />
      </Grid>}
    </Grid>
  </DialogForm>;
}

export default function OperationForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, operationId } = useParams();

  const { rows: operations } = useSelector(state => state.planningOperation);
  const participants = useSelector(state => state.planningParticipant.rows);

  if (!operations || !participants) {
    return <CenteredLoadingIndicator />;
  }

  const title = t(!!operationId ? 'planning.view.editOperation' : 'planning.view.addOperation');

  let operation;
  if (!!operationId) {
    operation = cloneDeep(operations?.find(p => p.id === Number(operationId)));
    if (operation.supervisorId) {
      operation.supervisor = participants?.find(p => p.id === operation.supervisorId);
    }
    if (operation.executorId) {
      operation.executor = participants?.find(p => p.id === operation.executorId);
    }
  } else {
    operation = { startDate: new Date() }
  };

  const handleSubmit = (data) => {
    if (operationId) {
      dispatch(saveOperation(id, operationId, data));
    } else {
      dispatch(addOperation(id, data));
    }
  };

  return <BaseOperationForm operation={operation}
    title={title} disableTypeSelect={!!operationId}
    handleSubmitOperation={handleSubmit}
  />;
}