import { Box, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import Map from '../map/Map';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveGeometries, toggleActiveControl } from '../../stores/map/map';
import Layers from '../map/layers/Layers';
import MapUtils from '../../utils/MapUtils';
import LayerStyles from '../map/LayerStyles';
import VectorLayer from '../map/layers/VectorLayer';
import { Colors, Icon, Paragraph } from 'styleguide';
import { getFileDownloadUrl } from '../../stores/workOrder/workOrder';
import { ClassifierType, WorkOrderFileType } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import { toggleFeaturesDialog } from '../../stores/map/feature';
import { Link, useNavigate } from 'react-router-dom';
import DateUtils from '../../utils/DateUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 400,
    borderRadius: 14,
    padding: 8,
    marginTop: 16
  },
  mapContainer: {
    minHeight: 500,
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden'
  },
  overviewContainer: {
    [theme.breakpoints.up('md')]: {
      padding: 40
    },
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  },
  divider: {
    marginTop: 32,
    marginBottom: 32
  },
  fileRow: {
    backgroundColor: Colors.hall3,
    minHeight: 48,
    borderRadius: 4,
    padding: 15,
    marginBottom: 5
  },
  downloadIcon: {
    marginTop: 5,
    float: 'right'
  }
}));

function FileDownloadLink({ workOrderId, fileId, children }) {
  const { t } = useTranslation();

  return <a href={getFileDownloadUrl(workOrderId, fileId)}
    target="_blank" rel="noreferrer"
    title={t('fileUpload.download')}>
    {children}
  </a>;
}

function FileBlock({ file, workOrderId, classifiers, hideType }) {
  const classes = useStyles();

  return <Grid item xs={12}>
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.fileRow}>
      <Grid item xs={12} lg={hideType ? 11 : 6}>
        <FileDownloadLink workOrderId={workOrderId} fileId={file.id}>
          {file.fileName}
        </FileDownloadLink>
      </Grid>
      {!hideType && <Grid item xs={12} lg={5}>
        <Paragraph fontSize='14'>
          {TableUtils.getClassifierTitle(classifiers, ClassifierType.documentType, file.documentType)}
        </Paragraph>
      </Grid>}
      <Box component={Grid} item xs={1} display={{ xs: "none", md: "block" }}>
        <div className={classes.downloadIcon}>
          <FileDownloadLink workOrderId={workOrderId} fileId={file.id}>
            <Icon icon="downloadFile" />
          </FileDownloadLink>
        </div>
      </Box>
    </Grid>
  </Grid>;
}

export default function WorkOrderDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const { selected, files } = useSelector(state => state.workOrder);
  const { classifiers } = useSelector(state => state.classifier);
  const { activeFeatures, layers, center, zoom, extent } = useSelector(state => state.map);

  const geometry = selected.geometry;
  useEffect(() => {
    if (geometry) {
      dispatch(setActiveGeometries([geometry]));
    }
  }, [dispatch, geometry]);

  const handleShowMap = () => {
    dispatch(setActiveGeometries([selected.geometry]));
    dispatch(toggleFeaturesDialog(false));
    dispatch(toggleActiveControl(null, false));
    navigate(`/map`);
  };

  const taskFiles = files?.filter(f => f.type === WorkOrderFileType.task);
  const completionFiles = files?.filter(f => f.type === WorkOrderFileType.completion);

  return <Paper className={classes.container}>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={12} lg={6} container direction="column" className={classes.overviewContainer}>
        <Grid item container direction="row">
          <Grid item xs={6}>
            <Paragraph fontSize='14B'>{t('workOrder.domain')}</Paragraph>
          </Grid>
          <Grid item xs={6}>
            <Paragraph fontSize='14'>
              {TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, selected.domain)}
            </Paragraph>
          </Grid>

          <Grid item xs={6}>
            <Paragraph fontSize='14B'>{t('workOrder.author')}</Paragraph>
          </Grid>
          <Grid item xs={6}>
            <Paragraph fontSize='14'>{selected.userAuthor.fullName}</Paragraph>
          </Grid>

          <Grid item xs={6}>
            <Paragraph fontSize='14B'>{t('workOrder.fulfiller')}</Paragraph>
          </Grid>
          <Grid item xs={6}>
            <Paragraph fontSize='14'>{selected.fulfiller?.title}</Paragraph>
          </Grid>

          <Grid item xs={6}>
            <Paragraph fontSize='14B'>{t('workOrder.source')}</Paragraph>
          </Grid>
          <Grid item xs={6}>
            <Paragraph fontSize='14'>{selected.source}</Paragraph>
            {!!selected.relatedTable && !!selected.relatedId &&
              <Link to={`/register/view/${selected.relatedTable}/${selected.relatedId}`}>
                {t(`register.${selected.relatedTable}.label`)}
              </Link>
            }
          </Grid>

          <Grid item xs={12}>
            <Box whiteSpace="pre-line">{selected.description}</Box>
          </Grid>

          {!!taskFiles.length && <Box padding={2} />}

          {taskFiles.map((file, index) => (
            <FileBlock key={index} file={file} workOrderId={selected.id} classifiers={classifiers} />
          ))}

          {!!selected.notificationList?.length && <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>}

          {TableUtils.sortArrayByNumber(selected.notificationList, 'id').map((notification, index) => <Grid item key={index} container>
            <Grid item xs={6}>
              <Paragraph fontSize='14B'>{t('workOrder.view.notifiedUser')}</Paragraph>
            </Grid>
            <Grid item xs={6}>
              <Paragraph fontSize='14'>
                {`${notification.fullName} (${TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, notification.domain)})`}
              </Paragraph>
            </Grid>
            <Grid item xs={12}>
              <Box whiteSpace="pre-line">{notification.content}</Box>
            </Grid>
          </Grid>)}

          {!!selected.fulfillmentComment && <>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={6}>
              <Paragraph fontSize='14B'>{t('workOrder.fulfillmentComment')}</Paragraph>
            </Grid>

            <Grid item xs={6}>
              <Paragraph fontSize='14'>{DateUtils.formatDateTime(selected.fulfillmentDatetime)}</Paragraph>
            </Grid>

            <Grid item xs={12}>
              <Box whiteSpace="pre-line">{selected.fulfillmentComment}</Box>
            </Grid>

            {!!completionFiles.length && <Box padding={2} />}

            {completionFiles.map((file, index) => (
              <FileBlock key={index} file={file} workOrderId={selected.id} hideType />
            ))}
          </>}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.mapContainer} onClick={handleShowMap}>
          <Map center={center} zoom={zoom} extent={extent} disablePan>
            <Layers>
              {MapUtils.getPhotoBaseLayer(layers)}
              {MapUtils.getCadastreWithAddressLayer(layers)}
              <VectorLayer features={activeFeatures} style={LayerStyles.activeFeature} zIndex={1000} />
            </Layers>
          </Map>
        </div>
      </Grid>
    </Grid>
  </Paper>;
}