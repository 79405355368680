import { AppBar, Dialog, makeStyles, Slide, Toolbar } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, NavButton, Heading, ProgressBar } from 'styleguide';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 25,
      paddingBottom: 100,
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 15,
      paddingBottom: 10,
      paddingLeft: 4,
      paddingRight: 4
    },
  },
  toolbar: {
    backgroundColor: 'white',
    height: 102
  },
  header: {
    top: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 25,
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 15,
      paddingLeft: 4,
      paddingRight: 4
    },
  },
  footer: {
    top: 'auto',
    bottom: 0,
    height: 88,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
      paddingRight: 4
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      paddingRight: 60
    }
  },
  spacer: {
    width: 16
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1200,
    [theme.breakpoints.up('md')]: {
      top: 48,
      right: 176,
    },
    [theme.breakpoints.down('md')]: {
      top: 38,
      right: 10,
    },
  },
}));

export default function DialogForm({ title, onSubmit, onClose, children, leftActions, actions, mainAction, disabled }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const mobile = useSelector(state => state.global.mobileView);

  const handleClose = () => {
    if (onClose) {
      const shouldExit = onClose();
      if (shouldExit) {
        return;
      }
    }
    setOpen(false);
    setTimeout(() => navigate(-1), 100);
  };

  return <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Slide} disableEnforceFocus>
    <form onSubmit={onSubmit} className={classes.container}>
      <AppBar elevation={0} color="transparent" position="static" >
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <Heading level={!!mobile ? '3' : '2'}>{title}</Heading>
          </div>
          <NavButton onClick={handleClose} action='close' disabled={!!disabled}></NavButton>
        </Toolbar>
      </AppBar>

      {children}

      {(!!actions || !!onSubmit) && <AppBar className={classes.footer} color="inherit" position="fixed">
        <Toolbar className={classes.toolbar}>
          {leftActions}
          <div className={classes.title}></div>
          {actions}
          <span className={classes.spacer} />
          {!!onSubmit && (mainAction || <Button type="submit" disabled={!!disabled}>{t('button.save')}</Button>)}
        </Toolbar>
      </AppBar>}
    </form>
  </Dialog>;
};

export function DialogContainer({ title, mainAction, onClose, children, actions, disabled, progress }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const mobile = useSelector(state => state.global.mobileView);

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
    !progress && setTimeout(() => navigate(-1), 100);
  };

  return <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Slide}>
    {!!progress && <ProgressBar className={classes.progressBar} percent={progress} />}
    {!!title && <AppBar elevation={0} color="transparent" position="static" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <Heading level={!!mobile ? '3' : '2'}>{title}</Heading>
        </div>
      </Toolbar>
    </AppBar>}
    <NavButton onClick={handleClose} action='close' disabled={!!disabled} className={classes.closeButton} />

    {children}

    {(!!actions || !!mainAction) && <AppBar className={classes.footer} color="inherit" position="fixed">
      <Toolbar className={classes.toolbar}>
        {actions}
        <span className={classes.title} />
        {!!mainAction && mainAction}
      </Toolbar>
    </AppBar>}
  </Dialog >;
}