import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TableHeader } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { setDetails, updateFilter, updatePageable, fetchBasicObjectTypes } from '../../stores/admin/basicObjectType';
import { PageableTableContainer } from '../table/PageableTable';
import { useNavigate } from 'react-router-dom';

export default function BasicObjectTypeTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { filteredRows, filter, pageable, isLoading } = useSelector(state => state.basicObjectType);

  const columns = useMemo(() => [
    { field: 'domain', headerName: t('admin.basicObjectType.domain'), type: "classifier", classifierType: ClassifierType.domain },
    { field: 'code', headerName: t('admin.basicObjectType.code'), filter: true },
    { field: 'title', headerName: t('admin.basicObjectType.title'), filter: true },
    { field: 'geometryType', headerName: t('admin.basicObjectType.geometryType'), type: "classifier", classifierType: ClassifierType.geometryType }
  ], [t]);

  useEffect(() => !pageable.loaded && dispatch(fetchBasicObjectTypes(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  const handleAddNew = () => {
    dispatch(setDetails({}));
    navigate(`/admin/basicObjectType`);
  };

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    navigate(`/admin/basicObjectType/${selected.id}`);
  };

  const header = () => (
    <TableHeader buttons={<Button size="extra-small" onClick={handleAddNew}>{t('button.add')}</Button>}>
      {t('admin.basicObjectType.label')}
    </TableHeader>
  );

  return <PageableTableContainer
    rows={filteredRows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}